.StrengthAbsoluteScale_wrapper {
  position: relative;
  background-color: @grey-base;
  transition: height 0.25ms ease-out;
}

.StrengthAbsoluteScale_progress {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0 0 5px 5px;
  width: 100%;
  background-color: @blue-base;
}
