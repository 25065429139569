.BatteryMask_wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BatteryMask_batteryFilling {
  height: 28px;
}

.BatteryMask_grayFilling {
  background-color: #b5b5b5;
  height: 28px;
}

.BatteryMask_redBattery {
  background-color: @red;
}

.BatteryMask_yellowBattery {
  background-color: @yellow-100;
}

.BatteryMask_greenBattery {
  background-color: @green-100;
}

.BatteryMask_imageMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/img/battery-mask.png");
  background-size: cover;
}
