.GenericHardwareCard_bg {
  width: 388px;
  height: 200px;
  padding: 24px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-between;

  @media (min-device-width: 320px) and (max-device-width: 480px) {
    width: 280px;
  }
}

.GenericHardwareCard_title {
  margin: 0 0 8px 0;
}
