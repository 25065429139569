.PourStatus_wrapper {
  display: flex;
  align-items: center;
  @media screen and (min-width: 768px), print {
    margin-left: auto;
    padding: 4px;
    padding-bottom: 20px;
    padding-right: 16px;
  }
}

.PourStatus_red {
  color: @red;
}

.PourStatus_orange {
  color: @orange-base;
}

.PourStatus_green {
  color: @green-base;
}

.PourStatus_grey {
  color: @grey-110;
}
