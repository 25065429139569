.DateInput_wrapper {
  width: 100%;
  margin-bottom: 24px;
}

.DateInput_labels {
  display: flex;
  justify-content: space-between;
}

.DateInput_alignLeft {
  text-align: left;
}

.DateInput_labels > * {
  display: inline-block;
  width: 100%;
  font-size: @tx-size-base;
  line-height: 1.7;
  margin-bottom: 0;
}

.DateInput_nonNativePickerWrapper {
  /*
     * This is here because on MS Edge clicks
     * on the div containing the disabled input
     * would not trigger onClick handlers.
     * It also needs to be combined with a
     * pointer-events: none on the desktop input.
     */
  cursor: pointer;
  pointer-events: all;
}

.DateInput_errorMessage {
  display: inline-block;
  width: 100%;
  color: #ff0000;
  font-size: @tx-size-base;
  letter-spacing: -0.2px;

  @media (min-width: 768px) {
    padding-left: 10px;
  }
}

.DateInput_invalidInput:focus {
  outline: none;
}

.DateInput_inputField {
  box-sizing: border-box;
  font-size: @tx-size-base;
  line-height: @tx-size-base;
  margin: 4px;
  min-height: 40px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  margin: 4px 0;
  width: 100%;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% 50%;

  @media (min-width: 768px) {
    pointer-events: none;
    display: inline-block;
    padding: 14px;
  }
}

.DateInput_inputField {
  cursor: pointer;
  background-image: url("./img/calendar_icon.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% 50%;
}

/*
 * These TimeInput classes are in this file because DateInput
 * is a standalone component and TimeInput is a subcomponent
 * of it, and therefore should live within DateInput.
 * Since DateInput doesn't have its own folder due to being
 * a standalone component they ended up here
 */

.TimeInput_timeSelector {
  margin: 0 24px;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: rgba(151, 151, 151, 0.5);
}

.TimeInput_controls {
  display: flex;
  margin: 20px 24px 0 24px;
  padding-bottom: 24px;
}

.TimeInput_timeSelector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TimeInput_inputSeparator {
  font-size: @tx-size-x-large;
}

.TimeInput_timeSelector > *:not(:last-child) {
  margin-right: 8px;
}

.TimeInput_inputField {
  width: 56px;
  margin-bottom: 16px;
  margin-top: 16px;
  font-size: @tx-size-base;
}

.TimeInput_controls > *:not(:last-child) {
  margin-right: 8px;
}
