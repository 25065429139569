.StrengthAbsoluteScale_wrapper {
  height: 100%;
}

.StrengthRowGroup_content {
  position: relative;
}

.StrengthRowGroup_thresholdColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.StrengthRowGroup_scaleColumn {
  height: 100%;
  position: absolute;
  top: 0;
  width: 4px;
  box-sizing: border-box;

  // This must be the padding of the row and the left
  // border + (width of the scale + 1) * 2.
  // This is here to align this line with the middle of the check icon.
  left: 26px;

  // The vertical paddings here should be half of the
  // height of each column so that the line starts and
  // ends in the middle of the first and last columns
  padding: 36px 0;
}

// This is here because separators in this row
// are exactly like the others but should have a small
// padding on the left to not cover the absolute scale.
.StrengthRowGroup_wrapper .RowSeparator_wrapper {
  padding: 0 24px 0 48px;
}
