.TimeSeries {
  display: flex;
  width: 100%;
  @media screen and (min-width: 768px), print {
  }
}

.TimeSeries_emptyDataWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// if we don't put this, when resizing the outer div width, the chart
// will not follow the width.
canvas {
  width: 100% !important;
  height: 100% !important;
}

// tooltip styling. So not following the naming convention here (we could put
// all this in a separate component).

.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: white;
  color: black;
  padding: 6px;
  border-radius: 6px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border: 1px solid;
  border-color: @grey-base;
  z-index: 100;
}

.tooltip-container {
  width: 100%;
}

.tooltip-table {
  text-align: left;
  border-collapse: collapse;
  font-size: @tx-size-small;
}

.tooltip-title {
  font-size: @tx-size-small;
  text-align: left;
  padding-bottom: 4px;
}

.tooltip-column {
  padding-right: 4px;
}

.tooltip-column-value {
  padding-right: 4px;
  padding-left: 8px;
  text-align: right;
}

.tooltip-circle-yellow {
  height: 8px;
  width: 8px;
  background-color: @yellow-base;
  border-radius: 50%;
  display: inline-block;
}

.tooltip-circle-begonia {
  height: 8px;
  width: 8px;
  background-color: @begonia-base;
  border-radius: 50%;
  display: inline-block;
}

.tooltip-circle-blue {
  height: 8px;
  width: 8px;
  background-color: @blue-base;
  border-radius: 50%;
  display: inline-block;
}
