.ApiTokenAlert_wrapper {
  box-sizing: border-box;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.ApiTokenAlert_body {
  color: @grey-110;
  line-height: 1.57;
  margin: 0;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
}
