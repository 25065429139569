.Input_inputWrapper {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 24px;
  font-size: @tx-size-base;
  color: @black-bold;
}

.Input_input {
  box-sizing: border-box;
  display: inline-block;
  font-size: @tx-size-base;
  min-height: 40px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  width: 100%;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% 50%;

  @media (min-width: 768px) {
    padding: 0 14px;
  }
}

.Input_input:disabled {
  background-color: #dcdad1;
}

.Input_labels {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
}

.Input_alignLeft {
  text-align: left;
}

.Input_alignRight {
  text-align: right;
}

.Input_labels > * {
  display: inline-block;
  width: 100%;
  font-size: @tx-size-base;
  margin-bottom: 0;
}

.Input_errorMessage {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
  color: #ff0000;
  font-size: @tx-size-base;
  letter-spacing: -0.2px;
}

.Input_validating {
  background-image: url("./img/form-validating.svg");
}

.Input_validInput {
  background-image: url("./img/form-valid.svg");
}

.Input_invalidInput {
  background-image: url("./img/form-error.svg");
  border: 2px solid @red;
}

.Input_invalidInput:focus {
  outline: none;
}
