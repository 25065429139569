.NotFoundItem_loading {
  margin: 24px;
  padding-top: 20px;
  font-size: @tx-size-large;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NotFoundItem_message {
  margin-bottom: 48px;
}
