.CurrentValue {
  display: flex;
  flex-direction: column;
}

.CurrentValue_valueWrapper {
  display: flex;
  align-items: baseline;
}

.CurrentValue_value {
  font-size: @tx-size-large;
  font-weight: bold;
  padding-right: 4px;

  @media only screen and (min-width: 768px) {
    font-size: @tx-size-xxl;
  }
}

.CurrentValue_unit {
  font-size: @tx-size-small;

  @media only screen and (min-width: 768px) {
    font-size: @tx-size-medium;
    font-weight: bold;
  }
}

.CurrentValue_strength {
  color: @blue-base;
}

.CurrentValue_temperature {
  color: @purple-base;
}

.CurrentValue_metricName {
  line-height: 0;
}
