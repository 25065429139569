.ConfirmationOverlay_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.ConfirmationOverlay_confirmationText {
  margin: 0;

  @media only screen and (min-width: 768px) {
    margin-right: 44px;
  }
}

.ConfirmationOverlay_confirmationButtons {
  display: flex;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: auto;
  }
}

.ConfirmationOverlay_confirmationButtons > *:not(:last-child) {
  margin-right: 8px;
}
