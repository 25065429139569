.TemperatureDifferentialForm {
  display: flex;
  width: 100%;
  margin-top: 80px;
  flex-direction: column;

  @media screen and (min-width: 768px), print {
    margin-top: 128px;
    justify-content: space-between;
    max-height: 352px;
  }
}

.TemperatureDifferentialForm_wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  max-height: 280px;

  @media screen and (min-width: 768px), print {
    flex-direction: column-reverse;
    max-height: 352px;
  }
}

.TemperatureDifferential_formWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media screen and (min-width: 768px), print {
    justify-content: space-between;
    max-height: 352px;
  }
}

.TemperatureDifferentialForm_title {
  font-size: @tx-size-large;
  height: 100%;
  padding-bottom: 8px;

  @media (min-width: 768px) {
    font-size: @tx-size-x-largepx;
    padding: 32px 0;
    width: 50%;
  }
}

.TemperatureDifferentialForm_form {
  display: flex;
  background: white;
  border-radius: 1px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  padding-top: 14px;
  padding-bottom: 21px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    height: 132px;
    align-items: space-around;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.TemperatureDifferentialForm_sensorWrapper {
  padding: 4px 24px;
  @media screen and (min-width: 768px) {
    width: 462px;
    height: 80px;
    padding: 22px 0 28px 24px;
  }
}

.TemperatureDifferentialForm_wrapperTime {
  padding: 4px 24px 12px 24px;
  @media screen and (min-width: 768px) {
    width: 200px;
    height: 80px;
    padding-top: 22px;
    padding-bottom: 28px;
  }
}

.TemperatureDifferentialForm_label {
  padding-bottom: 4px;
}
