.KpiDashboard {
  display: flex;
  flex-direction: column;
  margin-top: 76px;

  @media screen and (min-width: 768px), print {
    margin-top: 168px;
  }
}

.KpiDashboard_header {
  display: flex;
  z-index: 2;
  margin-bottom: 72px;
}

.KpiDashboard_site {
  display: flex;
  color: @white;
  margin: 0;
  font-size: @tx-size-xxl;
}

.KpiDashboard_organisation {
  display: flex;
  color: @white;
  opacity: 0.5;
  padding-left: 8px;
  align-self: flex-end;
  padding-bottom: 6px;
}

.KpiDashboard_mixSelector {
  display: flex;
  z-index: 2;
  font-size: @tx-size-large;
  color: @white;
}

.KpiDashboard_mixSelectorText {
  display: flex;
  padding-right: 8px;
}

.KpiDashboard_mixSelectorSelect {
  display: flex;
  width: 200px;
  border-bottom: @white solid 1px;
  height: 36px;
}

.KpiDashboard_cardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
  margin-top: 40px;
}

.KpiDashboard_spinner {
  margin-top: 200px;
}
