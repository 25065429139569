.SensorCard {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border: 0;

  @media only screen and (min-width: 768px) {
    padding: 0;
  }
}

.SensorCard_topPanel {
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 32px;
    border-bottom: solid 1px @grey-10;
  }
}

.SensorCard_middlePanel {
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    height: 160px;
    background-color: @grey-20;
  }
}

.SensorCard_bottomPanel {
  display: none;
  @media only screen and (min-width: 768px) {
    display: flex;
    height: 32px;
    padding: 0 32px;
    background-color: @grey-30;
  }
}

.SensorCard_wrapper {
  position: relative;
  margin-top: 16px;
  border: solid 1px @grey-10;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  transition: box-shadow 250ms;

  @media only screen and (min-width: 768px) {
    box-shadow: none;
  }
}

.SensorCard_wrapper:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.SensorCard_link {
  text-decoration: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.SensorCard_sensorInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SensorCard_mobileArrowIcon {
  display: flex;
  height: 100%;
}

.SensorCard_sensorName {
  font-size: @tx-size-medium;
  color: @grey-110;

  @media only screen and (min-width: 768px) {
    font-size: @tx-size-large;
  }
}

.SensorCard_offlineBar {
  border-left: solid 2px @red-sidebar;
}

.SensorCard_currentValueWrapper {
  display: flex;
  width: 70%;
  padding-left: 6px;

  @media only screen and (min-width: 768px) {
    height: 160px;
    width: 25%;
    padding-left: 32px;
  }
}

// ARCHIVED CARDS

.SensorCard_archived {
  z-index: -1;
}

.SensorCard_archivedContent {
  display: flex;
  height: 32px;
  @media only screen and (min-width: 768px) {
    height: 190px;
  }
}

.SensorCard_archivedContentColumn {
  display: flex;
  flex-direction: column;
}

.SensorCard_archivedContentBlueBox {
  width: 80px;
  height: 24px;
  background-color: @blue-5;
  margin-top: 4px;
  @media only screen and (min-width: 768px) {
    margin-left: 16px;
    margin-left: 32px;
    margin-top: 32px;
    height: 32px;
  }
}

.SensorCard_archivedContentPurpleBox {
  margin-left: 16px;
  margin-top: 4px;
  width: 80px;
  height: 24px;
  background-color: @purple-5;
  @media only screen and (min-width: 768px) {
    height: 32px;
    margin-left: 32px;
    margin-top: 32px;
  }
}

.SensorCard_archivedContentGreyBox {
  margin-top: 16px;
  margin-left: 32px;
  width: 90px;
  height: 16px;
  background-color: @grey-90;
}

.SensorCard_archivedContentLargeGreyBox {
  margin-top: 4px;
  margin-left: 32px;
  width: 110px;
  height: 16px;
  background-color: @grey-90;
}

.SensorCard_archivedContentMuchBiggerGreyBox {
  margin-top: 4px;
  margin-left: 32px;
  width: 288px;
  height: 16px;
  background-color: @grey-90;
}

.SensorCard_archivedBottom {
  display: flex;
  height: 32px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.SensorCard_archivedWrapper {
  position: relative;
}

.SensorCard_archivedViewSensorData {
  position: absolute;
  left: calc(~"50% - 200px");
  top: calc(~"50% - 30px");
  z-index: 5;
  width: 400px;
  height: 48px;
  background-color: @blue-archive-button;
  color: @grey-110;
  border-style: none;
  font-size: @tx-size-base;
  text-decoration: none;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SensorCard_graph {
  @media only screen and (min-width: 768px) {
    display: flex;
    width: 62%;
    padding: 8px 32px 8px 0;
  }
}
