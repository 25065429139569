.NotificationPreferencesView_bodyRow {
  display: flex;
  padding: 0 16px;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
  }
}

.NotificationPreferencesView_bodyRow:not(:nth-child(1)) {
  padding-top: 16px;
}

.NotificationPreferencesView_notificationDescription {
  flex: 7;
}

.NotificationPreferencesView_notificationDescriptionTitle {
  font-size: @tx-size-base;
  color: @black-bold;
  font-weight: 800;
  line-height: 1.29;
  margin-bottom: 8px;
}

.NotificationPreferencesView_notificationDescriptionBody {
  color: @black-bold;
  line-height: 1.83;
  font-size: @tx-size-small;
}

.NotificationPreferencesView_notificationControls {
  flex: 1;
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 800;
  font-size: @tx-size-base;

  @media (min-width: 768px) {
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
  }
}
