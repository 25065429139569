.PrintReportButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inline-svg-code( @code ) {
  @-svg-code: escape(~'<?xml version="1.0" ?>@{code}');
  @-inline-svg-code: ~"data:image/svg+xml,@{-svg-code}";
  @-inline-svg-url: ~"url('@{-inline-svg-code}')";
}

.PrintReportButton_icon {
  width: 28px;
  height: 24px;
  .inline-svg-code(@print-icon);
  background-image: @-inline-svg-url;
  background-repeat: no-repeat;
}
