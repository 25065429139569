.KpiCardPlaceholder {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 72px 40px;
  font-size: @tx-size-medium-small;
}

.KpiCardPlaceholder_title {
  display: flex;
  margin-bottom: 8px;
}

.KpiCardPlaceholder_body {
  display: flex;
  text-align: center;
}
