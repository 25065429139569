.UserDropdown_wrapper {
  padding-right: 0;
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
}

.UserDropdown_wrapper .NavDropdown_options {
  transform: none;

  @media only screen and (max-width: 768px) {
    opacity: 1;
    display: flex;
    transform: translateY(20%);
    flex-direction: column;
    min-height: 100%;
  }
}

.UserDropdown_wrapper .NavDropdown_item:hover {
  -webkit-text-stroke: 0.75px @white;
}

.UserDropdown_wrapper .NavDropdown_hr {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.UserDropdown_wrapper .NavDropdown_item {
  padding: 0.4em;

  @media only screen and (max-width: 768px) {
    padding: auto;
    padding-left: 0.4em;
    padding: 0;
    display: flex;
  }

  &:last-child {
    transform: translateY(-10%);
  }
}

.UserDropdown_wrapper .NavDropdown_label {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    order: 3;
    min-height: 100%;
  }
}

.UserDropdown_divider {
  margin-top: 28px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.UserDropdown_wrapper .NavDropdown_arrow {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
