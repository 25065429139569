.InventoryAlerts_wrapper {
  margin-top: 32px;
}

.InventoryAlerts_alertLink {
  display: block;
  margin: 8px 0 0 0;
}

.InventoryAlerts_wrapper > * {
  margin-top: 20px;
}
