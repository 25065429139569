// Default

html {
  height: 100%;
  width: 100%;
}

body {
  background: @background;
  color: @grey-110;
  font-family: @font-family;
  font-size: @tx-size-base;
  line-height: 1.57;
  letter-spacing: 0.6px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  height: 100%;
  width: 100%;
  margin: 0px;
  background-size: 100% 100%;
}

// white text on blue
*.white {
  background: @blue-100;
  color: @white;
}

// blue text on white
*.blue {
  background: @white;
  color: @blue-100;
}

h1 {
  font-size: @tx-size-x-large;
  line-height: 1.33;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

h2 {
  font-size: @tx-size-large;
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin-top: 24px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-top: 32px;
  }
}

h3 {
  font-size: @tx-size-medium;
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

a {
  color: @blue-100;
  &:hover &:focus {
    color: @blue-60;
  }
  &:visited {
    color: @blue-110;
  }
}

a.white {
  color: @white;
  &:hover &:focus {
    color: @white; // this is probably wrong, should be grey?
  }
  &:visited {
    color: @white; // same here
  }
}

a.blue {
  color: @blue-100;
  &:hover &:focus {
    color: @blue-60;
  }
  &:visited {
    color: @blue-110;
  }
}

.metadata {
  font-size: @tx-size-small;
  line-height: 1.83;
}
