.SensorDetailTop {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;

  @media screen and (min-width: 768px), print {
    flex-direction: row;
    margin-top: 168px;
    justify-content: space-between;
    max-height: 352px;
  }

  @media print {
    margin-top: 0px;
  }
}

.SensorDetailTop_name {
  font-size: @tx-size-x-largepx;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.SensorDetailTop_editButton {
  width: 84px;
  height: 32px;
  padding-left: 32px;
}

.SensorDetailTop_registeredBy {
  font-size: @tx-size-small;
  @media screen and (min-width: 768px), print {
    font-size: @tx-size-base;
  }
}

.SensorDetailTop_archive {
  display: flex;
  justify-content: center;
  padding-top: 24px;
}
