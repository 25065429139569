// print header classes. These are used only when printing.

.TemperatureDifferentialPrintHeader_printHeader {
  font-size: @tx-size-small;
  display: flex;
  width: 100%;
  margin-top: 8mm;
}

.TemperatureDifferentialPrintHeader_printHeaderFirstLine {
  display: flex;
}

.TemperatureDifferentialPrintHeader_printHeaderWrapper {
  display: flex;
  flex-direction: column;
}

.TemperatureDifferentialPrintHeader_printHeaderTitle {
  font-size: @tx-size-large;
}

.TemperatureDifferentialPrintHeader_printHeaderTitle {
  font-size: @tx-size-large;
}

.TemperatureDifferentialPrintHeader_printHeaderLogoWrapper {
  margin-left: auto;
}

.TemperatureDifferentialPrintHeader_printHeaderLogo {
  width: 110px;
}

.TemperatureDifferentialPrintHeader_printHeaderCreatedBy {
  margin-left: 50px;
}

.TemperatureDifferentialPrintHeader_siteName {
  margin-top: 16px;
  font-size: @tx-size-small;
}

.TemperatureDifferentialPrintHeader_printHeaderSecondLine {
  margin-top: 0px;
  display: flex;
}

.TemperatureDifferentialPrintHeader_sensorName {
  font-size: @tx-size-large;
}

.TemperatureDifferentialPrintHeader_printHeaderTimePeriod {
  font-size: @tx-size-small;
  margin-left: auto;
  display: flex;
}

.TemperatureDifferentialPrintHeader_printHeaderTimePeriod span {
  align-self: flex-end;
}
