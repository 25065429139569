.SensorMetrics {
  @media only screen and (min-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    pointer-events: none;
    z-index: 1;
  }
}

.SensorMetrics_metricWrapper {
  display: none;
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
}

.SensorMetrics_connectedNode {
  display: none;
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    font-size: @tx-size-small;
    color: @black-bold;
    padding-top: 2px;
  }
}

.SensorMetrics_registeredAt {
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    font-size: @tx-size-small;
    color: @black-bold;
    padding-top: 2px;
  }
}

.SensorMetrics_pouredAt {
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    font-size: @tx-size-small;
    color: @black-bold;
    margin-right: 32px;
    padding-top: 2px;
  }
}

.SensorMetrics_actionLinkWrapper {
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }
}

.SensorMetrics_link {
  text-decoration: underline;
  font-size: @tx-size-base;
  color: @blue-base;
  pointer-events: all;
  position: relative;
}

.SensorMetrics_link:visited {
  color: @blue-base;
}
