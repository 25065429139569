.DefaultLayout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  background-color: @background;

  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    padding-bottom: 16px;
  }

  @media only print {
    background-color: transparent;
  }
}

.DefaultLayout_background {
  position: absolute;
  top: 136px;
  width: 100vw;
  height: 116px;
  left: 0;
  background-image: url("./img/white-background-light.svg");
  background-repeat: repeat-x, no-repeat;
  pointer-events: none;

  @media only print {
    display: none;
    background-image: none;
  }
}

.DefaultLayout_topBar {
  z-index: 3;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  box-shadow: -20px -19px 23px 0px rgba(0, 0, 0, 0.15);
  transition: box-shadow 250ms;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.15)
    ),
    linear-gradient(111deg, @blue-85 1%, @blue-105),
    linear-gradient(
      180deg,
      transparent 51%,
      rgba(0, 0, 0, 0.15) 99%,
      rgba(0, 0, 0, 0.15)
    );

  @media only screen and (max-width: 768px) {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 48px;
  }

  @media only print {
    display: none;
  }
}

.DefaultLayout_blueBackground {
  background-image: linear-gradient(91deg, @blue-85, @blue-105);
}

.DefaultLayout_withNavbarShadow {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
}

.DefaultLayout_container {
  box-sizing: border-box;
  display: flex;
  align-self: center;
  max-width: 1220px;
  width: 100%;
  height: 100%;
  padding-bottom: 16px;

  @media only screen and (max-width: 768px) {
    display: flex;
    max-width: 100vw;
    padding: 0 16px 16px 16px;
    height: auto;
  }

  @media print {
    padding-bottom: 0;
  }
}

.DefaultLayout_content {
  width: 100%;
}
