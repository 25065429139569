.DeleteBubble_wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  @media only screen and (min-width: 768px) {
    border-radius: 0;
    box-shadow: none;
    background-color: none;
    position: initial;
    left: auto;
  }
}
