.ControlsPlaceholder {
  display: flex;
  border: 1px solid #e1e1e1;
  border-radius: 1px;
  width: 100%;
  padding: 8px 8px 8px 16px;
  height: auto;
  box-sizing: border-box;
}

.ControlsPlaceholder_text {
  display: flex;
  padding-right: 31px;
}

.ControlsPlaceholder_icon {
  display: flex;
}
