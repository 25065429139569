.TabbedView {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
  flex-wrap: wrap;

  @media only screen and (min-width: 768px) {
    height: 48px;
    justify-content: space-between;
  }
}

.TabbedView_controls {
  display: flex;
}

.TabbedView_controlWrapper:first-child {
  margin-right: 32px;

  @media only screen and (min-width: 768px) {
    margin-right: 72px;
  }
}

.TabbedView_controlWrapper {
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  padding-bottom: 4px;
  cursor: pointer;
}

.TabbedView_control {
  text-decoration: none;
  color: @grey-110;
  font-size: @tx-size-medium;
  padding-right: 8px;
}

.TabbedView_controlWrapper:hover {
  border-bottom: 2px solid @blue-5;
}

.TabbedView_currentControl,
.TabbedView_currentControl:hover {
  border-bottom: 2px solid @blue-90;
}

.TabbedView_controlWrapper:hover .TabbedView_control {
  color: @blue-base;
}

.TabbedView_currentControl:hover .TabbedView_control {
  color: @grey-110;
}

.TabbedView_sensorCount {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: @tx-size-small;
  background-color: rgba(34, 35, 46, 0.05);
  border-radius: 2px;
  padding: 0 8px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: @tx-size-medium-small;
    border-radius: 5px;
  }
}

.TabbedView_plusIcon {
  padding-right: 8px;
}

.TabbedView_noChildren {
  display: none;
}

.TabbedView_lists {
  display: flex;
  width: 100%;
}
