.RequestPasswordResetView {
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  min-height: 7em;
  border-radius: 0.5em 0.5em 0em 0em;
  color: @grey-110;
  background-color: @white;
  left: 0%;
  box-shadow: 0px -1px 1px @grey-base;
}

.RequestPasswordResetView_form {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1em;
  @media only screen and (min-width: 768px) {
    width: 400px;
  }
}

.RequestPasswordResetView_formItem {
  font-size: @tx-size-base;
  width: 100%;
  flex-basis: 5em;
}

.RequestPasswordResetView_formTitle {
  .RequestPasswordResetView_formItem;
  font-size: @tx-size-medium;
  padding-top: 1em;
  @media only screen and (orientation: landscape) {
    flex-basis: 2em;
  }
}

.RequestPasswordResetView_formInput {
  font-size: @tx-size-base;
  width: 100%;
}

.RequestPasswordResetView_formButton {
  .LoginView_formItem;
  display: flex;
  align-items: center;
}

.RequestPasswordResetView_transition-appear,
.RequestPasswordResetView_transition-enter {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: transform 250ms linear;
}

.RequestPasswordResetView_transition-appear-active,
.RequestPasswordResetView_transition-enter-active {
  -webkit-transform: none;
  transform: none;
  transition: transform 250ms linear;
}

.RequestPasswordResetView_transition-exit-active {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: transform 250ms linear;
}
