.EditButton_editIcon {
  height: 12px;
  width: 12px;
  margin: 0 4px;
}

.EditButton_editButtonText {
  padding: 4px;
  display: flex;
  align-items: center;
  margin-right: 12px;
}
