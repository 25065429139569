.TemporaryThresholds_wrapper {
  width: 100%;
}

.TemporaryThresholds_titleLine {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.TemporaryThresholds_titleBlock {
  margin-bottom: 16px;
}

.TemporaryThresholds_title {
  margin: 0;
}

.TemporaryThresholds_tableWrapper {
  width: 100%;
  border: solid 1px @grey-10;
}

.TemporaryThresholds_value {
  font-weight: 800;
}

.TemporaryThresholds_headerRow {
  padding: 16px 24px;
  color: @blue-base;
  font-weight: 800;
  border-bottom: solid 1px @grey-10;
}

.TemporaryThresholds_rowSection {
  padding: 0 24px;
}

.TemporaryThresholds_rowWrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}

.TemporaryThresholds_footer {
  border-top: solid 1px @grey-10;
  padding: 16px;
  background-color: rgba(35, 38, 53, 0.05);

  @media (min-width: 768px) {
    background-color: unset;
  }
}

.TemporaryThresholds_footerControls {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.TemporaryThresholds_footerRemoval {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.TemporaryThresholds_footerRemovalText {
  text-decoration: underline;
}

.TemporaryThresholds_separator {
  width: 100%;
  height: 1px;
  background-color: @grey-10;
}

.TemporaryThresholds_deleteIcon {
  cursor: pointer;
}
