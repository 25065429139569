.NotificationPreferencesView_wrapper {
  overflow-x: visible;
  margin-top: 88px;

  /*
     * This is temporary.
     * It will only stay here until we style inputs properly.
     */
  input {
    box-shadow: none;
    height: auto;
  }

  @media (min-width: 768px) {
    margin-top: 136px;
  }
}

.NotificationPreferencesView_title {
  margin: 0 0 16px;

  @media (min-width: 768px) {
    margin: 0 0 24px;
  }
}

.NotificationPreferencesView_content {
  padding-top: 24px;

  @media (min-width: 768px) {
    padding-top: 40px;
  }
}

.NotificationPreferencesView_tableWrapper {
  width: 100%;
  box-sizing: border-box;
}

.NotificationPreferencesView_tableContent {
  padding: 16px 0 0 0;

  @media (min-width: 768px) {
    padding: 0;
  }
}

.NotificationPreferencesView_separatorWrapper {
  @media (min-width: 768px) {
    padding: 0 24px;
  }
}

.NotificationPreferencesView_rowSeparator {
  height: 1px;
  width: 100%;
  background-color: @grey-base;
}

.NotificationPreferencesView_tableHeader {
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 0 24px;
    font-weight: 800;
    font-size: @tx-size-base;
    border-bottom: solid 1px #eceded;
  }
}

.NotificationPreferencesView_descriptionHeader {
  flex: 14;
}

.NotificationPreferencesView_controlHeader {
  text-align: center;
  flex: 1;
}

.NotificationPreferencesView_tableFooter {
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    margin: 24px;
  }
}

.NotificationPreferencesView_tableFooter > * {
  margin: 0 8px;
}

.NotificationPreferencesView_topLink,
.NotificationPreferencesView_topLink:visited {
  font-size: @tx-size-base;
  font-weight: 800;
  text-decoration: none;
  color: @black;
}
