.SubNavbar_topBar {
  position: fixed;
  z-index: 3;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 56px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 768px), print {
    top: 48px;
    height: 40px;
    border-radius: 0 0 10px 10px;
    padding: 0 16px;
  }
}

.SubNavbar_topBarChildren {
  width: 1220px;

  @media (max-width: 768px) {
    top: 48px;
  }
}

.SubNavbar_spacer {
  @media (max-width: 768px) {
    display: none;
  }
}
