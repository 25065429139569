.NotificationPreferencesView_notificationControl {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;

  @media (min-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
}

.NotificationPreferencesView_controlLabel {
  padding-left: 4px;

  @media (min-width: 768px) {
    display: none;
  }
}
