// Common to every button
.Button {
  border-style: none;
  font-size: @tx-size-base;
  text-decoration: none;
  border-radius: 2px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &:visited {
    color: @white;
  }

  &:visited {
    color: @white;
  }

  // Not sure about the colors, they change with opacity. TODO: Check if OK.
  &:disabled {
    background: @grey-base;
    color: @grey-110;
    opacity: 0.3;
    &:hover {
      background: @grey-base;
    }

    &:active {
      background: @grey-110;
    }

    &:focus {
      background: @grey-60;
    }
  }
}

// ***************
// Colors
// ***************
.Button_blue {
  color: @white;
  background-color: @blue-base;

  &:hover {
    background: @blue-80;
  }

  &:active {
    background: @blue-110;
  }

  &:focus {
    background: @blue-60;
  }
}

.Button_black {
  color: @white;
  background-color: @black;
}

.Button_red {
  color: @white;
  background-color: @red;
}

.Button_begonia {
  background-color: @begonia-base;
  color: @white;

  &:hover {
    background: @begonia-80;
  }

  &:active {
    background: @begonia-110;
  }

  &:focus {
    background: @begonia-60;
  }
}

.Button_green {
  background-color: @green-base;
  color: @white;

  &:hover {
    background: @green-80;
  }

  &:active {
    background: @green-110;
  }

  &:focus {
    background: @green-60;
  }
}

.Button_yellow {
  background-color: @yellow-base;
  color: @white;

  &:hover {
    background: @yellow-80;
  }

  &:active {
    background: @yellow-110;
  }

  &:focus {
    background: @yellow-60;
  }
}

.Button_purple {
  background-color: @purple-base;
  color: @white;

  &:hover {
    background: @purple-80;
  }

  &:active {
    background: @purple-110;
  }

  &:focus {
    background: @purple-60;
  }
}

.Button_white {
  background-color: @white;
  border: solid 1px @white;
  color: @grey-110;

  &:hover {
    background: @grey-30;
  }
}

.Button_ghost {
  color: @grey-110;
  background-color: transparent;
  border: solid 1px @grey-base;
}

.Button_ghostWhite {
  color: @white;
  background-color: transparent;
  border: solid 1px @white;
}

// ***************
// Sizes
// ***************
.Button_standard {
  height: @bt-heigth-standard;

  // when in laptop, the standard buttons will always have a fixed width of 400 pixels
  @media only screen and (min-width: 768px) {
    width: @bt-size-x-large;
  }
}

.Button_extra-small {
  height: @bt-heigth-x-small;
  width: @bt-width-x-small;
}

a.Button_extra-small {
  line-height: @bt-heigth-x-small;
}

.Button_small {
  height: @bt-heigth-small;
  width: @bt-width-small;
}

a.Button_small {
  line-height: @bt-heigth-small;
}

.Button_medium {
  height: @bt-heigth-standard;
  width: @bt-size-medium;
}

// Alert Buttons have standard height)
.Button_alert {
  height: @bt-heigth-standard;
  width: @bt-width-small;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
}

a.Button_alert {
  line-height: @bt-heigth-small;
}

.Button_fullWidth {
  width: 100%;
}

.Button_outlined {
  border: 1px solid @grey-110;
}
