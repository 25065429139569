.HeaderRow_wrapper {
  box-sizing: border-box;
  padding: 24px;
  height: 70px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-weight: 800;
  border-top: solid 1px @card-border;
  border-bottom: solid 1px @card-border;
}

.HeaderRow_temperatureHeader {
  color: @purple-base;
}

.HeaderRow_strengthHeader {
  color: @blue-base;
}
