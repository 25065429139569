.ProgressBar {
  display: flex;
  position: relative;
  background-color: @blue-base;
  transition: all ease 250ms;
  width: 2px;
  top: 0;

  @media screen and (min-width: 768px), print {
    height: 2px;
    width: 0;
    left: 0;
  }
}

.ProgressBar_delay {
  transition-delay: 2s;
}

.ProgressBar_step0 {
  top: 0;
  height: 100px;

  @media screen and (min-width: 768px) {
    height: 2px;
    top: 0;
    left: 0;
    width: 40%;
  }
}

.ProgressBar_step1 {
  top: 100px;
  height: 100px;

  @media screen and (min-width: 768px) {
    height: 2px;
    top: 0;
    left: 40%;
    width: 40%;
  }
}

.ProgressBar_step2 {
  top: 200px;
  height: 80px;

  @media screen and (min-width: 768px) {
    height: 2px;
    top: 0;
    left: 80%;
    width: 20%;
  }
}

.ProgressBar_complete {
  top: 0;
  height: 280px;

  @media screen and (min-width: 768px) {
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
  }
}
