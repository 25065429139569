.BlueBackgroundLayout_background {
  @media (min-width: 768px) {
    position: absolute;
    width: 100vw;
    height: 428px;
    left: 0;
    background-image: linear-gradient(97deg, @blue-85, @blue-105);
    pointer-events: none;
  }
}
