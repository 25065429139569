.TemperatureRowGroup_transition-enter {
  height: 0px;
  opacity: 0.01;
}

.TemperatureRowGroup_transition-enter.ThresholdsRowGroup_transition-enter-active {
  max-height: 100px;
  opacity: 1;
  transition: height 150ms ease-in;
  transition: opacity 150ms ease-in;
}

.TemperatureRowGroup_transition-exit {
  max-height: 100px;
  opacity: 1;
}

.TemperatureRowGroup_transition-exit.TemperatureRowGroup_transition-exit-active {
  height: 0px;
  opacity: 0.01;
  transition: height 150ms ease-in;
  transition: opacity 150ms ease-in;
}
