.CalculateConcreteStrengthView_calculateFormTitle {
  font-size: @tx-size-medium;
  font-weight: 800;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: @tx-size-large;
    font-weight: 400;
  }
}

.CalculateConcreteStrengthView_sensorName {
  display: inline-block;
  margin-top: 8px;
}

.CalculateConcreteStrengthView_formWrapper {
  margin-top: 24px;
}

.CalculateConcreteStrengthView_controlButtons {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-top: 32px;
    margin-bottom: 32px;
    flex-direction: row;
  }
}

.CalculateConcreteStrengthView_controlButtons > *:not(:last-child) {
  margin-top: 8px;

  @media (min-width: 768px) {
    margin-right: 16px;
    margin-top: 0;
  }
}
