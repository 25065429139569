.CurrentValuePanel {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media only screen and (min-width: 768px) {
    align-items: center;
    padding-bottom: @tx-size-base;
  }
}
