.Navbar {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 4;
  color: #fff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  pointer-events: none;
  transform: translateX(-50%);

  @media only screen and (max-width: 768px) {
    left: 0;
    height: 100%;
    width: 100vw;
    transform: none;
  }

  @media only print {
    display: none;
  }
}

.Navbar_top {
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex: 0;
    align-items: center;
    pointer-events: all;
    flex: 1;
    padding: 0 2em 0 0;
    justify-content: space-between;
    height: 48px;
  }
}

.Navbar_left:before {
  opacity: 0.1;
  padding-left: 0.5em;
  color: #ffffff;

  @media only screen and (max-width: 768px) {
    content: "";
    padding: 0;
  }
}

.Navbar_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  pointer-events: all;

  @media only screen and (max-width: 768px) {
    display: flex;
    position: relative;
    z-index: 2;
    transform: translateX(15%);
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-height: 4em;
    width: 100%;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
    border-width: 0 0 1px 0;
    padding: 0;
  }
}

.Navbar_selector {
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    width: 100%;
  }
}

.Navbar_middle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  pointer-events: all;
  padding-left: 4em;

  @media only screen and (max-width: 768px) {
    position: relative;
    z-index: 1;
    flex: 1;
    transform: translateX(15%);
    padding-bottom: 2em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 0;
  }
}

.Navbar_right {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  pointer-events: all;

  @media only screen and (max-width: 768px) {
    > .NavItem {
      min-height: 100%;
    }

    position: relative;
    transform: translateX(15%);
    max-height: 6em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 85%;
    padding: 0 1em;
    padding-left: 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
    border-width: 1px 0 0 0;
  }
}

.Navbar_drawer {
  display: flex;
  width: 100%;
  max-width: 1220px;
  width: 100%;
  height: 80px;
  flex: 3 0 auto;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition-duration: 0.75s;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-image: linear-gradient(
        111deg,
        rgba(15, 114, 227, 1) 1%,
        rgba(52, 53, 218, 1) 100%
      ),
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 51%,
        rgba(0, 0, 0, 0.15) 99%,
        rgba(0, 0, 0, 0.15) 100%
      );
  }
}

.Navbar_toggle {
  display: none;

  @media only screen and (max-width: 768px) {
    height: 100%;
    padding: 0 1em;
    display: flex;
    background-color: rgba(0, 0, 0, 0.13);
    align-items: center;
  }
}

.Navbar_brandIcon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8em;
  width: 116px;
  height: 26px;

  @media only screen and (max-width: 768px) {
    width: 86px;
    height: 20px;
  }
}

.Navbar_iconDt {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.Navbar_menuLabel {
  font-size: 15.5px;
  padding-left: 0.2em;
}

.Navbar_menuIcon {
  height: 24px;
  width: 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.Navbar_clickableBg {
  @media only screen and (max-width: 768px) {
    pointer-events: all;
    cursor: pointer;
  }
}

.Navbar_closedDrawer {
  @media only screen and (max-width: 768px) {
    transform: translateX(-100%);
  }
}

.Navbar_openDrawer {
  @media only screen and (max-width: 768px) {
    transform: translateX(-15%);
  }
}

/*
 * This is because in the specific circunstance of the alert in mobile
 * being on top of the MENU label we need to reposition it only there.
 * This doesn't happen on any menu items elsewhere and therefore I added
 * this here to avoid adding an extra property to the BlinkingRedDot
 * component unnecessarily just for this single case. Please avoid
 * nesting rules if it's easier to just style them elsewhere.
 */
.Navbar_top .BlinkingRedDot_wrapper {
    position: relative;
    top: -20px;
    left: -4px;
}
