.ProgressBar_total {
  position: relative;
  margin: 2px;
  height: 16px;
  width: 97%;
}

.ProgressBar_empty {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: @grey-90;
  height: 16px;
}

.ProgressBar_progressOverflow {
  overflow: hidden;
  border-radius: 8px;
  background-color: @grey-base;
}

.ProgressBar_progress {
  border-radius: 8px;
  height: 16px;
  width: 0%;
}

.ProgressBar_percentage {
  position: absolute;
  transform: translate(15%, -50%);
  right: 10px;
  top: 50%;
  display: inline-block;
  font-size: 10px;
  color: @black;
}

.ProgressBar_blue {
  background-color: @blue-base;
}

.ProgressBar_green {
  background-color: @green-base;
}
