.SubNavSearchBox_filterControl {
  display: flex;
  align-items: center;
  padding-right: 4px;
  transition: 250ms;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 100%;
  }
}

.SubNavSearchBox_isSearching {
  width: 100%;
  border-bottom: 1px solid @black;

  @media only screen and (min-width: 768px) {
    border: none;
  }
}
