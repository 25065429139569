.SensorsIndexView {
  display: flex;
  flex-direction: column;
  position: relative;
}

.SensorsIndexView_addSensorButton {
  width: 150px;
  position: fixed;
  bottom: 8px;
  right: 16px;
  z-index: 3;

  @media only screen and (min-width: 768px) {
    display: flex;
    width: 165px;
    position: absolute;
    right: 0;
    bottom: 16px;
    z-index: 2;
  }
}

.SensorsIndexView_sensorLists {
  display: flex;
  width: 100%;
  margin-top: 112px;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    margin-top: 176px;
    height: 48px;
    justify-content: space-between;
  }
}

.SensorsIndexView_addSensorMobileWrapper {
  width: 150px;
  position: fixed;
  bottom: 8px;
  right: 16px;
}

.SensorsIndexView_addSensorDesktopWrapper {
  display: none;
}

.SensorsIndexView_plusIcon {
  padding-right: 8px;
}

.SensorsIndexView .SubNavbar_topBarChildren {
  display: flex;
  flex-wrap: nowrap;
}

.SensorsIndexView_siteSelector {
  display: none;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
}

.SensorsIndexView_noDurationSelector {
  @media only screen and (min-width: 768px) {
    display: flex;
  }
}

.SensorsIndexView_durationSelector {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 4px;

  @media only screen and (min-width: 768px) {
    width: 236px;
  }
}

.SensorsIndexView_clock {
  width: 17.5px;
  height: 17.5px;
}

.SensorsIndexView_noActiveArchive {
  display: none;
}

.SensorsIndexView_rightSide {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media only screen and (min-width: 768px) {
    width: 50%;
  }
}

.SensorsIndexView_filter {
  display: flex;
  width: 100%;

  @media only screen and (min-width: 768px) {
  }
}
