.total {
  margin: 25px 5px;
  width: 97%;
}

.BoundariesBar_progressOverflow {
  position: relative;
  bottom: 7px;
  height: 16px;
  overflow: hidden;
  border-radius: 8px;
  background-color: @grey-base;
}

.BoundariesBar_boundaryBarLeft,
.BoundariesBar_boundaryBarMiddle,
.BoundariesBar_boundaryBarRight {
  display: inline-block;
  height: 16px;
}

.BoundariesBar_boundaryBarLeft {
  width: 70%;
}

.BoundariesBar_boundaryBarMiddle {
  width: 15%;
}

.BoundariesBar_boundaryBarRight {
  width: 15%;
}

.BoundariesBar_tooltipWrapper {
  position: relative;
  z-index: 2;
  display: inline-block;
  transform: translateX(-50%);
}

.BoundariesBar_tooltip {
  position: relative;
  background-color: @purple-base;
  color: @white;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 8px;
}

.BoundariesBar_tooltipTriangle {
  position: relative;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid @purple-base;
  left: 50%;
  transform: translateX(-50%);
}

.BoundariesBar_left {
  left: 35%;
}

.BoundariesBar_middle {
  left: 77.5%;
}

.BoundariesBar_right {
  left: 92.5%;
}

.BoundariesBar_red {
  background-color: @red;
}

.BoundariesBar_yellow {
  background-color: @yellow-base;
}

.BoundariesBar_green {
  background-color: @green-base;
}
