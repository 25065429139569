.InventorySection_sectionWrapper {
  @media (min-device-width: 320px) and (max-device-width: 480px) {
    width: 100%;
    overflow-x: visible;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 16px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    padding: 4px;
    width: 90vw;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: solid transparent;
    border-left-width: 12px;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.InventorySection_sectionHeader {
  display: flex;
  align-items: baseline;
}

.InventorySection_sectionTitle {
  display: inline-block;
  margin: 42px 0 24px 0;
}

.InventorySection_hardwareCards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1220px;

  @media (min-device-width: 320px) and (max-device-width: 768px) {
    display: flex;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100vw;
    max-width: 100vw;
    margin-left: -16px;
    padding: 0 8px 0 0;

    &:before {
      content: "";
      display: inline-block;
      min-height: 1px;
      min-width: 8px;
    }

    &:after {
      content: "";
      display: inline-block;
      min-height: 1px;
      min-width: 1px;
    }
  }
}

.InventoryCountBadge_wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  padding: 2px 6px;
  border-radius: 19.5px;
  background-color: rgba(34, 35, 46, 0.05);

  @media (min-device-width: 320px) and (max-device-width: 480px) {
    margin: 8px;
  }
}

.InventoryCountBadge_count {
  font-size: @tx-size-base;
}

.DevicesOfflineCount_wrapper {
  display: inline-flex;
  align-items: baseline;
  margin-left: 40px;
  font-weight: bold;
  color: @red;
}

.InventorySection_criticalAlertIcon {
  transform: translateY(25%);
  margin-right: 4px;
}
