.SensorDetailAlerts_wrapper {
  margin-top: 20px;
}

.SensorDetailAlerts_wrapper > * {
  margin: 20px 0;
}

.SensorDetailAlerts_alertLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0 0 0;
}

.SensorDetailAlerts_offlineNode {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
}

.SensorDetailAlerts_offlineNodeText {
  font-weight: 800;

  // We cannot use flex-basis because of IE11 support
  flex: 4 1;

  @media (min-width: 768px) {
    flex: none;
  }
}

.SensorDetailAlerts_sensorImage {
  transform: rotate(90deg);
}

.SensorDetailAlerts_nodeImage {
  height: 24px;
  width: 24px;
}

.SensorDetailAlerts_offlineImage {
  height: 24px;
  width: 24px;
}

.SensorDetailAlerts_offlineIcons {
  display: inline-flex;
  align-items: center;
  margin-right: 4px;

  // We cannot use flex-basis because of IE11 support
  flex: 1 1;

  @media (min-width: 768px) {
    flex: none;
  }
}

.SensorDetailAlerts_buttonWrapper {
  margin-top: 16px;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    margin-top: 0;
    margin-left: 12px;
  }
}

.SensorDetailAlerts_previousSensorCache {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  flex-wrap: wrap;

  // These are only here because otherwise the declaration
  // of the component would be cluttered by *many* non-semantic
  // elements and we wouldn't be able to reuse icons as we should
  // If we weren't reusing them or if there would be non-semantic
  // elements anyway we could simply apply classes, but that's
  // not the case and therefore I judged it was better to nest
  // these selectors here.
  & img {
    margin-right: 12px;
  }

  & a {
    font-weight: normal;
  }

  & > *:not(:last-child) {
    @media (min-width: 768px) {
      margin-right: 72px;
    }
  }
}

.SensorDetailAlerts_previousSensorNodeInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    margin-top: 0;
    justify-content: flex-start;
  }
}
.SensorDetailAlerts_previousSensorInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    margin-top: 0;
    justify-content: flex-start;
  }
}
