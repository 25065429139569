.PourViewSubnavbar_filter {
  display: flex;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 346px;
  }
}

.PourViewSubnavbar_allPoursLink {
  display: none;
  color: @black-bold;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    display: flex;
    width: 100%;
  }
}
