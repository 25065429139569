.PoursIndexView {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 88px;

  @media only screen and (min-width: 768px) {
    margin-top: 136px;
  }
}

.PoursIndexView_header {
  display: flex;
}

.PoursIndexView_headerText {
  display: flex;
  margin-top: 24px;

  @media only screen and (min-width: 768px) {
    margin-top: 37px;
  }
}

.PoursIndexView_pourCount {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: @tx-size-medium-small;
  background-color: @grey-base;
  border-radius: 2px;
  padding: 0 8px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
  margin-left: 8px;
  @media only screen and (min-width: 768px) {
    font-size: @tx-size-medium-small;
    background-color: rgba(34, 35, 46, 0.05);

    border-radius: 5px;
    margin-top: 37px;
  }
}

.PoursIndexView_addPourButton {
  width: 164px;
  position: fixed;
  bottom: 8px;
  right: 16px;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    display: flex;
    position: absolute;
    right: 0;
    top: 32px;
    z-index: 2;
  }
}

.PoursIndexView .SubNavbar_topBarChildren {
  display: flex;
  flex-wrap: nowrap;
}

.AddPour_icon {
  padding-right: 8px;
}

.PoursIndexView_pourList {
  @media only screen and (min-width: 768px) {
    margin-top: 12px;
  }
}

.PoursIndexView_pourListActive {
  margin-bottom: 40px;
}
