.FixedFooter_wrapper {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: @white;
  width: 100vw;
  border-radius: 10px 10px 0 0;
  box-shadow: -2px 3px 20px 0px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  color: @black;
  padding: 40px 16px 16px 16px;

  @media (min-width: 768px) {
    padding: 40px 30% 16px 30%;
  }
}
