.PourItem_wrapper {
  width: 100%;
  padding-left: 16px;
  padding-right: 4px;
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  box-sizing: border-box;
  margin-top: 8px;
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 768px), print {
    padding-top: 8px;
    flex-direction: column;
  }
}
.PourItem_orange {
  border-left: 4px solid @orange-base;
}

.PourItem_red {
  border-left: 4px solid @red;
}

.PourItem_item {
  display: flex;
  width: 100%;
  text-align: left;
  align-items: center;
  min-height: 64px;
}

.PourItem_itemNameSensorCount {
  display: flex;
  flex-direction: column;
}

.PourItem_name {
  font-size: @tx-size-medium;
  @media screen and (min-width: 768px), print {
    font-size: @tx-size-large;
  }
}

.PourItem_sensorCount {
  font-size: @tx-size-small;
  @media screen and (min-width: 768px), print {
    font-size: @tx-size-small;
  }
}

.PourItem_mobileArrowIcon {
  display: flex;
  margin-left: auto;
}

.PourItem_link {
  text-decoration: none;
  color: @black;
}

.PourItem_link:visited {
  color: @black;
}
