.PourForm_title {
  margin: 24px 0;
}

.PourForm_buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.PourForm_buttons > * {
  margin: 8px 0;

  @media only screen and (min-width: 768px) {
    margin: 0 24px;
  }
}

.PourForm_deleteSection {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: @tx-size-small;
}
