.ChartPlaceholder {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  border: 1px solid @grey-base;
  background-color: white;
  width: 100%;

  @media screen and (min-width: 768px), print {
    margin-top: 24px;
    margin-bottom: 32px;
    height: 460px;
    width: 1220px;
    border: none;
  }
}

.ChartPlaceholder_image {
  width: 100%;
  height: auto;
}

.ChartPlaceholder_text {
  display: flex;
  position: absolute;
  align-self: center;
  padding: 20px 52px 0 52px;
  font-size: @tx-size-base;
  color: @black-bold;

  @media screen and (min-width: 768px), print {
    position: absolute;
    top: calc(~"50% - 56px");
    left: calc(~"50% - 20%");
    font-size: @tx-size-large;
    color: @black-bold;
    padding: 0;
  }
}
