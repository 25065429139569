.FilterBarComponent_filterInput {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  box-sizing: inherit;
  text-indent: 0;
  height: 22px;
  width: 100%;

  // this is needed to fix alignment issues in ios and safari desktop
  @media only screen and (min-width: 768px) {
    line-height: @tx-size-small;
  }
}

.FilterBarComponent_filterInput::placeholder {
  font-size: @tx-size-small;
  line-height: 16px;
  letter-spacing: normal;
  color: @black-bold;
  padding-left: 4px;

  @media only screen and (min-width: 768px) {
    font-size: @tx-size-base;
    line-height: @tx-size-base;
  }
}

.FilterControl {
  width: 100%;
}

.FilterBarComponent_filterInput:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0);
}
