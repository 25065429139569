.DurationSelector_container {
  width: 100%;
  display: flex;
  align-items: center;
}

.DurationSelector_clock {
  width: 17.5px;
  height: 17.5px;
}
