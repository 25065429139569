.KpiCard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: @white;
  margin-bottom: 2%;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
}

.KpiCard_col_4 {
  display: flex;
  width: 32.3%;
}

.KpiCard_col_6 {
  display: flex;
  width: 49%;
}

.KpiCard_col_8 {
  display: flex;
  width: 65.6%;
}

.KpiCard_col_12 {
  display: flex;
  width: 100%;
}

.KpiCard_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 56px;
  border-bottom: solid 1px rgba(151, 151, 151, 0.2);
}

.KpiCard_title {
  display: flex;
  color: @black-bold;
  font-size: @tx-size-base;
}

.KpiCard_plot {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 24px;
  width: 100%;
}

.KpiCard_scrollablePlot {
  align-items: flex-start;
  overflow: scroll;
  max-height: 440px;
}

.KpiCard_tablePlot {
  padding-top: 0;
  padding-bottom: 8px;
}

.KpiCard_chart {
  display: flex;
}

.KpiCard_tooltip {
  display: flex;
}
