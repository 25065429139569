.TemperatureDifferential {
  width: 100%;
  box-sizing: border-box;
  margin-top: 108px;
  position: relative;
  z-index: 1;

  @media only print {
    margin-top: 8px;
  }
}

.TemperatureDifferential .SubNavbar_topBarChildren {
  display: flex;
  flex-wrap: nowrap;
}

.TemperatureDifferential_siteSelector {
  width: 100%;
}
