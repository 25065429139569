.PoliciesView_wrapper {
  overflow-x: visible;
  margin-top: 88px;
  padding-bottom: 180px;

  @media (min-width: 768px) {
    margin-top: 136px;
  }
}

.PoliciesView_sectionHeader {
  margin-top: 32px;
}

.PoliciesView_subsection {
  margin-top: 24px;
  font-weight: 800;
}

.PoliciesView_agreeText {
  margin-top: 0;
  margin-bottom: 24px;
}

.PoliciesView_agreementWrapper {
  display: flex;
}

.PoliciesView_agreementCheckbox {
  height: 16px;
  width: 36px;
  margin-top: 4px;
  margin-right: 4px;

  @media (min-width: 768px) {
    height: 16px;
  }
}
