.PresetDateRangePicker_panel {
  padding: 0 22px 11px
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer
}
.PresetDateRangePicker_button:active {
  outline: 0
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff
}
.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.SingleDatePickerInput__rtl {
  direction: rtl
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2
}
.SingleDatePickerInput__block {
  display: block
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.SingleDatePicker {
  position: relative;
  display: inline-block
}
.SingleDatePicker__block {
  display: block
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.SingleDatePicker_picker__rtl {
  direction: rtl
}
.SingleDatePicker_picker__directionLeft {
  left: 0
}
.SingleDatePicker_picker__directionRight {
  right: 0
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2
}
.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a
}
.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}
.CalendarDay:active {
  outline: 0
}
.CalendarDay__defaultCursor {
  cursor: default
}
.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848
}
.CalendarDay__outside:hover {
  border: 0
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848
}
.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__last_in_range,
.CalendarDay__last_in_range:hover {
  border-style: solid
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate
}
.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}
.CalendarMonthGrid__animating {
  z-index: 1
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}
.CalendarMonthGrid__vertical {
  margin: 0 auto
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden
}
.DayPickerNavigation {
  position: relative;
  z-index: 2
}
.DayPickerNavigation__horizontal {
  height: 0
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2
}
.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:active {
  background: 0 0
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848
}
.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left
}
.DayPicker__horizontal {
  background: #fff
}
.DayPicker__verticalScrollable {
  height: 100%
}
.DayPicker__hidden {
  visibility: hidden
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}
.DayPicker_portal__vertical {
  position: initial
}
.DayPicker_focusRegion {
  outline: 0
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_weekHeaders {
  position: relative
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left
}
.DayPicker_weekHeader__vertical {
  left: 50%
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}
.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height .2s ease-in-out;
  -moz-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out
}
.DayPicker_transitionContainer__vertical {
  width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle
}
.DateInput__small {
  width: 97px
}
.DateInput__block {
  width: 100%
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb
}
.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .2px;
  padding: 7px 7px 5px
}
.DateInput_input__regular {
  font-weight: auto
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2
}
.DateInput_fangShape {
  fill: #fff
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block
}
.DateRangePickerInput__disabled {
  background: #f2f2f2
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.DateRangePickerInput__rtl {
  direction: rtl
}
.DateRangePickerInput__block {
  display: block
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}
.DateRangePickerInput_clearDates__small {
  padding: 6px
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.DateRangePicker {
  position: relative;
  display: inline-block
}
.DateRangePicker__block {
  display: block
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.DateRangePicker_picker__rtl {
  direction: rtl
}
.DateRangePicker_picker__directionLeft {
  left: 0
}
.DateRangePicker_picker__directionRight {
  right: 0
}
.DateRangePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.CalendarDay__default,
.CalendarDay__default:hover {
  font-size: 16px;
  border: none;
  color: #000000;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 4px;
}
.CalendarDay__default:hover {
  background-color: #f4f4f4;
}
.CalendarDay__selected {
  background: #3291f6;
  color: #ffffff;
  border-radius: 50%;
  border: none;
}
.CalendarDay__selected:hover {
  background: #0278f6;
  border: none;
  color: #ffffff;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:hover {
  color: #d5d4d4;
  background-color: #ffffff;
}
.SingleDatePicker {
  width: 100%;
}
.SingleDatePicker_picker {
  transform: translate(25%, -75%);
}
.DateInput {
  width: 100%;
}
.SingleDatePickerInput__withBorder {
  display: none;
  position: absolute;
  top: -50px;
}
html {
  height: 100%;
  width: 100%;
}
body {
  background: #f8fafa;
  color: #30363e;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.6px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  height: 100%;
  width: 100%;
  margin: 0px;
  background-size: 100% 100%;
}
*.white {
  background: #026df5;
  color: #ffffff;
}
*.blue {
  background: #ffffff;
  color: #026df5;
}
h1 {
  font-size: 1.71em;
  line-height: 1.33;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
h2 {
  font-size: 1.42em;
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin-top: 24px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  h2 {
    margin-top: 32px;
  }
}
h3 {
  font-size: 1.28em;
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
a {
  color: #026df5;
}
a:hover a:focus {
  color: #469cf6;
}
a:visited {
  color: #014298;
}
a.white {
  color: #ffffff;
}
a.white:hover a.white:focus {
  color: #ffffff;
}
a.white:visited {
  color: #ffffff;
}
a.blue {
  color: #026df5;
}
a.blue:hover a.blue:focus {
  color: #469cf6;
}
a.blue:visited {
  color: #014298;
}
.metadata {
  font-size: 0.85em;
  line-height: 1.83;
}
input {
  height: 40px;
  border-radius: 2px;
  background-color: #fbfbfb;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #d5d4d4;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-indent: 10px;
}
input::-webkit-input-placeholder {
  opacity: 0.6;
  color: #4b4f5a;
}
input:-moz-placeholder {
  opacity: 0.6;
  color: #4b4f5a;
}
input::-moz-placeholder {
  opacity: 0.6;
  color: #4b4f5a;
}
input:-ms-input-placeholder {
  opacity: 0.6;
  color: #4b4f5a;
}
input:focus {
  background-color: #fbfbfb;
}
input.__invalid {
  height: 40px;
  border-radius: 2px;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #ff0000;
  background-color: #fcf5f5;
}
input.__invalid:focus {
  background-color: #fcf5f5;
}
.control-errors {
  margin-top: 0.2em;
  color: #ff0000;
}
/* These rules disable spinners on number fields and the annoying clear button on IE11 */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-ms-clear {
  display: none;
}
.Navbar {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 4;
  color: #fff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  pointer-events: none;
  transform: translateX(-50%);
}
@media only screen and (max-width: 768px) {
  .Navbar {
    left: 0;
    height: 100%;
    width: 100vw;
    transform: none;
  }
}
@media only print {
  .Navbar {
    display: none;
  }
}
.Navbar_top {
  display: none;
}
@media only screen and (max-width: 768px) {
  .Navbar_top {
    display: flex;
    flex: 0;
    align-items: center;
    pointer-events: all;
    flex: 1;
    padding: 0 2em 0 0;
    justify-content: space-between;
    height: 48px;
  }
}
.Navbar_left:before {
  opacity: 0.1;
  padding-left: 0.5em;
  color: #ffffff;
}
@media only screen and (max-width: 768px) {
  .Navbar_left:before {
    content: "";
    padding: 0;
  }
}
.Navbar_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  pointer-events: all;
}
@media only screen and (max-width: 768px) {
  .Navbar_left {
    display: flex;
    position: relative;
    z-index: 2;
    transform: translateX(15%);
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-height: 4em;
    width: 100%;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
    border-width: 0 0 1px 0;
    padding: 0;
  }
}
.Navbar_selector {
  display: none;
}
@media only screen and (max-width: 768px) {
  .Navbar_selector {
    display: flex;
    width: 100%;
  }
}
.Navbar_middle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  pointer-events: all;
  padding-left: 4em;
}
@media only screen and (max-width: 768px) {
  .Navbar_middle {
    position: relative;
    z-index: 1;
    flex: 1;
    transform: translateX(15%);
    padding-bottom: 2em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 0;
  }
}
.Navbar_right {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  pointer-events: all;
}
@media only screen and (max-width: 768px) {
  .Navbar_right {
    position: relative;
    transform: translateX(15%);
    max-height: 6em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 85%;
    padding: 0 1em;
    padding-left: 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
    border-width: 1px 0 0 0;
  }
  .Navbar_right > .NavItem {
    min-height: 100%;
  }
}
.Navbar_drawer {
  display: flex;
  max-width: 1220px;
  width: 100%;
  height: 80px;
  flex: 3 0 auto;
  justify-content: space-around;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .Navbar_drawer {
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition-duration: 0.75s;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-image: linear-gradient(111deg, #0f72e3 1%, #3435da 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 0.15) 99%, rgba(0, 0, 0, 0.15) 100%);
  }
}
.Navbar_toggle {
  display: none;
}
@media only screen and (max-width: 768px) {
  .Navbar_toggle {
    height: 100%;
    padding: 0 1em;
    display: flex;
    background-color: rgba(0, 0, 0, 0.13);
    align-items: center;
  }
}
.Navbar_brandIcon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8em;
  width: 116px;
  height: 26px;
}
@media only screen and (max-width: 768px) {
  .Navbar_brandIcon {
    width: 86px;
    height: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .Navbar_iconDt {
    display: none;
  }
}
.Navbar_menuLabel {
  font-size: 15.5px;
  padding-left: 0.2em;
}
.Navbar_menuIcon {
  height: 24px;
  width: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 768px) {
  .Navbar_clickableBg {
    pointer-events: all;
    cursor: pointer;
  }
}
@media only screen and (max-width: 768px) {
  .Navbar_closedDrawer {
    transform: translateX(-100%);
  }
}
@media only screen and (max-width: 768px) {
  .Navbar_openDrawer {
    transform: translateX(-15%);
  }
}
/*
 * This is because in the specific circunstance of the alert in mobile
 * being on top of the MENU label we need to reposition it only there.
 * This doesn't happen on any menu items elsewhere and therefore I added
 * this here to avoid adding an extra property to the BlinkingRedDot
 * component unnecessarily just for this single case. Please avoid
 * nesting rules if it's easier to just style them elsewhere.
 */
.Navbar_top .BlinkingRedDot_wrapper {
  position: relative;
  top: -20px;
  left: -4px;
}
@media only screen and (max-width: 768px) {
  .NavActionItem {
    padding-left: 23px;
  }
}
.NavDropdown {
  display: flex;
  height: 48px;
  align-items: center;
  cursor: pointer;
  padding: 0 1em;
}
@media only screen and (max-width: 768px) {
  .NavDropdown {
    padding: 0;
  }
}
.NavDropdown a {
  text-decoration: none;
}
.NavDropdown_options {
  overflow: hidden;
  display: none;
  transform-origin: top;
  transition: height 0.25s ease, opacity 0.25s ease;
  transform: translateY(-24px);
}
@media only screen and (max-width: 768px) {
  .NavDropdown_options {
    transform: none;
  }
}
.NavDropdown_options a {
  color: #ffffff;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .NavDropdown_item {
    height: 1em;
  }
}
@media only screen and (max-width: 768px) {
  .NavDropdown_mobileHidden {
    height: 0;
    opacity: 0;
  }
}
@media only screen and (max-width: 768px) {
  .NavDropdown_mobileVisible {
    opacity: 1;
  }
}
.NavDropdown_arrow {
  padding-left: 0.5em;
  height: 24px;
  width: 24px;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.2s ease;
}
@media only screen and (max-width: 768px) {
  .NavDropdown_arrow {
    display: block;
    top: 50%;
    padding: 0;
    right: 0;
    transform: translate(-36px);
  }
}
.NavDropdown_arrowUp {
  transform: translate(-36px) rotateX(180deg);
}
@media only screen and (max-width: 768px) {
  .NavDropdown_hr {
    display: none;
  }
}
.NavDropdown_item hr {
  border: 0;
  height: 1px;
  background: #d7e9fb;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .NavDropdown:hover .NavDropdown_options {
    top: 100%;
    left: 0;
    width: 240px;
    display: block;
    position: absolute;
    padding: 1em 1em 0.5em 1em;
    background-color: #026df5;
    border-radius: 2px;
    min-width: 100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }
}
.NavItem {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 16px;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .NavItem {
    height: auto;
  }
}
.NavItem input {
  /*
     * This is necessary to cleanup tacit.css
     */
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 768px) {
  .NavItem {
    width: 100%;
  }
}
.NavLink {
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  margin: 0 1em;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: transparent;
  color: #ffffff;
  text-decoration: none;
  height: 100%;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .NavLink {
    height: 48px;
    width: 100%;
    border-width: 0 0 0 9px;
    margin: 0;
    padding: 0 1em;
  }
}
@media only screen and (max-width: 768px) {
  .NavLink:hover {
    border-width: 0 0 0 9px;
  }
}
.NavLink:hover .NavLink:focus {
  color: #ffffff;
}
.NavLink:visited {
  color: #ffffff;
}
.NavLink > * {
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 100%;
}
.NavLink:hover {
  border-width: 0 0 2px 0;
  border-color: #ffffff;
  border-style: solid;
}
.NavLink_active {
  font-weight: normal;
  border-width: 0 0 2px 0;
  border-color: #d7e9fb;
  border-style: solid;
}
@media only screen and (max-width: 768px) {
  .NavLink_active {
    font-weight: bold;
    overflow: hidden;
    border-width: 0 0 0 9px;
  }
}
.NavLink_dropdownItem {
  border-width: 0 0 0 0;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .NavLink_dropdownItem {
    border-width: 0 0 0 0;
    padding-left: 24px;
  }
}
.NavLink_dropdownItem:hover {
  border-width: 0 0 0 0;
}
.NavLink_alert {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
@media (min-width: 768px) {
  .NavLink_alert {
    position: initial;
    height: auto;
    width: auto;
  }
}
.NavUser_initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  background-color: #10a265;
  border-radius: 50%;
  margin-right: 11px;
  font-size: 0.85em;
  letter-spacing: -0.1px;
}
.NavUser_userWrapper {
  display: flex;
  flex-direction: column;
}
.NavUser_fullName {
  line-height: 1.1;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  .NavUser_fullName {
    display: none;
  }
}
.NavUser_siteName {
  font-size: 0.85em;
  opacity: 0.4;
  line-height: 1.1;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  .NavUser_siteName {
    display: none;
  }
}
.InlineDropdown {
  min-width: 85%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .InlineDropdown {
    align-items: flex-start;
  }
}
.InlineDropdown .NavDropdown_options {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background-color: #014298;
}
@media only screen and (min-width: 768px) {
  .InlineDropdown .NavDropdown_options {
    display: none;
    transform: translateY(-16px);
    height: auto;
    margin: auto;
    min-width: auto;
    flex-direction: row;
  }
}
.InlineDropdown .NavDropdown_label {
  display: flex;
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .InlineDropdown .NavDropdown_label {
    display: auto;
    min-width: 100%;
    transform: translate(24px);
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: initial;
  }
}
.InlineDropdown .NavLink {
  margin: 0 1em 0 0;
}
@media only screen and (min-width: 768px) {
  .InlineDropdown .NavLink {
    border: none;
  }
}
.InlineDropdown .NavLink:hover {
  border: none;
}
.InlineDropdown .NavDropdown_item {
  padding-left: 0;
  height: 48px;
  transition: height 0.25s;
}
.InlineDropdown .NavDropdown_item > .NavItem {
  height: auto;
}
@media only screen and (max-width: 768px) {
  .InlineDropdown .NavDropdown_item > .NavItem {
    height: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .InlineDropdown .NavDropdown_item {
    display: flex;
    justify-content: left;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) {
  .InlineDropdown .NavDropdown_item:hover {
    border-bottom: solid 2px #d7e9fb;
    -webkit-text-stroke: 0.75px #ffffff;
  }
}
.UserDropdown_wrapper {
  padding-right: 0;
}
@media only screen and (max-width: 768px) {
  .UserDropdown_wrapper {
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
}
.UserDropdown_wrapper .NavDropdown_options {
  transform: none;
}
@media only screen and (max-width: 768px) {
  .UserDropdown_wrapper .NavDropdown_options {
    opacity: 1;
    display: flex;
    transform: translateY(20%);
    flex-direction: column;
    min-height: 100%;
  }
}
.UserDropdown_wrapper .NavDropdown_item:hover {
  -webkit-text-stroke: 0.75px #ffffff;
}
@media only screen and (max-width: 768px) {
  .UserDropdown_wrapper .NavDropdown_hr {
    display: none;
  }
}
.UserDropdown_wrapper .NavDropdown_item {
  padding: 0.4em;
}
@media only screen and (max-width: 768px) {
  .UserDropdown_wrapper .NavDropdown_item {
    padding: auto;
    padding-left: 0.4em;
    padding: 0;
    display: flex;
  }
}
.UserDropdown_wrapper .NavDropdown_item:last-child {
  transform: translateY(-10%);
}
.UserDropdown_wrapper .NavDropdown_label {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .UserDropdown_wrapper .NavDropdown_label {
    order: 3;
    min-height: 100%;
  }
}
.UserDropdown_divider {
  margin-top: 28px;
}
@media only screen and (max-width: 768px) {
  .UserDropdown_divider {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .UserDropdown_wrapper .NavDropdown_arrow {
    display: none;
  }
}
.DefaultLayout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f8fafa;
}
@media only screen and (max-width: 768px) {
  .DefaultLayout {
    overflow-x: hidden;
    padding-bottom: 16px;
  }
}
@media only print {
  .DefaultLayout {
    background-color: transparent;
  }
}
.DefaultLayout_background {
  position: absolute;
  top: 136px;
  width: 100vw;
  height: 116px;
  left: 0;
  background-image: url("img/white-background-light.svg");
  background-repeat: repeat-x, no-repeat;
  pointer-events: none;
}
@media only print {
  .DefaultLayout_background {
    display: none;
    background-image: none;
  }
}
.DefaultLayout_topBar {
  z-index: 3;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  box-shadow: -20px -19px 23px 0px rgba(0, 0, 0, 0.15);
  transition: box-shadow 250ms;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15)), linear-gradient(111deg, #0f72e3 1%, #3435da), linear-gradient(180deg, transparent 51%, rgba(0, 0, 0, 0.15) 99%, rgba(0, 0, 0, 0.15));
}
@media only screen and (max-width: 768px) {
  .DefaultLayout_topBar {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 48px;
  }
}
@media only print {
  .DefaultLayout_topBar {
    display: none;
  }
}
.DefaultLayout_blueBackground {
  background-image: linear-gradient(91deg, #0f72e3, #3435da);
}
.DefaultLayout_withNavbarShadow {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
}
.DefaultLayout_container {
  box-sizing: border-box;
  display: flex;
  align-self: center;
  max-width: 1220px;
  width: 100%;
  height: 100%;
  padding-bottom: 16px;
}
@media only screen and (max-width: 768px) {
  .DefaultLayout_container {
    display: flex;
    max-width: 100vw;
    padding: 0 16px 16px 16px;
    height: auto;
  }
}
@media print {
  .DefaultLayout_container {
    padding-bottom: 0;
  }
}
.DefaultLayout_content {
  width: 100%;
}
.SubNavbarLayout_background {
  position: fixed;
  top: 136px;
  width: 100vw;
  height: 116px;
  left: 0;
  background-image: url("img/white-background-light.svg");
  background-repeat: repeat-x, no-repeat;
  pointer-events: none;
}
@media screen and (max-width: 768px), print {
  .SubNavbarLayout_background {
    display: none;
  }
}
.PoliciesLayout_background {
  position: absolute;
  top: 80px;
  width: 100vw;
  height: 116px;
  left: 0;
  background-image: url("img/white-background-light.svg");
  background-repeat: repeat-x, no-repeat;
}
@media only screen and (max-width: 768px) {
  .PoliciesLayout_background {
    display: none;
  }
}
@media (min-width: 768px) {
  .BlueBackgroundLayout_background {
    position: absolute;
    width: 100vw;
    height: 428px;
    left: 0;
    background-image: linear-gradient(97deg, #0f72e3, #3435da);
    pointer-events: none;
  }
}
.NotificationPreferencesView_wrapper {
  overflow-x: visible;
  margin-top: 88px;
  /*
     * This is temporary.
     * It will only stay here until we style inputs properly.
     */
}
.NotificationPreferencesView_wrapper input {
  box-shadow: none;
  height: auto;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_wrapper {
    margin-top: 136px;
  }
}
.NotificationPreferencesView_title {
  margin: 0 0 16px;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_title {
    margin: 0 0 24px;
  }
}
.NotificationPreferencesView_content {
  padding-top: 24px;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_content {
    padding-top: 40px;
  }
}
.NotificationPreferencesView_tableWrapper {
  width: 100%;
  box-sizing: border-box;
}
.NotificationPreferencesView_tableContent {
  padding: 16px 0 0 0;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_tableContent {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .NotificationPreferencesView_separatorWrapper {
    padding: 0 24px;
  }
}
.NotificationPreferencesView_rowSeparator {
  height: 1px;
  width: 100%;
  background-color: #d5d4d4;
}
.NotificationPreferencesView_tableHeader {
  display: none;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_tableHeader {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 0 24px;
    font-weight: 800;
    font-size: 14px;
    border-bottom: solid 1px #eceded;
  }
}
.NotificationPreferencesView_descriptionHeader {
  flex: 14;
}
.NotificationPreferencesView_controlHeader {
  text-align: center;
  flex: 1;
}
.NotificationPreferencesView_tableFooter {
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_tableFooter {
    margin: 24px;
  }
}
.NotificationPreferencesView_tableFooter > * {
  margin: 0 8px;
}
.NotificationPreferencesView_topLink,
.NotificationPreferencesView_topLink:visited {
  font-size: 14px;
  font-weight: 800;
  text-decoration: none;
  color: #000000;
}
.NotificationPreferencesView_bodyRow {
  display: flex;
  padding: 0 16px;
  flex-direction: column;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_bodyRow {
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
  }
}
.NotificationPreferencesView_bodyRow:not(:nth-child(1)) {
  padding-top: 16px;
}
.NotificationPreferencesView_notificationDescription {
  flex: 7;
}
.NotificationPreferencesView_notificationDescriptionTitle {
  font-size: 14px;
  color: #30363e;
  font-weight: 800;
  line-height: 1.29;
  margin-bottom: 8px;
}
.NotificationPreferencesView_notificationDescriptionBody {
  color: #30363e;
  line-height: 1.83;
  font-size: 0.85em;
}
.NotificationPreferencesView_notificationControls {
  flex: 1;
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 800;
  font-size: 14px;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_notificationControls {
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
  }
}
.NotificationPreferencesView_notificationControl {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_notificationControl {
    width: 100%;
    margin: 0 auto;
  }
}
.NotificationPreferencesView_controlLabel {
  padding-left: 4px;
}
@media (min-width: 768px) {
  .NotificationPreferencesView_controlLabel {
    display: none;
  }
}
.InventoryIndexView_wrapper {
  overflow-x: visible;
  margin-top: 136px;
}
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .InventoryIndexView_wrapper {
    margin-top: 88px;
  }
}
.InventoryIndexView_siteName {
  margin-top: 40px;
}
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .InventoryIndexView_siteName {
    margin-top: 24px;
  }
}
.InventorySection_showMoreLink {
  cursor: pointer;
}
.InventoryIndexView_metadata {
  margin-bottom: 16px;
}
.InventoryIndexView_siteName {
  display: inline-block;
}
.InventoryIndexView_siteCountBadge {
  display: inline-block;
  padding: 8px;
  border-radius: 5px;
  background-color: rgba(242, 242, 242, 0.6);
  font-family: 0.85em;
  color: #30363e;
  margin: 0 8px 8px 8px;
}
.InventoryIndexView_hardwareSummary > * {
  margin-right: 48px;
}
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .InventoryIndexView_hardwareSummary > * {
    display: block;
    width: 100%;
    overflow: hidden;
  }
}
.InventoryIndexView_sensorLink {
  color: #026df5;
  text-decoration: underline;
}
.SensorsIndexView_loadMoreWrapper {
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;
}
.InventorySection_sectionWrapper {
  /* Track */
  /* Handle */
}
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .InventorySection_sectionWrapper {
    width: 100%;
    overflow-x: visible;
  }
}
.InventorySection_sectionWrapper ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 16px;
}
.InventorySection_sectionWrapper ::-webkit-scrollbar-track {
  padding: 4px;
  width: 90vw;
}
.InventorySection_sectionWrapper ::-webkit-scrollbar-thumb {
  border: solid transparent;
  border-left-width: 12px;
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.15);
}
.InventorySection_sectionHeader {
  display: flex;
  align-items: baseline;
}
.InventorySection_sectionTitle {
  display: inline-block;
  margin: 42px 0 24px 0;
}
.InventorySection_hardwareCards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1220px;
}
@media (min-device-width: 320px) and (max-device-width: 768px) {
  .InventorySection_hardwareCards {
    display: flex;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100vw;
    max-width: 100vw;
    margin-left: -16px;
    padding: 0 8px 0 0;
  }
  .InventorySection_hardwareCards:before {
    content: "";
    display: inline-block;
    min-height: 1px;
    min-width: 8px;
  }
  .InventorySection_hardwareCards:after {
    content: "";
    display: inline-block;
    min-height: 1px;
    min-width: 1px;
  }
}
.InventoryCountBadge_wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
  padding: 2px 6px;
  border-radius: 19.5px;
  background-color: rgba(34, 35, 46, 0.05);
}
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .InventoryCountBadge_wrapper {
    margin: 8px;
  }
}
.InventoryCountBadge_count {
  font-size: 14px;
}
.DevicesOfflineCount_wrapper {
  display: inline-flex;
  align-items: baseline;
  margin-left: 40px;
  font-weight: bold;
  color: #ff0000;
}
.InventorySection_criticalAlertIcon {
  transform: translateY(25%);
  margin-right: 4px;
}
.HardwareCard_cardWrapper {
  margin: 0 16px 8px 0;
  scroll-snap-align: center;
}
.HardwareCard_severeGlow {
  box-shadow: 0 2px 21px 0 rgba(255, 0, 0, 0.22);
}
.HardwareCard_bg {
  position: relative;
  width: 388px;
  height: 200px;
  padding: 24px 16px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-between;
}
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .HardwareCard_bg {
    width: 280px;
  }
}
.HardwareCard_hardwareName {
  margin: 0 0 8px 0;
  white-space: nowrap;
  max-width: 240px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .HardwareCard_hardwareName {
    width: 120px;
  }
}
.HardwareCard_hardwareId {
  margin-bottom: 12px;
}
.HardwareCard_moderateBar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 4px;
  height: 100%;
  float: left;
  background-color: #ff9700;
}
.HardwareCard_severeBar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 4px;
  height: 100%;
  float: left;
  background-color: #ff4242;
}
.HardwareCard_metadataColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.HardwareCard_titleRow {
  display: flex;
  align-content: center;
}
.HardwareCard_editButton {
  margin: 0 8px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.HardwareCard_editView {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 24px;
  box-sizing: border-box;
  z-index: 1;
}
.HardwareCard_editTitle {
  margin-bottom: 16px;
  font-size: 1.42em;
  color: #30363e;
  align-self: flex-start;
}
.HardwareCard_nameInput {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgba(74, 144, 226, 0.61), inset 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #82b6f3;
  background-color: #fbfbfb;
  padding: 12px;
  box-sizing: border-box;
  font-size: 16px;
}
.HardwareCard_cancelEditButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 48px;
  font-size: 14px;
  text-align: center;
  background-color: #30363e;
  color: #ffffff;
  border-radius: 2px;
  margin: 0 4px;
  cursor: pointer;
}
.HardwareCard_saveEditButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 48px;
  font-size: 14px;
  text-align: center;
  background-color: #10a265;
  color: #ffffff;
  border-radius: 2px;
  margin: 0 4px;
  cursor: pointer;
}
.HardwareCard_editControls {
  margin-top: 24px;
}
.BatteryMask_wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BatteryMask_batteryFilling {
  height: 28px;
}
.BatteryMask_grayFilling {
  background-color: #b5b5b5;
  height: 28px;
}
.BatteryMask_redBattery {
  background-color: #ff0000;
}
.BatteryMask_yellowBattery {
  background-color: #f5b915;
}
.BatteryMask_greenBattery {
  background-color: #10a265;
}
.BatteryMask_imageMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/img/battery-mask.png");
  background-size: cover;
}
.GenericHardwareCard_bg {
  width: 388px;
  height: 200px;
  padding: 24px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-between;
}
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .GenericHardwareCard_bg {
    width: 280px;
  }
}
.GenericHardwareCard_title {
  margin: 0 0 8px 0;
}
.Card_bg {
  display: inline-block;
  border-radius: 2px;
  border: solid 1px #eceded;
  background-color: #ffffff;
  padding: 0;
}
.InventoryAlerts_wrapper {
  margin-top: 32px;
}
.InventoryAlerts_alertLink {
  display: block;
  margin: 8px 0 0 0;
}
.InventoryAlerts_wrapper > * {
  margin-top: 20px;
}
.SensorRegistrationView_wrapper {
  overflow-x: visible;
  margin-top: 88px;
}
@media (min-width: 768px) {
  .SensorRegistrationView_wrapper {
    margin-top: 136px;
  }
}
.SensorRegistrationView_greeting {
  padding-top: 24px;
  margin-bottom: 8px;
  font-size: 1.28em;
}
@media (min-width: 768px) {
  .SensorRegistrationView_greeting {
    padding-top: 40px;
    font-size: 1.71em;
  }
}
.SensorRegistrationView_content {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
}
@media (min-width: 768px) {
  .SensorRegistrationView_content {
    flex-direction: row;
  }
}
.SensorRegistrationView_content > *:not(:last-child) {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .SensorRegistrationView_content > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0px;
  }
}
.SensorRegistrationView_informationCard {
  flex: 1 0;
}
.SensorRegistrationView_formWrapper {
  padding: 24px 16px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .SensorRegistrationView_formWrapper {
    padding: 80px 160px;
  }
}
.SensorRegistrationView_formHeader {
  width: 100%;
  margin: 0;
  margin-bottom: 8px;
  font-weight: 800;
}
.SensorRegistrationView_formSeparator {
  height: 1px;
  width: 100%;
  border-bottom: solid 1px #e9e9e9;
  margin-bottom: 24px;
}
.SensorRegistrationView_formCard {
  flex: 3;
}
.SensorRegistrationView_addPourRow {
  width: 100%;
  display: flex;
  align-items: center;
}
.SensorRegistrationView_addPourButton {
  background-color: #10a265;
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-left: 16px;
  margin-bottom: 4px;
}
/**
 * The structure is:
 * - LoginLayout -> Set the background
 *      - LoginView_container -> Flex container that will become a fix-sized white div in laptops.
 *          - LoginForm_form -> The only content of LoginView_container, also a flex container
 */
.LoginLayout {
  background-image: url("img/assets-bg-tile-dark.svg"), linear-gradient(to bottom right, #8458c7, #026df5, #1b85f6);
  background-repeat: repeat-x, no-repeat;
  background-position: top, top;
  background-color: black;
  height: 100vh;
  width: 100vw;
}
@media only screen and (orientation: landscape) {
  .LoginLayout {
    height: 100vw;
  }
}
.LoginView_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.LoginForm_form {
  margin-top: 3em;
  width: 80%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  color: #ffffff;
}
.LoginView_formItem {
  font-size: 14px;
  width: 100%;
  flex-basis: 5em;
}
.LoginView_formTitle {
  font-size: 14px;
  width: 100%;
  flex-basis: 5em;
  font-size: 1.28em;
}
@media only screen and (orientation: landscape) {
  .LoginView_formTitle {
    flex-basis: 2em;
  }
}
.LoginView_formLogo {
  height: 5em;
  background: url(img/logo-white.svg);
  background-repeat: no-repeat;
}
.LoginView_formLink {
  font-size: 14px;
  width: 100%;
  flex-basis: 5em;
  line-height: 30px;
  text-align: center;
}
.LoginView_formLink > a {
  color: #ffffff;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.LoginView_formLink > a:hover .LoginView_formLink > a:focus {
  color: #ffffff;
}
.LoginView_formLink > a:visited {
  color: #ffffff;
}
.LoginView_formInput {
  font-size: 14px;
  width: 100%;
}
.LoginView_formButton {
  font-size: 14px;
  width: 100%;
  flex-basis: 5em;
  display: flex;
  align-items: center;
}
.LoginView_formErrors {
  margin-top: 0.2em;
  background-color: #f9e6f0;
  color: #30363e;
  text-indent: 10px;
  border-left: 3px solid red;
}
@media only screen and (min-width: 768px) {
  .LoginLayout {
    background-image: url("img/assets-bg-tile-dark.svg"), url("img/node.svg"), url("img/hub.svg"), linear-gradient(to bottom right, #8458c7, #026df5, #1b85f6);
    background-repeat: repeat-x, no-repeat, no-repeat, no-repeat;
    background-position: top, -150px center, right 300px, top;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }
  .LoginView_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    width: 540px;
    height: 572px;
  }
  .LoginForm_form {
    width: 80%;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    color: #30363e;
    margin-left: 30px;
    margin-top: 70px;
  }
  .LoginView_formLogo {
    height: 5em;
    background: url(img/logo-default.svg);
    background-repeat: no-repeat;
  }
  .LoginView_formItem {
    font-size: 14px;
    width: 400px;
    flex-basis: 6em;
  }
  .LoginView_formTitle {
    display: flex;
    align-items: center;
    flex-basis: 5em;
    font-size: 1.42em;
  }
}
.RequestPasswordResetView {
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  min-height: 7em;
  border-radius: 0.5em 0.5em 0em 0em;
  color: #30363e;
  background-color: #ffffff;
  left: 0%;
  box-shadow: 0px -1px 1px #d5d4d4;
}
.RequestPasswordResetView_form {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1em;
}
@media only screen and (min-width: 768px) {
  .RequestPasswordResetView_form {
    width: 400px;
  }
}
.RequestPasswordResetView_formItem {
  font-size: 14px;
  width: 100%;
  flex-basis: 5em;
}
.RequestPasswordResetView_formTitle {
  font-size: 14px;
  width: 100%;
  flex-basis: 5em;
  font-size: 1.28em;
  padding-top: 1em;
}
@media only screen and (orientation: landscape) {
  .RequestPasswordResetView_formTitle {
    flex-basis: 2em;
  }
}
.RequestPasswordResetView_formInput {
  font-size: 14px;
  width: 100%;
}
.RequestPasswordResetView_formButton {
  font-size: 14px;
  width: 100%;
  flex-basis: 5em;
  display: flex;
  align-items: center;
}
.RequestPasswordResetView_transition-appear,
.RequestPasswordResetView_transition-enter {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: transform 250ms linear;
}
.RequestPasswordResetView_transition-appear-active,
.RequestPasswordResetView_transition-enter-active {
  -webkit-transform: none;
  transform: none;
  transition: transform 250ms linear;
}
.RequestPasswordResetView_transition-exit-active {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: transform 250ms linear;
}
.SensorsIndexView {
  display: flex;
  flex-direction: column;
  position: relative;
}
.SensorsIndexView_addSensorButton {
  width: 150px;
  position: fixed;
  bottom: 8px;
  right: 16px;
  z-index: 3;
}
@media only screen and (min-width: 768px) {
  .SensorsIndexView_addSensorButton {
    display: flex;
    width: 165px;
    position: absolute;
    right: 0;
    bottom: 16px;
    z-index: 2;
  }
}
.SensorsIndexView_sensorLists {
  display: flex;
  width: 100%;
  margin-top: 112px;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .SensorsIndexView_sensorLists {
    margin-top: 176px;
    height: 48px;
    justify-content: space-between;
  }
}
.SensorsIndexView_addSensorMobileWrapper {
  width: 150px;
  position: fixed;
  bottom: 8px;
  right: 16px;
}
.SensorsIndexView_addSensorDesktopWrapper {
  display: none;
}
.SensorsIndexView_plusIcon {
  padding-right: 8px;
}
.SensorsIndexView .SubNavbar_topBarChildren {
  display: flex;
  flex-wrap: nowrap;
}
.SensorsIndexView_siteSelector {
  display: none;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .SensorsIndexView_siteSelector {
    display: flex;
  }
}
@media only screen and (min-width: 768px) {
  .SensorsIndexView_noDurationSelector {
    display: flex;
  }
}
.SensorsIndexView_durationSelector {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 4px;
}
@media only screen and (min-width: 768px) {
  .SensorsIndexView_durationSelector {
    width: 236px;
  }
}
.SensorsIndexView_clock {
  width: 17.5px;
  height: 17.5px;
}
.SensorsIndexView_noActiveArchive {
  display: none;
}
.SensorsIndexView_rightSide {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media only screen and (min-width: 768px) {
  .SensorsIndexView_rightSide {
    width: 50%;
  }
}
.SensorsIndexView_filter {
  display: flex;
  width: 100%;
}
.TabbedView {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .TabbedView {
    height: 48px;
    justify-content: space-between;
  }
}
.TabbedView_controls {
  display: flex;
}
.TabbedView_controlWrapper:first-child {
  margin-right: 32px;
}
@media only screen and (min-width: 768px) {
  .TabbedView_controlWrapper:first-child {
    margin-right: 72px;
  }
}
.TabbedView_controlWrapper {
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  padding-bottom: 4px;
  cursor: pointer;
}
.TabbedView_control {
  text-decoration: none;
  color: #30363e;
  font-size: 1.28em;
  padding-right: 8px;
}
.TabbedView_controlWrapper:hover {
  border-bottom: 2px solid #d7e9fb;
}
.TabbedView_currentControl,
.TabbedView_currentControl:hover {
  border-bottom: 2px solid #0278f6;
}
.TabbedView_controlWrapper:hover .TabbedView_control {
  color: #026df5;
}
.TabbedView_currentControl:hover .TabbedView_control {
  color: #30363e;
}
.TabbedView_sensorCount {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85em;
  background-color: rgba(34, 35, 46, 0.05);
  border-radius: 2px;
  padding: 0 8px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .TabbedView_sensorCount {
    font-size: 16px;
    border-radius: 5px;
  }
}
.TabbedView_plusIcon {
  padding-right: 8px;
}
.TabbedView_noChildren {
  display: none;
}
.TabbedView_lists {
  display: flex;
  width: 100%;
}
.SensorCard {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border: 0;
}
@media only screen and (min-width: 768px) {
  .SensorCard {
    padding: 0;
  }
}
@media only screen and (min-width: 768px) {
  .SensorCard_topPanel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 32px;
    border-bottom: solid 1px #e1e1e1;
  }
}
@media only screen and (min-width: 768px) {
  .SensorCard_middlePanel {
    display: flex;
    justify-content: space-between;
    height: 160px;
    background-color: #fbfbfb;
  }
}
.SensorCard_bottomPanel {
  display: none;
}
@media only screen and (min-width: 768px) {
  .SensorCard_bottomPanel {
    display: flex;
    height: 32px;
    padding: 0 32px;
    background-color: #f6f6f6;
  }
}
.SensorCard_wrapper {
  position: relative;
  margin-top: 16px;
  border: solid 1px #e1e1e1;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  transition: box-shadow 250ms;
}
@media only screen and (min-width: 768px) {
  .SensorCard_wrapper {
    box-shadow: none;
  }
}
.SensorCard_wrapper:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.SensorCard_link {
  text-decoration: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.SensorCard_sensorInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.SensorCard_mobileArrowIcon {
  display: flex;
  height: 100%;
}
.SensorCard_sensorName {
  font-size: 1.28em;
  color: #30363e;
}
@media only screen and (min-width: 768px) {
  .SensorCard_sensorName {
    font-size: 1.42em;
  }
}
.SensorCard_offlineBar {
  border-left: solid 2px #ff4242;
}
.SensorCard_currentValueWrapper {
  display: flex;
  width: 70%;
  padding-left: 6px;
}
@media only screen and (min-width: 768px) {
  .SensorCard_currentValueWrapper {
    height: 160px;
    width: 25%;
    padding-left: 32px;
  }
}
.SensorCard_archived {
  z-index: -1;
}
.SensorCard_archivedContent {
  display: flex;
  height: 32px;
}
@media only screen and (min-width: 768px) {
  .SensorCard_archivedContent {
    height: 190px;
  }
}
.SensorCard_archivedContentColumn {
  display: flex;
  flex-direction: column;
}
.SensorCard_archivedContentBlueBox {
  width: 80px;
  height: 24px;
  background-color: #d7e9fb;
  margin-top: 4px;
}
@media only screen and (min-width: 768px) {
  .SensorCard_archivedContentBlueBox {
    margin-left: 16px;
    margin-left: 32px;
    margin-top: 32px;
    height: 32px;
  }
}
.SensorCard_archivedContentPurpleBox {
  margin-left: 16px;
  margin-top: 4px;
  width: 80px;
  height: 24px;
  background-color: #ebe5f5;
}
@media only screen and (min-width: 768px) {
  .SensorCard_archivedContentPurpleBox {
    height: 32px;
    margin-left: 32px;
    margin-top: 32px;
  }
}
.SensorCard_archivedContentGreyBox {
  margin-top: 16px;
  margin-left: 32px;
  width: 90px;
  height: 16px;
  background-color: #f2f2f2;
}
.SensorCard_archivedContentLargeGreyBox {
  margin-top: 4px;
  margin-left: 32px;
  width: 110px;
  height: 16px;
  background-color: #f2f2f2;
}
.SensorCard_archivedContentMuchBiggerGreyBox {
  margin-top: 4px;
  margin-left: 32px;
  width: 288px;
  height: 16px;
  background-color: #f2f2f2;
}
.SensorCard_archivedBottom {
  display: flex;
  height: 32px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.SensorCard_archivedWrapper {
  position: relative;
}
.SensorCard_archivedViewSensorData {
  position: absolute;
  left: calc(50% - 200px);
  top: calc(50% - 30px);
  z-index: 5;
  width: 400px;
  height: 48px;
  background-color: #c4ddfc;
  color: #30363e;
  border-style: none;
  font-size: 14px;
  text-decoration: none;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .SensorCard_graph {
    display: flex;
    width: 62%;
    padding: 8px 32px 8px 0;
  }
}
.Tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}
.CurrentValue {
  display: flex;
  flex-direction: column;
}
.CurrentValue_valueWrapper {
  display: flex;
  align-items: baseline;
}
.CurrentValue_value {
  font-size: 1.42em;
  font-weight: bold;
  padding-right: 4px;
}
@media only screen and (min-width: 768px) {
  .CurrentValue_value {
    font-size: 2.8em;
  }
}
.CurrentValue_unit {
  font-size: 0.85em;
}
@media only screen and (min-width: 768px) {
  .CurrentValue_unit {
    font-size: 1.28em;
    font-weight: bold;
  }
}
.CurrentValue_strength {
  color: #026df5;
}
.CurrentValue_temperature {
  color: #7850c1;
}
.CurrentValue_metricName {
  line-height: 0;
}
.CurrentValuePanel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media only screen and (min-width: 768px) {
  .CurrentValuePanel {
    align-items: center;
    padding-bottom: 14px;
  }
}
.EmptyView_wrapper {
  width: 100%;
  text-align: center;
  padding-bottom: 40px;
}
@media only screen and (min-width: 768px) {
  .EmptyView_wrapper {
    margin-top: 40px;
  }
}
.EmptyView_registrationSteps {
  display: flex;
  margin-top: 44px;
  flex-direction: column;
  padding: 0 8px;
}
@media only screen and (min-width: 768px) {
  .EmptyView_registrationSteps {
    flex-direction: row;
    padding: 0;
  }
}
.EmptyView_lateralRed {
  position: absolute;
  width: 4px;
  height: 95%;
  left: -24px;
  transform: translateY(5%);
  margin-right: 20px;
  background-color: #cc156f;
}
.EmptyView_redText {
  position: relative;
  display: flex;
  color: #cc156f;
  font-weight: bold;
}
.EmptyView_purpleBackground {
  background-color: #ebe5f5;
  font-weight: 800;
  border-radius: 2px;
  padding: 3px 6px;
}
.EmptyView_registrationSteps > *:not(:last-child) {
  margin-bottom: 24px;
}
@media only screen and (min-width: 768px) {
  .EmptyView_registrationSteps > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0px;
  }
}
.EmptyView_controls {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .SensorMetrics {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    pointer-events: none;
    z-index: 1;
  }
}
.SensorMetrics_metricWrapper {
  display: none;
}
@media only screen and (min-width: 768px) {
  .SensorMetrics_metricWrapper {
    display: flex;
    justify-content: space-between;
  }
}
.SensorMetrics_connectedNode {
  display: none;
}
@media only screen and (min-width: 768px) {
  .SensorMetrics_connectedNode {
    display: flex;
    align-items: center;
    font-size: 0.85em;
    color: #30363e;
    padding-top: 2px;
  }
}
@media only screen and (min-width: 768px) {
  .SensorMetrics_registeredAt {
    display: flex;
    align-items: center;
    font-size: 0.85em;
    color: #30363e;
    padding-top: 2px;
  }
}
@media only screen and (min-width: 768px) {
  .SensorMetrics_pouredAt {
    display: flex;
    align-items: center;
    font-size: 0.85em;
    color: #30363e;
    margin-right: 32px;
    padding-top: 2px;
  }
}
@media only screen and (min-width: 768px) {
  .SensorMetrics_actionLinkWrapper {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }
}
.SensorMetrics_link {
  text-decoration: underline;
  font-size: 14px;
  color: #026df5;
  pointer-events: all;
  position: relative;
}
.SensorMetrics_link:visited {
  color: #026df5;
}
.NoActiveSensors_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 8px;
}
@media only screen and (min-width: 768px) {
  .NoActiveSensors_wrapper {
    margin-top: 40px;
    width: 100%;
    padding: 24px;
  }
}
.NoActiveSensors_title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 8px;
}
@media only screen and (min-width: 768px) {
  .NoActiveSensors_title {
    font-size: 1.28em;
  }
}
@media only screen and (min-width: 768px) {
  .NoActiveSensors_text {
    padding-left: 32px;
  }
}
.NoActiveSensors_alert {
  padding-right: 8px;
}
.PoliciesView_wrapper {
  overflow-x: visible;
  margin-top: 88px;
  padding-bottom: 180px;
}
@media (min-width: 768px) {
  .PoliciesView_wrapper {
    margin-top: 136px;
  }
}
.PoliciesView_sectionHeader {
  margin-top: 32px;
}
.PoliciesView_subsection {
  margin-top: 24px;
  font-weight: 800;
}
.PoliciesView_agreeText {
  margin-top: 0;
  margin-bottom: 24px;
}
.PoliciesView_agreementWrapper {
  display: flex;
}
.PoliciesView_agreementCheckbox {
  height: 16px;
  width: 36px;
  margin-top: 4px;
  margin-right: 4px;
}
@media (min-width: 768px) {
  .PoliciesView_agreementCheckbox {
    height: 16px;
  }
}
.CalculateConcreteStrengthView_calculateFormTitle {
  font-size: 1.28em;
  font-weight: 800;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .CalculateConcreteStrengthView_calculateFormTitle {
    font-size: 1.42em;
    font-weight: 400;
  }
}
.CalculateConcreteStrengthView_sensorName {
  display: inline-block;
  margin-top: 8px;
}
.CalculateConcreteStrengthView_formWrapper {
  margin-top: 24px;
}
.CalculateConcreteStrengthView_controlButtons {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .CalculateConcreteStrengthView_controlButtons {
    margin-top: 32px;
    margin-bottom: 32px;
    flex-direction: row;
  }
}
.CalculateConcreteStrengthView_controlButtons > *:not(:last-child) {
  margin-top: 8px;
}
@media (min-width: 768px) {
  .CalculateConcreteStrengthView_controlButtons > *:not(:last-child) {
    margin-right: 16px;
    margin-top: 0;
  }
}
.TemporaryThresholds_wrapper {
  width: 100%;
}
.TemporaryThresholds_titleLine {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.TemporaryThresholds_titleBlock {
  margin-bottom: 16px;
}
.TemporaryThresholds_title {
  margin: 0;
}
.TemporaryThresholds_tableWrapper {
  width: 100%;
  border: solid 1px #e1e1e1;
}
.TemporaryThresholds_value {
  font-weight: 800;
}
.TemporaryThresholds_headerRow {
  padding: 16px 24px;
  color: #026df5;
  font-weight: 800;
  border-bottom: solid 1px #e1e1e1;
}
.TemporaryThresholds_rowSection {
  padding: 0 24px;
}
.TemporaryThresholds_rowWrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}
.TemporaryThresholds_footer {
  border-top: solid 1px #e1e1e1;
  padding: 16px;
  background-color: rgba(35, 38, 53, 0.05);
}
@media (min-width: 768px) {
  .TemporaryThresholds_footer {
    background-color: unset;
  }
}
.TemporaryThresholds_footerControls {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: center;
}
@media (min-width: 768px) {
  .TemporaryThresholds_footerControls {
    flex-direction: row;
  }
}
.TemporaryThresholds_footerRemoval {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .TemporaryThresholds_footerRemoval {
    margin-bottom: 0;
  }
}
.TemporaryThresholds_footerRemovalText {
  text-decoration: underline;
}
.TemporaryThresholds_separator {
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
}
.TemporaryThresholds_deleteIcon {
  cursor: pointer;
}
.ConfirmationScreen_confirmationWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ConfirmationScreen_confirmationMessage {
  width: 100%;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
}
.ConfirmationScreen_confirmationButtons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.ConfirmationScreen_confirmationButtons > * {
  margin: 8px;
}
.SensorDetail {
  width: 100%;
  box-sizing: border-box;
  margin-top: 108px;
  position: relative;
  z-index: 1;
}
@media print {
  .SensorDetail {
    padding: 0px;
    margin-top: 0px;
  }
}
.SensorDetail .SubNavbar_topBarChildren {
  display: flex;
  flex-wrap: nowrap;
}
.SensorDetail_siteSelector {
  width: 100%;
}
.SensorDetail_durationSelector {
  display: flex;
  align-items: center;
  width: 50%;
  padding-left: 4px;
}
@media screen and (min-width: 768px), print {
  .SensorDetail_durationSelector {
    width: 236px;
  }
}
.SensorDetail_clock {
  width: 17.5px;
  height: 17.5px;
}
@media screen and (min-width: 768px), print {
  .SensorDetail_clock {
    width: 20px;
    height: 20px;
  }
}
@media print {
  .SensorDetail_mixHeader {
    margin-top: 16px;
  }
}
.SensorPanel {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
@media screen and (min-width: 768px), print {
  .SensorPanel {
    margin-bottom: 32px;
  }
}
@media only print {
  .SensorPanel {
    margin-bottom: 0;
    margin-top: 16px;
  }
}
.SensorPanel_top {
  display: flex;
  background: white;
  border-radius: 2px;
  border: 1px;
  border-color: #d5d4d4;
  border-style: solid;
  flex-direction: column;
}
@media screen and (min-width: 768px), print {
  .SensorPanel_top {
    flex-direction: row;
    max-height: 452px;
  }
}
@media only print {
  .SensorPanel_top {
    max-height: 300px;
  }
}
.SensorPanel_streamSummary {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .SensorPanel_streamSummary {
    width: 234px;
    flex-direction: row;
  }
}
.SensorPanel_streams {
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .SensorPanel_streams {
    min-height: 388px;
  }
}
@media only print {
  .SensorPanel_streams {
    min-height: 0;
  }
}
.SensorPanel_controls {
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px), print {
  .SensorPanel_controls {
    flex-direction: row;
  }
}
@media only print {
  .SensorPanel_controls {
    display: none;
  }
}
.SensorPanel_downloadButtons {
  display: flex;
  width: 100%;
}
.SensorPanel_actionButton {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .SensorPanel_actionButton {
    width: 238px;
  }
}
.SensorPanel_actionButtonContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.StreamInfo {
  display: flex;
  flex-direction: column;
}
.StreamInfo_title {
  font-size: 1.42em;
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
}
.StreamInfo_line {
  border: 1px;
  border-color: #d5d4d4;
  border-bottom-style: solid;
  margin-left: 24px;
  margin-right: 24px;
}
@media screen and (min-width: 768px), print {
  .StreamInfo_line {
    margin-right: 0px;
  }
}
.StreamInfo_graph {
  display: flex;
  width: 100%;
}
.StreamsSummary {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .StreamsSummary {
    flex-direction: row;
  }
}
.StreamsSummary_measures {
  display: flex;
  flex-direction: row;
  border: 1px;
  border-color: #d5d4d4;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .StreamsSummary_measures {
    border-left-style: none;
    border-right-style: solid;
    width: 238px;
    flex-direction: column;
  }
}
.StreamsSummary_temperature {
  display: flex;
  font-size: 1.42em;
  flex: 1;
}
@media screen and (min-width: 768px), print {
  .StreamsSummary_temperature {
    height: 90px;
    flex: 0 auto;
    height: 150px;
  }
}
.StreamsSummary_strength {
  display: flex;
  flex: 1;
  font-size: 1.42em;
}
@media screen and (min-width: 768px), print {
  .StreamsSummary_strength {
    height: 90px;
    flex: 0 auto;
    height: 150px;
  }
}
.StreamsSummary_bottomContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.StreamsSummary_line {
  border: 1px;
  border-color: #d5d4d4;
  border-bottom-style: solid;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 8px;
}
@media screen and (min-width: 768px), print {
  .StreamsSummary_line {
    margin-right: 0px;
  }
}
.StreamsSummary_bottom {
  display: flex;
  flex-direction: row;
  border: 1px;
  border-color: #d5d4d4;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .StreamsSummary_bottom {
    justify-content: flex-start;
    display: flex;
    border-top-style: none;
    border-left: 4px solid #026df5;
    flex: 1;
    width: auto;
  }
}
.StreamsSummary_bottomContent {
  display: flex;
  flex: 1;
  justify-content: center;
}
.StreamsSummary_controls {
  display: flex;
  margin-top: auto;
  border-radius: 2px;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .StreamsSummary_controls {
    border: 1px;
    border-color: #d5d4d4;
    border-top-style: solid;
    margin-top: auto;
    height: 48px;
  }
}
@media print {
  .StreamsSummary_controls {
    display: none;
  }
}
.StreamsSummary_actionButtonContent {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #026df5;
  font-size: 0.85em;
}
@media screen and (min-width: 768px), print {
  .StreamsSummary_actionButtonContent {
    font-size: 14px;
  }
}
.StreamsSummary_reconfigureIcon {
  width: 28px;
  height: 24px;
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0V0z%22/%3E%3Cpath%20fill%3D%22%23026df5%22%20d%3D%22M19.43%2012.98c.04-.32.07-.64.07-.98%200-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06%200-.12.01-.17.03l-2.49%201c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46%202.18%2014.25%202%2014%202h-4c-.25%200-.46.18-.49.42l-.38%202.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17%200-.34.09-.43.25l-2%203.46c-.13.22-.07.49.12.64l2.11%201.65c-.04.32-.07.65-.07.98%200%20.33.03.66.07.98l-2.11%201.65c-.19.15-.24.42-.12.64l2%203.46c.09.16.26.25.44.25.06%200%20.12-.01.17-.03l2.49-1c.52.4%201.08.73%201.69.98l.38%202.65c.03.24.24.42.49.42h4c.25%200%20.46-.18.49-.42l.38-2.65c.61-.25%201.17-.59%201.69-.98l2.49%201c.06.02.12.03.18.03.17%200%20.34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73%200%20.21-.02.43-.05.73l-.14%201.13.89.7%201.08.84-.7%201.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16%201.13-.2%201.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21%201.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21%201.27.51%201.04.42.9-.68c.43-.32.84-.56%201.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19%201.35.16%201.13%201.06.43c.43.18.83.41%201.23.71l.91.7%201.06-.43%201.27-.51.7%201.21-1.07.85-.89.7.14%201.13zM12%208c-2.21%200-4%201.79-4%204s1.79%204%204%204%204-1.79%204-4-1.79-4-4-4zm0%206c-1.1%200-2-.9-2-2s.9-2%202-2%202%20.9%202%202-.9%202-2%202z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
}
.StreamsSummary_space {
  width: 4px;
}
@media screen and (min-width: 768px), print {
  .StreamsSummary_space {
    height: 20px;
  }
}
.TimeSeriesGraph {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .TimeSeriesGraph {
    padding-left: 20px;
    max-height: 340px;
  }
}
@media print {
  .TimeSeriesGraph {
    max-height: 250px;
  }
}
.TimeSeriesGraph_emptyDataWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
canvas {
  width: 100% !important;
  height: 100% !important;
}
.DownloadButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 2px;
  border: 1px;
  border-color: #d5d4d4;
  border-style: solid;
  background: #ffffff;
}
@media screen and (min-width: 768px), print {
  .DownloadButtons {
    margin-left: 4px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 48px;
  }
}
.DownloadButtons_button {
  padding: 4px;
  display: flex;
  width: 100%;
}
.DownloadButtons_buttonContent {
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 768px), print {
  .DownloadButtons_buttonContent {
    justify-content: center;
  }
}
.DownloadButtons_downloadIcon {
  width: 28px;
  height: 24px;
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0V0z%22/%3E%3Cpath%20d%3D%22M19.35%2010.04C18.67%206.59%2015.64%204%2012%204%209.11%204%206.6%205.64%205.35%208.04%202.34%208.36%200%2010.91%200%2014c0%203.31%202.69%206%206%206h13c2.76%200%205-2.24%205-5%200-2.64-2.05-4.78-4.65-4.96zM19%2018H6c-2.21%200-4-1.79-4-4%200-2.05%201.53-3.76%203.56-3.97l1.07-.11.5-.95C8.08%207.14%209.94%206%2012%206c2.62%200%204.88%201.86%205.39%204.43l.3%201.5%201.53.11c1.56.1%202.78%201.41%202.78%202.96%200%201.65-1.35%203-3%203zm-5.55-8h-2.9v3H8l4%204%204-4h-2.55z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
}
.DownloadButtons_chartIcon {
  width: 28px;
  height: 24px;
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0V0z%22/%3E%3Cpath%20d%3D%22M3.5%2018.49l6-6.01%204%204L22%206.92l-1.41-1.41-7.09%207.97-4-4L2%2016.99l1.5%201.5z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
}
.MeasureSummary {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px), print {
  .MeasureSummary {
    height: 150px;
  }
}
.MeasureSummary_temperature {
  color: #7850c1;
  border-top: 4px solid #7850c1;
}
@media screen and (min-width: 768px), print {
  .MeasureSummary_temperature {
    border-top: 0px;
    border-left: 4px solid #7850c1;
  }
}
.MeasureSummary_strength {
  color: #026df5;
  border-top: 4px solid #026df5;
  border-left: 0px;
}
@media screen and (min-width: 768px), print {
  .MeasureSummary_strength {
    border-top: 0px;
    border-left: 4px solid #026df5;
  }
}
.MeasureSummary_measure {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding-top: 8px;
}
.MeasureSummary_value {
  font-size: 2.8em;
}
.MeasureSummary_unit {
  padding-left: 4px;
  height: 0px;
  font-size: 16px;
  font-weight: bold;
}
.MeasureSummary_metric {
  font-size: 16px;
}
@media screen and (min-width: 768px), print {
  .MeasureSummary_metric {
    font-size: 0.85em;
  }
}
.MeasureSummary_minMax {
  font-size: 0.85em;
  color: #30363e;
}
.SensorDetailTop {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
}
@media screen and (min-width: 768px), print {
  .SensorDetailTop {
    flex-direction: row;
    margin-top: 168px;
    justify-content: space-between;
    max-height: 352px;
  }
}
@media print {
  .SensorDetailTop {
    margin-top: 0px;
  }
}
.SensorDetailTop_name {
  font-size: 24px;
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.SensorDetailTop_editButton {
  width: 84px;
  height: 32px;
  padding-left: 32px;
}
.SensorDetailTop_registeredBy {
  font-size: 0.85em;
}
@media screen and (min-width: 768px), print {
  .SensorDetailTop_registeredBy {
    font-size: 14px;
  }
}
.SensorDetailTop_archive {
  display: flex;
  justify-content: center;
  padding-top: 24px;
}
.SensorDetailArchive {
  display: flex;
  justify-content: center;
  padding-top: 24px;
}
.PrintHeader_printHeader {
  font-size: 0.85em;
  display: flex;
  width: 100%;
  margin-top: 4mm;
}
.PrintHeader_printHeaderFirstLine {
  display: flex;
}
.PrintHeader_printHeaderWrapper {
  display: flex;
  flex-direction: column;
}
.PrintHeader_printHeaderTitle {
  font-size: 1.42em;
}
.PrintHeader_printHeaderTitle {
  font-size: 1.42em;
}
.PrintHeader_printHeaderLogoWrapper {
  margin-left: auto;
}
.PrintHeader_printHeaderLogo {
  width: 110px;
}
.PrintHeader_printHeaderCreatedBy {
  margin-left: 50px;
}
.PrintHeader_siteName {
  margin-top: 16px;
  font-size: 0.85em;
}
.PrintHeader_printHeaderSecondLine {
  margin-top: 0px;
  display: flex;
}
.PrintHeader_sensorName {
  font-size: 1.42em;
}
.PrintHeader_printHeaderRegisteredBy {
  font-size: 0.85em;
  margin-left: auto;
  display: flex;
}
.PrintHeader_printHeaderRegisteredBy span {
  align-self: flex-end;
}
.PrintStrength_wrapper {
  border: solid 1px #d5d4d4;
  padding-top: 8px;
  padding-bottom: 8px;
}
.PrintStrength_table {
  width: 100%;
  text-align: left;
  border-spacing: unset;
  padding-left: 24px;
  padding-right: 24px;
}
.PrintStrength_header {
  margin-top: 16px;
}
.PrintStrength_rowBold {
  font-weight: bold;
}
.PrintStrength_oddRow {
  padding-left: 20px;
  background-color: rgba(216, 216, 216, 0.3);
  border-bottom: solid 1px #d5d4d4;
  -webkit-print-color-adjust: exact;
}
.PrintStrength_evenRow {
  padding-left: 20px;
  background-color: transparent;
  border-bottom: solid 1px #d5d4d4;
}
.SensorDetailAlerts_wrapper {
  margin-top: 20px;
}
.SensorDetailAlerts_wrapper > * {
  margin: 20px 0;
}
.SensorDetailAlerts_alertLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0 0 0;
}
.SensorDetailAlerts_offlineNode {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
}
.SensorDetailAlerts_offlineNodeText {
  font-weight: 800;
  flex: 4 1;
}
@media (min-width: 768px) {
  .SensorDetailAlerts_offlineNodeText {
    flex: none;
  }
}
.SensorDetailAlerts_sensorImage {
  transform: rotate(90deg);
}
.SensorDetailAlerts_nodeImage {
  height: 24px;
  width: 24px;
}
.SensorDetailAlerts_offlineImage {
  height: 24px;
  width: 24px;
}
.SensorDetailAlerts_offlineIcons {
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
  flex: 1 1;
}
@media (min-width: 768px) {
  .SensorDetailAlerts_offlineIcons {
    flex: none;
  }
}
.SensorDetailAlerts_buttonWrapper {
  margin-top: 16px;
  width: 100%;
}
@media (min-width: 768px) {
  .SensorDetailAlerts_buttonWrapper {
    width: auto;
    margin-top: 0;
    margin-left: 12px;
  }
}
.SensorDetailAlerts_previousSensorCache {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  flex-wrap: wrap;
}
.SensorDetailAlerts_previousSensorCache img {
  margin-right: 12px;
}
.SensorDetailAlerts_previousSensorCache a {
  font-weight: normal;
}
@media (min-width: 768px) {
  .SensorDetailAlerts_previousSensorCache > *:not(:last-child) {
    margin-right: 72px;
  }
}
.SensorDetailAlerts_previousSensorNodeInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;
}
@media (min-width: 768px) {
  .SensorDetailAlerts_previousSensorNodeInfo {
    width: auto;
    margin-top: 0;
    justify-content: flex-start;
  }
}
.SensorDetailAlerts_previousSensorInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}
@media (min-width: 768px) {
  .SensorDetailAlerts_previousSensorInfo {
    width: auto;
    margin-top: 0;
    justify-content: flex-start;
  }
}
.MaturitySummary_contentWrapper {
  padding: 16px;
  box-sizing: border-box;
}
@media screen and (min-width: 768px), print {
  .MaturitySummary_contentWrapper {
    padding: 24px;
  }
}
@media print {
  .MaturitySummary_contentWrapper {
    padding: 8px;
  }
}
.MaturitySummary_maturityRowItems {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px), print {
  .MaturitySummary_maturityRowItems {
    flex-direction: row;
  }
}
.MaturitySummary_helpText {
  margin: 0;
  margin-bottom: 24px;
}
@media screen and (min-width: 768px), print {
  .MaturitySummary_helpText {
    margin-bottom: 16px;
  }
}
@media print {
  .MaturitySummary_helpText {
    font-size: 0.85em;
  }
}
@media print {
  .MaturitySummary_header {
    margin-top: 16px;
  }
}
.MaturityItem_wrapper {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  height: 59px;
  background-color: #f6f6f6;
  border: solid 1px #d5d4d4;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 8px 16px;
}
@media screen and (min-width: 768px), print {
  .MaturityItem_wrapper {
    height: 108px;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}
@media print {
  .MaturityItem_wrapper {
    height: 80px;
    background-color: transparent;
  }
}
.MaturityItem_wrapper:not(:last-child) {
  margin-bottom: 8px;
}
@media screen and (min-width: 768px), print {
  .MaturityItem_wrapper:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .MaturityItem_name {
    font-size: 14px;
    margin-bottom: 8px;
  }
}
@media print {
  .MaturityItem_name {
    font-size: 0.85em;
    text-align: center;
    margin-bottom: 8px;
  }
}
.MaturityItem_value {
  font-weight: 800;
}
@media screen and (min-width: 768px) {
  .MaturityItem_value {
    font-weight: 400;
    font-size: 1.71em;
  }
}
@media print {
  .MaturityItem_value {
    font-weight: 400;
    font-size: 1.42em;
  }
}
.MaturityItem_green {
  background-color: #10a265;
  border: none;
  color: #ffffff;
}
@media print {
  .MaturityItem_green {
    border: solid 1px #d5d4d4;
    color: #30363e;
    background-color: transparent;
    height: 80px;
  }
}
.MixDetailChart_wrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  box-sizing: border-box;
}
@media screen and (min-width: 768px), print {
  .MixDetailChart_wrapper {
    flex-direction: row;
  }
}
@media print {
  .MixDetailChart_wrapper {
    padding: 8px;
  }
}
.MixDetailChart_item {
  width: 100%;
  text-align: left;
}
.MixDetailChart_item:not(:last-child) {
  margin-bottom: 8px;
}
@media screen and (min-width: 768px), print {
  .MixDetailChart_item:not(:last-child) {
    margin-bottom: auto;
  }
}
.MixDetailChart_itemName {
  font-weight: bold;
}
.HardwareInformationChart_wrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  box-sizing: border-box;
}
@media screen and (min-width: 768px), print {
  .HardwareInformationChart_wrapper {
    flex-direction: row;
  }
}
.HardwareInformationChart_item {
  white-space: pre-wrap;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}
.HardwareInformationChart_itemText {
  padding-left: 16px;
}
.HardwareInformationChart_item:not(:last-child) {
  margin-bottom: 24px;
}
@media screen and (min-width: 768px), print {
  .HardwareInformationChart_item:not(:last-child) {
    margin-bottom: auto;
  }
}
.HardwareInformationChart_itemName {
  display: block;
  box-sizing: border-box;
  font-weight: bold;
}
@media screen and (min-width: 768px), print {
  .HardwareInformationChart_itemName {
    display: inline-block;
  }
}
.HardwareInformationChart_itemNameSensor {
  display: block;
  white-space: pre-wrap;
  box-sizing: border-box;
  font-weight: bold;
  padding-left: auto;
}
@media screen and (min-width: 768px), print {
  .HardwareInformationChart_itemNameSensor {
    display: inline-block;
    padding-left: 0;
  }
}
.ProgressBar_total {
  position: relative;
  margin: 2px;
  height: 16px;
  width: 97%;
}
.ProgressBar_empty {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: #f2f2f2;
  height: 16px;
}
.ProgressBar_progressOverflow {
  overflow: hidden;
  border-radius: 8px;
  background-color: #d5d4d4;
}
.ProgressBar_progress {
  border-radius: 8px;
  height: 16px;
  width: 0%;
}
.ProgressBar_percentage {
  position: absolute;
  transform: translate(15%, -50%);
  right: 10px;
  top: 50%;
  display: inline-block;
  font-size: 10px;
  color: #000000;
}
.ProgressBar_blue {
  background-color: #026df5;
}
.ProgressBar_green {
  background-color: #10a265;
}
.total {
  margin: 25px 5px;
  width: 97%;
}
.BoundariesBar_progressOverflow {
  position: relative;
  bottom: 7px;
  height: 16px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #d5d4d4;
}
.BoundariesBar_boundaryBarLeft,
.BoundariesBar_boundaryBarMiddle,
.BoundariesBar_boundaryBarRight {
  display: inline-block;
  height: 16px;
}
.BoundariesBar_boundaryBarLeft {
  width: 70%;
}
.BoundariesBar_boundaryBarMiddle {
  width: 15%;
}
.BoundariesBar_boundaryBarRight {
  width: 15%;
}
.BoundariesBar_tooltipWrapper {
  position: relative;
  z-index: 2;
  display: inline-block;
  transform: translateX(-50%);
}
.BoundariesBar_tooltip {
  position: relative;
  background-color: #7850c1;
  color: #ffffff;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 8px;
}
.BoundariesBar_tooltipTriangle {
  position: relative;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #7850c1;
  left: 50%;
  transform: translateX(-50%);
}
.BoundariesBar_left {
  left: 35%;
}
.BoundariesBar_middle {
  left: 77.5%;
}
.BoundariesBar_right {
  left: 92.5%;
}
.BoundariesBar_red {
  background-color: #ff0000;
}
.BoundariesBar_yellow {
  background-color: #f5b915;
}
.BoundariesBar_green {
  background-color: #10a265;
}
.ThresholdsTable_tableWrapper {
  width: 100%;
}
.ThresholdsTable_tableWrapper > *:first-child {
  border-top: none;
}
.ThresholdsSection_header {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .ThresholdsSection_header {
    flex-direction: row;
    align-items: center;
  }
}
.ThresholdsSection_title {
  display: flex;
  align-items: baseline;
}
.ThresholdsSection_titleText {
  margin: 0;
}
.ThresholdsSubscribers_subscriptionsParagraph {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.ThresholdSubscribers_otherText {
  text-decoration: underline;
}
.ThresholdSubscribers_manageLink {
  display: block;
  font-size: 0.85em;
}
@media (min-width: 768px) {
  .ThresholdSubscribers_manageLink {
    display: inline;
    font-size: 14px;
  }
}
.ThresholdsSubscribers_notificationsIcon {
  display: none;
  margin-right: 8px;
}
@media (min-width: 768px) {
  .ThresholdsSubscribers_notificationsIcon {
    display: block;
  }
}
.ThresholdEmptyAlert_wrapper {
  box-sizing: border-box;
  padding: 24px;
  display: flex;
}
.ThresholdEmptyAlert_icon {
  display: none;
  min-height: 24px;
  min-width: 24px;
}
@media only screen and (min-width: 768px) {
  .ThresholdEmptyAlert_icon {
    display: block;
  }
}
.ThresholdEmptyAlert_message {
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .ThresholdEmptyAlert_message {
    padding-left: 8px;
  }
}
.ThresholdEmptyAlert_title {
  line-height: 1.2;
  font-size: 1.42em;
  font-weight: 800;
}
.ThresholdEmptyAlert_body {
  color: #30363e;
  line-height: 1.57;
  margin: 0;
  margin-top: 8px;
}
.ThresholdsInlineForm_explanation {
  color: #026df5;
  margin: 0;
  margin-bottom: 8px;
  width: 100%;
}
.ThresholdsInlineForm_inputRow {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .ThresholdsInlineForm_inputRow {
    flex-direction: row;
  }
}
.ThresholdsInlineForm_inputRow > *:not(:last-child) {
  margin-right: 16px;
  margin-bottom: 0px;
}
@media only screen and (min-width: 768px) {
  .ThresholdsInlineForm_inputRow > *:not(:last-child) {
    margin-bottom: inherit;
  }
}
/*
 * This specificity is necessary because it's the only way to beat the specific class
 * TODO we should refactor stylings for input so that the parents stablish the ideal margin for them
 */
.ThresholdsInlineForm_inputRow > *,
.ThresholdsInlineForm_inputRow > .Input_inputWrapper {
  margin-bottom: 0px;
}
.ThresholdsInlineForm_buttonsRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.ThresholdsInlineForm_buttonsRow > *:not(:last-child) {
  margin-right: 8px;
}
@media only screen and (min-width: 768px) {
  .ThresholdsInlineForm_buttonsRow > *:not(:last-child) {
    margin-right: 16px;
  }
}
.ThresholdsSubscribers_subscriptionsParagraph {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.ThresholdSubscribers_otherText {
  text-decoration: underline;
}
.ThresholdSubscribers_manageLink {
  display: block;
  font-size: 0.85em;
}
@media (min-width: 768px) {
  .ThresholdSubscribers_manageLink {
    display: inline;
    font-size: 14px;
  }
}
.ThresholdsSubscribers_notificationsIcon {
  display: none;
  margin-right: 8px;
}
@media (min-width: 768px) {
  .ThresholdsSubscribers_notificationsIcon {
    display: block;
  }
}
.TemperatureRowGroup_transition-enter {
  height: 0px;
  opacity: 0.01;
}
.TemperatureRowGroup_transition-enter.ThresholdsRowGroup_transition-enter-active {
  max-height: 100px;
  opacity: 1;
  transition: height 150ms ease-in;
  transition: opacity 150ms ease-in;
}
.TemperatureRowGroup_transition-exit {
  max-height: 100px;
  opacity: 1;
}
.TemperatureRowGroup_transition-exit.TemperatureRowGroup_transition-exit-active {
  height: 0px;
  opacity: 0.01;
  transition: height 150ms ease-in;
  transition: opacity 150ms ease-in;
}
.StrengthAbsoluteScale_wrapper {
  height: 100%;
}
.StrengthRowGroup_content {
  position: relative;
}
.StrengthRowGroup_thresholdColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.StrengthRowGroup_scaleColumn {
  height: 100%;
  position: absolute;
  top: 0;
  width: 4px;
  box-sizing: border-box;
  left: 26px;
  padding: 36px 0;
}
.StrengthRowGroup_wrapper .RowSeparator_wrapper {
  padding: 0 24px 0 48px;
}
.StrengthAbsoluteScale_wrapper {
  position: relative;
  background-color: #d5d4d4;
  transition: height 0.25ms ease-out;
}
.StrengthAbsoluteScale_progress {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0 0 5px 5px;
  width: 100%;
  background-color: #026df5;
}
.TemperatureRow_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  height: 96px;
}
@media only screen and (min-width: 768px) {
  .TemperatureRow_wrapper {
    flex-direction: row;
    padding: 0 24px;
    height: auto;
    height: 64px;
  }
}
.TemperatureRow_contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
}
@media only screen and (min-width: 768px) {
  .TemperatureRow_contentWrapper {
    flex-direction: row;
    align-items: center;
    padding: 16px 0;
  }
}
.TemperatureRow_barColumn {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .TemperatureRow_barColumn {
    flex: 0 0 200px;
    padding: 0;
    width: 132px;
  }
}
.TemperatureRow_descriptionColumn {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 14px;
}
@media only screen and (min-width: 768px) {
  .TemperatureRow_descriptionColumn {
    margin-top: 0;
    padding-left: 32px;
  }
}
.TemperatureRow_leftMetadata {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 9;
  width: 100%;
  padding: 0 16px;
  margin-top: 8px;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .TemperatureRow_leftMetadata {
    padding: 0;
    margin-top: 0;
  }
}
.TemperatureRow_deleteColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(30%, -50%);
}
@media only screen and (min-width: 768px) {
  .TemperatureRow_deleteColumn {
    position: initial;
    transform: none;
  }
}
.StrengthRow_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .StrengthRow_wrapper {
    padding-right: 24px;
  }
}
.StrengthRow_statusIconColumn {
  padding: 0 8px 0 16px;
}
.StrengthRow_textColumn {
  display: flex;
  flex-direction: column;
  flex: 4;
  align-items: left;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .StrengthRow_textColumn {
    flex-direction: row;
  }
}
.StrengthRow_titleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .StrengthRow_titleWrapper {
    width: 20%;
  }
}
.StrengthRow_title {
  font-weight: 800;
  display: flex;
  align-items: center;
}
.StrengthRow_silenceIcon {
  padding: 0 0 0 16px;
}
@media only screen and (min-width: 768px) {
  .StrengthRow_description {
    flex: 1 0 auto;
  }
}
.StrengthRow_predictionIcon {
  display: flex;
  padding-right: 20px;
}
@media only screen and (min-width: 768px) {
  .StrengthRow_predictionIcon {
    padding-right: 4px;
  }
}
.StrengthRow_deleteColumn {
  display: flex;
  flex: 1;
  width: 32px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  transform: translate(30%);
}
@media only screen and (min-width: 768px) {
  .StrengthRow_deleteColumn {
    position: static;
    transform: none;
    display: flex;
    justify-content: flex-end;
  }
}
.StrengthRow_emptyCircle {
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%3Ctitle%3Eicon--empty-milestone%3C/title%3E%3Cg%20id%3D%22Sensor-details%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22Platform1.1/Sensor-details--predictions-touched%22%20transform%3D%22translate%28-134.000000,%20-937.000000%29%22%3E%3Cg%20id%3D%22Group-12%22%20transform%3D%22translate%28109.500000,%20756.500000%29%22%3E%3Cg%20id%3D%22icon--empty-milestone%22%20transform%3D%22translate%2824.500000,%20180.500000%29%22%3E%3Cg%20id%3D%22Group-13%22%3E%3Cg%20id%3D%22Icons/Glyph/success-Copy-2%22%3E%3Cpolygon%20id%3D%22Path%22%20points%3D%220%200%2024%200%2024%2024%200%2024%22%3E%3C/polygon%3E%3Cpath%20d%3D%22M12,2%20C6.48,2%202,6.48%202,12%20C2,17.52%206.48,22%2012,22%20C17.52,22%2022,17.52%2022,12%20C22,6.48%2017.52,2%2012,2%20Z%20M12,20%20C7.59,20%204,16.41%204,12%20C4,7.59%207.59,4%2012,4%20C16.41,4%2020,7.59%2020,12%20C20,16.41%2016.41,20%2012,20%20Z%22%20id%3D%22symbol%22%20fill%3D%22%23D5D4D4%22%20fill-rule%3D%22nonzero%22%3E%3C/path%3E%3Cpath%20d%3D%22M12,20%20C7.59,20%204,16.41%204,12%20C4,7.59%207.59,4%2012,4%20C16.41,4%2020,7.59%2020,12%20C20,16.41%2016.41,20%2012,20%20Z%22%20id%3D%22Path-Copy%22%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22nonzero%22%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
  background-repeat: no-repeat;
}
.StrengthRow_checkCircle {
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%3Cdesc%3ECreated%20with%20Sketch%20Beta.%3C/desc%3E%3Cg%20id%3D%22Sensor-details%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22Platform1.1/Sensor-details--predictions-touched%22%20transform%3D%22translate%28-578.000000,%20-2139.000000%29%22%3E%3Cg%20id%3D%22icon-completed%22%20transform%3D%22translate%28578.000000,%202139.000000%29%22%3E%3Cg%3E%3Ccircle%20id%3D%22Oval%22%20fill%3D%22%23FFFFFF%22%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%228%22%3E%3C/circle%3E%3Cpolygon%20id%3D%22Path%22%20points%3D%220%200%2024%200%2024%2024%200%2024%22%3E%3C/polygon%3E%20%3Cpath%20d%3D%22M12,2%20C6.48,2%202,6.48%202,12%20C2,17.52%206.48,22%2012,22%20C17.52,22%2022,17.52%2022,12%20C22,6.48%2017.52,2%2012,2%20Z%20M12,20%20C7.59,20%204,16.41%204,12%20C4,7.59%207.59,4%2012,4%20C16.41,4%2020,7.59%2020,12%20C20,16.41%2016.41,20%2012,20%20Z%20M16.59,7.58%20L10,14.17%20L7.41,11.59%20L6,13%20L10,17%20L18,9%20L16.59,7.58%20Z%22%20id%3D%22symbol%22%20fill%3D%22%23026DF5%22%20fill-rule%3D%22nonzero%22%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
  background-repeat: no-repeat;
}
.HeaderRow_wrapper {
  box-sizing: border-box;
  padding: 24px;
  height: 70px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-weight: 800;
  border-top: solid 1px #eceded;
  border-bottom: solid 1px #eceded;
}
.HeaderRow_temperatureHeader {
  color: #7850c1;
}
.HeaderRow_strengthHeader {
  color: #026df5;
}
.FooterRow_wrapper {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 16px 24px;
  background-color: #f2f2f2;
}
.RowSeparator_wrapper {
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  padding: 0 24px;
  background-clip: content-box;
  opacity: 0.2;
  background-color: #000000;
}
.ConfirmationOverlay_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (min-width: 768px) {
  .ConfirmationOverlay_wrapper {
    flex-direction: row;
    justify-content: flex-end;
  }
}
.ConfirmationOverlay_confirmationText {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ConfirmationOverlay_confirmationText {
    margin-right: 44px;
  }
}
.ConfirmationOverlay_confirmationButtons {
  display: flex;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ConfirmationOverlay_confirmationButtons {
    width: auto;
  }
}
.ConfirmationOverlay_confirmationButtons > *:not(:last-child) {
  margin-right: 8px;
}
.DeleteBubble_wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
@media only screen and (min-width: 768px) {
  .DeleteBubble_wrapper {
    border-radius: 0;
    box-shadow: none;
    background-color: none;
    position: initial;
    left: auto;
  }
}
.AnimatedRow_wrapper {
  position: relative;
}
.AnimatedRow_willEnter {
  will-change: height, opacity;
}
.AnimatedRow_overlay {
  background-color: #026df5;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.TemperatureDifferential {
  width: 100%;
  box-sizing: border-box;
  margin-top: 108px;
  position: relative;
  z-index: 1;
}
@media only print {
  .TemperatureDifferential {
    margin-top: 8px;
  }
}
.TemperatureDifferential .SubNavbar_topBarChildren {
  display: flex;
  flex-wrap: nowrap;
}
.TemperatureDifferential_siteSelector {
  width: 100%;
}
.Panel {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-top: 16px;
}
@media screen and (min-width: 768px), print {
  .Panel {
    margin-bottom: 24px;
    margin-top: 24px;
  }
}
.Panel_top {
  display: flex;
  background: white;
  border-radius: 2px;
  border: 1px;
  border-color: #d5d4d4;
  border-style: solid;
  flex-direction: column;
  margin-bottom: 16px;
}
@media screen and (min-width: 768px), print {
  .Panel_top {
    flex-direction: row;
    min-height: 388px;
    margin-bottom: 9px;
  }
}
.Panel_summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 18px;
  box-sizing: border-box;
}
@media screen and (min-width: 768px), print {
  .Panel_summary {
    width: 238px;
    flex-direction: row;
    padding-top: 24px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
    border-right: 2px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
}
.Panel_topBorder {
  display: flex;
}
@media screen and (min-width: 768px), print {
  .Panel_topBorder {
    display: none;
  }
}
.Panel_topBorderBlue {
  display: flex;
  height: 2px;
  background-color: #026df5;
  flex-grow: 1;
}
.Panel_topBorderPurple {
  display: flex;
  height: 2px;
  background-color: #7850c1;
  flex-grow: 2;
}
.Panel_charts {
  box-sizing: border-box;
}
@media screen and (min-width: 768px), print {
  .Panel_charts {
    width: 982px;
    padding-top: 24px;
    padding-bottom: 12px;
  }
}
.Panel_controls {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
@media screen and (min-width: 768px), print {
  .Panel_controls {
    flex-direction: row;
    margin-top: 0;
  }
}
@media only print {
  .Panel_controls {
    display: none;
  }
}
.Panel_downloadControls {
  display: flex;
  width: 100%;
}
.Panel_actionButton {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px), print {
  .Panel_actionButton {
    margin-bottom: 0;
    width: 238px;
  }
}
.Summary {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .Summary {
    flex-direction: column;
    border-bottom: none;
  }
}
.Summary_maxDiff,
.Summary_maxTemp,
.Summary_minTemp {
  display: flex;
}
.Summary_maxDiff {
  display: flex;
  color: #026df5;
  flex-grow: 1;
}
@media screen and (min-width: 768px), print {
  .Summary_maxDiff {
    height: 130px;
    border-top: 0px;
    border-left: 2px solid #026df5;
    margin-bottom: 24px;
    flex-grow: 0;
  }
}
@media only print {
  .Summary_maxDiff {
    border-left: none;
  }
}
.Summary_temps {
  display: flex;
  flex-grow: 2;
  justify-content: space-around;
}
@media screen and (min-width: 768px), print {
  .Summary_temps {
    border-top: 0px;
    flex-direction: column;
    height: 206px;
    flex-grow: 0;
  }
}
.Summary_maxTemp {
  color: #7850c1;
}
@media screen and (min-width: 768px), print {
  .Summary_maxTemp {
    height: 103px;
    border-left: 2px solid #7850c1;
  }
}
@media only print {
  .Summary_maxTemp {
    border-left: none;
  }
}
.Summary_minTemp {
  display: flex;
  color: #7850c1;
}
@media screen and (min-width: 768px), print {
  .Summary_minTemp {
    height: 103px;
    border-left: 2px solid #7850c1;
  }
}
@media only print {
  .Summary_minTemp {
    border-left: none;
  }
}
.Charts {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .Charts {
    max-height: 448px;
  }
}
.Charts_chartDifferential {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .Charts_chartDifferential {
    height: 130px;
  }
}
.Charts_chartTemperatures {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .Charts_chartTemperatures {
    height: 232px;
  }
}
.Charts_chartLabels {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  padding-left: 16px;
  padding-top: 6px;
  padding-bottom: 15px;
}
@media screen and (min-width: 768px), print {
  .Charts_chartLabels {
    flex-direction: row;
    padding-right: 6px;
    padding-left: 52px;
    padding-top: 22px;
    padding-bottom: 0px;
  }
}
.Charts_circleYellow {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  height: 10px;
  width: 10px;
  background-color: #f5b915;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
@media screen and (min-width: 768px), print {
  .Charts_circleYellow {
    margin-left: 20px;
  }
}
.Charts_circleBegonia {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  height: 10px;
  width: 10px;
  background-color: #cc156f;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
@media screen and (min-width: 768px), print {
  .Charts_circleBegonia {
    margin-left: 20px;
  }
}
.Charts_circleBlue {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  height: 10px;
  width: 10px;
  background-color: #026df5;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
@media screen and (min-width: 768px), print {
  .Charts_circleBlue {
    margin-left: 8px;
  }
}
#print-canvas {
  display: none;
}
.Measure {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.Measure_date {
  font-size: 9px;
}
@media screen and (min-width: 768px), print {
  .Measure_date {
    font-size: 14px;
  }
}
.Measure_label {
  font-size: 0.85em;
}
@media screen and (min-width: 768px), print {
  .Measure_label {
    font-size: 14px;
  }
}
.Measure_measure {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  line-height: normal;
}
@media screen and (min-width: 768px), print {
  .Measure_measure {
    height: 50px;
    line-height: 1.57;
  }
}
.Measure_value {
  font-size: 2.8em;
}
@media screen and (min-width: 768px) {
  .Measure_value {
    font-size: 56px;
  }
}
@media only print {
  .Measure_value {
    font-size: 2.8em;
  }
}
.Measure_unit {
  font-size: 9px;
  margin-top: 1.8em;
}
@media screen and (min-width: 768px), print {
  .Measure_unit {
    align-self: auto;
    padding-left: 4px;
    height: 0px;
    font-size: 1.28em;
    font-weight: bold;
    margin-top: 0;
  }
}
@media only print {
  .Measure_unit {
    font-size: 0.85em;
  }
}
.TimeSeries {
  display: flex;
  width: 100%;
}
.TimeSeries_emptyDataWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
canvas {
  width: 100% !important;
  height: 100% !important;
}
.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: white;
  color: black;
  padding: 6px;
  border-radius: 6px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border: 1px solid;
  border-color: #d5d4d4;
  z-index: 100;
}
.tooltip-container {
  width: 100%;
}
.tooltip-table {
  text-align: left;
  border-collapse: collapse;
  font-size: 0.85em;
}
.tooltip-title {
  font-size: 0.85em;
  text-align: left;
  padding-bottom: 4px;
}
.tooltip-column {
  padding-right: 4px;
}
.tooltip-column-value {
  padding-right: 4px;
  padding-left: 8px;
  text-align: right;
}
.tooltip-circle-yellow {
  height: 8px;
  width: 8px;
  background-color: #f5b915;
  border-radius: 50%;
  display: inline-block;
}
.tooltip-circle-begonia {
  height: 8px;
  width: 8px;
  background-color: #cc156f;
  border-radius: 50%;
  display: inline-block;
}
.tooltip-circle-blue {
  height: 8px;
  width: 8px;
  background-color: #026df5;
  border-radius: 50%;
  display: inline-block;
}
.ChartPlaceholder {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  border: 1px solid #d5d4d4;
  background-color: white;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .ChartPlaceholder {
    margin-top: 24px;
    margin-bottom: 32px;
    height: 460px;
    width: 1220px;
    border: none;
  }
}
.ChartPlaceholder_image {
  width: 100%;
  height: auto;
}
.ChartPlaceholder_text {
  display: flex;
  position: absolute;
  align-self: center;
  padding: 20px 52px 0 52px;
  font-size: 14px;
  color: #30363e;
}
@media screen and (min-width: 768px), print {
  .ChartPlaceholder_text {
    position: absolute;
    top: calc(50% - 56px);
    left: calc(50% - 20%);
    font-size: 1.42em;
    color: #30363e;
    padding: 0;
  }
}
.PrintReportButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PrintReportButton_icon {
  width: 28px;
  height: 24px;
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22/%3E%3Cpath%20fill%3D%22white%22%20fill-rule%3D%22nonzero%22%20d%3D%22M19%208c1.66%200%203%201.34%203%203v6h-4v4H6v-4H2v-6c0-1.66%201.34-3%203-3h1V3h12v5h1zM8%205v3h8V5H8zm8%2012v-2H8v4h8v-2zm2-2h2v-4c0-.55-.45-1-1-1H5c-.55%200-1%20.45-1%201v4h2v-2h12v2zm0-2.5a1%201%200%201%201%200-2%201%201%200%200%201%200%202z%22/%3E%3C/g%3E%3C/svg%3E');
  background-repeat: no-repeat;
}
.TemperatureDifferentialForm {
  display: flex;
  width: 100%;
  margin-top: 80px;
  flex-direction: column;
}
@media screen and (min-width: 768px), print {
  .TemperatureDifferentialForm {
    margin-top: 128px;
    justify-content: space-between;
    max-height: 352px;
  }
}
.TemperatureDifferentialForm_wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  max-height: 280px;
}
@media screen and (min-width: 768px), print {
  .TemperatureDifferentialForm_wrapper {
    flex-direction: column-reverse;
    max-height: 352px;
  }
}
.TemperatureDifferential_formWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media screen and (min-width: 768px), print {
  .TemperatureDifferential_formWrapper {
    justify-content: space-between;
    max-height: 352px;
  }
}
.TemperatureDifferentialForm_title {
  font-size: 1.42em;
  height: 100%;
  padding-bottom: 8px;
}
@media (min-width: 768px) {
  .TemperatureDifferentialForm_title {
    font-size: 24px;
    padding: 32px 0;
    width: 50%;
  }
}
.TemperatureDifferentialForm_form {
  display: flex;
  background: white;
  border-radius: 1px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  padding-top: 14px;
  padding-bottom: 21px;
}
@media screen and (min-width: 768px) {
  .TemperatureDifferentialForm_form {
    flex-direction: row;
    height: 132px;
    align-items: space-around;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.TemperatureDifferentialForm_sensorWrapper {
  padding: 4px 24px;
}
@media screen and (min-width: 768px) {
  .TemperatureDifferentialForm_sensorWrapper {
    width: 462px;
    height: 80px;
    padding: 22px 0 28px 24px;
  }
}
.TemperatureDifferentialForm_wrapperTime {
  padding: 4px 24px 12px 24px;
}
@media screen and (min-width: 768px) {
  .TemperatureDifferentialForm_wrapperTime {
    width: 200px;
    height: 80px;
    padding-top: 22px;
    padding-bottom: 28px;
  }
}
.TemperatureDifferentialForm_label {
  padding-bottom: 4px;
}
.ControlsPlaceholder {
  display: flex;
  border: 1px solid #e1e1e1;
  border-radius: 1px;
  width: 100%;
  padding: 8px 8px 8px 16px;
  height: auto;
  box-sizing: border-box;
}
.ControlsPlaceholder_text {
  display: flex;
  padding-right: 31px;
}
.ControlsPlaceholder_icon {
  display: flex;
}
.ProgressBar {
  display: flex;
  position: relative;
  background-color: #026df5;
  transition: all ease 250ms;
  width: 2px;
  top: 0;
}
@media screen and (min-width: 768px), print {
  .ProgressBar {
    height: 2px;
    width: 0;
    left: 0;
  }
}
.ProgressBar_delay {
  transition-delay: 2s;
}
.ProgressBar_step0 {
  top: 0;
  height: 100px;
}
@media screen and (min-width: 768px) {
  .ProgressBar_step0 {
    height: 2px;
    top: 0;
    left: 0;
    width: 40%;
  }
}
.ProgressBar_step1 {
  top: 100px;
  height: 100px;
}
@media screen and (min-width: 768px) {
  .ProgressBar_step1 {
    height: 2px;
    top: 0;
    left: 40%;
    width: 40%;
  }
}
.ProgressBar_step2 {
  top: 200px;
  height: 80px;
}
@media screen and (min-width: 768px) {
  .ProgressBar_step2 {
    height: 2px;
    top: 0;
    left: 80%;
    width: 20%;
  }
}
.ProgressBar_complete {
  top: 0;
  height: 280px;
}
@media screen and (min-width: 768px) {
  .ProgressBar_complete {
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
  }
}
.DownloadControls {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 2px;
  border: 1px;
  border-color: #d5d4d4;
  border-style: solid;
  background: #ffffff;
}
@media screen and (min-width: 768px), print {
  .DownloadControls {
    margin-left: 4px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 48px;
  }
}
.DownloadControls_button {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 768px), print {
  .DownloadControls_button {
    padding: 4px;
  }
}
.DownloadControls_buttonContent {
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 768px), print {
  .DownloadControls_buttonContent {
    justify-content: center;
  }
}
.DownloadControls_downloadIcon {
  width: 28px;
  height: 24px;
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0V0z%22/%3E%3Cpath%20d%3D%22M19.35%2010.04C18.67%206.59%2015.64%204%2012%204%209.11%204%206.6%205.64%205.35%208.04%202.34%208.36%200%2010.91%200%2014c0%203.31%202.69%206%206%206h13c2.76%200%205-2.24%205-5%200-2.64-2.05-4.78-4.65-4.96zM19%2018H6c-2.21%200-4-1.79-4-4%200-2.05%201.53-3.76%203.56-3.97l1.07-.11.5-.95C8.08%207.14%209.94%206%2012%206c2.62%200%204.88%201.86%205.39%204.43l.3%201.5%201.53.11c1.56.1%202.78%201.41%202.78%202.96%200%201.65-1.35%203-3%203zm-5.55-8h-2.9v3H8l4%204%204-4h-2.55z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
}
.DownloadControls_chartIcon {
  width: 28px;
  height: 24px;
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0V0z%22/%3E%3Cpath%20d%3D%22M3.5%2018.49l6-6.01%204%204L22%206.92l-1.41-1.41-7.09%207.97-4-4L2%2016.99l1.5%201.5z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
}
.TemperatureDifferentialTable_printHeader {
  display: none;
}
@media only print {
  .TemperatureDifferentialTable_printHeader {
    display: inherit;
    font-size: 1.42em;
    margin-bottom: 8px;
  }
}
.TemperatureDifferentialPrintHeader_printHeader {
  font-size: 0.85em;
  display: flex;
  width: 100%;
  margin-top: 8mm;
}
.TemperatureDifferentialPrintHeader_printHeaderFirstLine {
  display: flex;
}
.TemperatureDifferentialPrintHeader_printHeaderWrapper {
  display: flex;
  flex-direction: column;
}
.TemperatureDifferentialPrintHeader_printHeaderTitle {
  font-size: 1.42em;
}
.TemperatureDifferentialPrintHeader_printHeaderTitle {
  font-size: 1.42em;
}
.TemperatureDifferentialPrintHeader_printHeaderLogoWrapper {
  margin-left: auto;
}
.TemperatureDifferentialPrintHeader_printHeaderLogo {
  width: 110px;
}
.TemperatureDifferentialPrintHeader_printHeaderCreatedBy {
  margin-left: 50px;
}
.TemperatureDifferentialPrintHeader_siteName {
  margin-top: 16px;
  font-size: 0.85em;
}
.TemperatureDifferentialPrintHeader_printHeaderSecondLine {
  margin-top: 0px;
  display: flex;
}
.TemperatureDifferentialPrintHeader_sensorName {
  font-size: 1.42em;
}
.TemperatureDifferentialPrintHeader_printHeaderTimePeriod {
  font-size: 0.85em;
  margin-left: auto;
  display: flex;
}
.TemperatureDifferentialPrintHeader_printHeaderTimePeriod span {
  align-self: flex-end;
}
.Table {
  width: 100%;
  border-collapse: collapse;
}
.Table_wrapper {
  border: solid 1px #e1e1e1;
  width: 100%;
}
.Table_borderless {
  border: none;
}
.Table_row:nth-child(even) {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-color: #f4f4f4;
}
.Table_rowHasBorder:nth-child(even) {
  background-color: #ffffff;
  border-bottom: solid 1px rgba(151, 151, 151, 0.2);
  border-top: solid 1px rgba(151, 151, 151, 0.2);
}
.Table_rowHasBorder:last-child {
  border-bottom: none;
}
.Table_sortable {
  overflow-y: auto;
  height: 642px;
}
@media only print {
  .Table_sortable {
    overflow-y: inherit;
    height: auto;
  }
}
.Column {
  text-align: center;
  padding: 12px;
}
@media only print {
  .Column {
    padding: 8px;
  }
}
.Column_header {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  position: sticky;
  top: 0;
  background: #fff;
  padding: 22px 0;
  border-bottom: solid 1px #e1e1e1;
}
@media only print {
  .Column_header {
    padding: 16px 0;
  }
}
.Column_headerBorderless {
  border-bottom: none;
  padding-bottom: 8px;
}
.Column_imageCellWrapper {
  height: 40px;
  overflow: hidden;
}
.Column_imageCell {
  margin-top: 1px;
}
.Column_noPadding {
  padding: 0;
}
.SortableColumn_sortIcon {
  background-repeat: no-repeat;
  vertical-align: middle;
}
@media only print {
  .SortableColumn_sortIcon {
    display: none;
  }
}
.PoursIndexView {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 88px;
}
@media only screen and (min-width: 768px) {
  .PoursIndexView {
    margin-top: 136px;
  }
}
.PoursIndexView_header {
  display: flex;
}
.PoursIndexView_headerText {
  display: flex;
  margin-top: 24px;
}
@media only screen and (min-width: 768px) {
  .PoursIndexView_headerText {
    margin-top: 37px;
  }
}
.PoursIndexView_pourCount {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #d5d4d4;
  border-radius: 2px;
  padding: 0 8px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
  margin-left: 8px;
}
@media only screen and (min-width: 768px) {
  .PoursIndexView_pourCount {
    font-size: 16px;
    background-color: rgba(34, 35, 46, 0.05);
    border-radius: 5px;
    margin-top: 37px;
  }
}
.PoursIndexView_addPourButton {
  width: 164px;
  position: fixed;
  bottom: 8px;
  right: 16px;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .PoursIndexView_addPourButton {
    display: flex;
    position: absolute;
    right: 0;
    top: 32px;
    z-index: 2;
  }
}
.PoursIndexView .SubNavbar_topBarChildren {
  display: flex;
  flex-wrap: nowrap;
}
.AddPour_icon {
  padding-right: 8px;
}
@media only screen and (min-width: 768px) {
  .PoursIndexView_pourList {
    margin-top: 12px;
  }
}
.PoursIndexView_pourListActive {
  margin-bottom: 40px;
}
.PoursIndexPlaceholder {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  background-color: #f2f2f2;
  margin-top: 12px;
}
@media only screen and (min-width: 768px) {
  .PoursIndexPlaceholder {
    align-items: center;
    padding: 56px 210px;
    margin-top: 12px;
  }
}
.PoursIndexPlaceholder_header {
  color: #30363e;
}
.PoursIndexPlaceholder_text {
  max-width: 800px;
  color: #30363e;
}
.PoursIndexPlaceholder_addPour {
  margin-top: 32px;
  align-self: center;
  text-decoration: underline;
  color: #10a265;
  cursor: pointer;
}
.PoursIndexSubnavbar_filter {
  display: flex;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .PoursIndexSubnavbar_filter {
    width: 346px;
  }
}
.PoursIndexSubnavbar_siteSelector {
  display: none;
}
@media only screen and (min-width: 768px) {
  .PoursIndexSubnavbar_siteSelector {
    display: flex;
    width: 100%;
  }
}
.PourItem_wrapper {
  width: 100%;
  padding-left: 16px;
  padding-right: 4px;
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  box-sizing: border-box;
  margin-top: 8px;
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 768px), print {
  .PourItem_wrapper {
    padding-top: 8px;
    flex-direction: column;
  }
}
.PourItem_orange {
  border-left: 4px solid #ff9700;
}
.PourItem_red {
  border-left: 4px solid #ff0000;
}
.PourItem_item {
  display: flex;
  width: 100%;
  text-align: left;
  align-items: center;
  min-height: 64px;
}
.PourItem_itemNameSensorCount {
  display: flex;
  flex-direction: column;
}
.PourItem_name {
  font-size: 1.28em;
}
@media screen and (min-width: 768px), print {
  .PourItem_name {
    font-size: 1.42em;
  }
}
.PourItem_sensorCount {
  font-size: 0.85em;
}
@media screen and (min-width: 768px), print {
  .PourItem_sensorCount {
    font-size: 0.85em;
  }
}
.PourItem_mobileArrowIcon {
  display: flex;
  margin-left: auto;
}
.PourItem_link {
  text-decoration: none;
  color: #000000;
}
.PourItem_link:visited {
  color: #000000;
}
.PourForm_title {
  margin: 24px 0;
}
.PourForm_buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
}
@media only screen and (min-width: 768px) {
  .PourForm_buttons {
    flex-direction: row;
  }
}
.PourForm_buttons > * {
  margin: 8px 0;
}
@media only screen and (min-width: 768px) {
  .PourForm_buttons > * {
    margin: 0 24px;
  }
}
.PourForm_deleteSection {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.85em;
}
.PourStatus_wrapper {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px), print {
  .PourStatus_wrapper {
    margin-left: auto;
    padding: 4px;
    padding-bottom: 20px;
    padding-right: 16px;
  }
}
.PourStatus_red {
  color: #ff0000;
}
.PourStatus_orange {
  color: #ff9700;
}
.PourStatus_green {
  color: #10a265;
}
.PourStatus_grey {
  color: #30363e;
}
.PourView {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 88px;
}
@media only screen and (min-width: 768px) {
  .PourView {
    margin-top: 136px;
  }
}
.PourView_addSensorButton {
  width: 150px;
  position: fixed;
  bottom: 8px;
  right: 16px;
  z-index: 3;
}
@media only screen and (min-width: 768px) {
  .PourView_addSensorButton {
    display: flex;
    width: 165px;
    position: absolute;
    right: 0;
    top: 32px;
    z-index: 2;
  }
}
.PourView_header {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.PourView_headerPourName {
  display: flex;
  align-items: center;
  font-size: 24px;
  padding-right: 8px;
}
.PourView_headerMixName {
  font-size: 14px;
}
.PourView_sensorLists {
  display: flex;
  width: 100%;
  z-index: 1;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .PourView_sensorLists {
    justify-content: space-between;
  }
}
.PourView_plusIcon {
  padding-right: 8px;
}
.PourView .SubNavbar_topBarChildren {
  display: flex;
}
.PourView_editButtonWrapper {
  margin: 0 32px;
}
.PourView_countWrapper {
  display: flex;
  align-items: center;
}
.PourViewSubnavbar_filter {
  display: flex;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .PourViewSubnavbar_filter {
    width: 346px;
  }
}
.PourViewSubnavbar_allPoursLink {
  display: none;
  color: #30363e;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .PourViewSubnavbar_allPoursLink {
    display: flex;
    width: 100%;
  }
}
.TokenGeneratorView {
  margin-top: 88px;
}
@media (min-width: 768px) {
  .TokenGeneratorView {
    margin-top: 136px;
  }
}
.TokenGeneratorView_content {
  padding-top: 24px;
}
@media (min-width: 768px) {
  .TokenGeneratorView_content {
    padding-top: 40px;
  }
}
.TokenGeneratorView_formWrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
.TokenGeneratorView_form {
  padding: 16px;
}
.ApiTokenAlert_wrapper {
  box-sizing: border-box;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .ApiTokenAlert_wrapper {
    flex-direction: row;
  }
}
.ApiTokenAlert_body {
  color: #30363e;
  line-height: 1.57;
  margin: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .ApiTokenAlert_body {
    text-align: left;
  }
}
.KpiDashboard {
  display: flex;
  flex-direction: column;
  margin-top: 76px;
}
@media screen and (min-width: 768px), print {
  .KpiDashboard {
    margin-top: 168px;
  }
}
.KpiDashboard_header {
  display: flex;
  z-index: 2;
  margin-bottom: 72px;
}
.KpiDashboard_site {
  display: flex;
  color: #ffffff;
  margin: 0;
  font-size: 2.8em;
}
.KpiDashboard_organisation {
  display: flex;
  color: #ffffff;
  opacity: 0.5;
  padding-left: 8px;
  align-self: flex-end;
  padding-bottom: 6px;
}
.KpiDashboard_mixSelector {
  display: flex;
  z-index: 2;
  font-size: 1.42em;
  color: #ffffff;
}
.KpiDashboard_mixSelectorText {
  display: flex;
  padding-right: 8px;
}
.KpiDashboard_mixSelectorSelect {
  display: flex;
  width: 200px;
  border-bottom: #ffffff solid 1px;
  height: 36px;
}
.KpiDashboard_cardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
  margin-top: 40px;
}
.KpiDashboard_spinner {
  margin-top: 200px;
}
.MixSelector_container {
  display: flex;
  width: 138px;
}
.MobileAlert {
  box-sizing: border-box;
  padding: 24px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.MobileAlert_body {
  color: #30363e;
  line-height: 1.57;
  margin: 0;
  text-align: left;
}
.MobileAlert_icon {
  float: left;
  margin-top: -3px;
}
.MobileAlert_wrapper {
  display: flex;
  margin-bottom: 24px;
}
.KpiCard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-bottom: 2%;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
}
.KpiCard_col_4 {
  display: flex;
  width: 32.3%;
}
.KpiCard_col_6 {
  display: flex;
  width: 49%;
}
.KpiCard_col_8 {
  display: flex;
  width: 65.6%;
}
.KpiCard_col_12 {
  display: flex;
  width: 100%;
}
.KpiCard_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 56px;
  border-bottom: solid 1px rgba(151, 151, 151, 0.2);
}
.KpiCard_title {
  display: flex;
  color: #30363e;
  font-size: 14px;
}
.KpiCard_plot {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 24px;
  width: 100%;
}
.KpiCard_scrollablePlot {
  align-items: flex-start;
  overflow: scroll;
  max-height: 440px;
}
.KpiCard_tablePlot {
  padding-top: 0;
  padding-bottom: 8px;
}
.KpiCard_chart {
  display: flex;
}
.KpiCard_tooltip {
  display: flex;
}
.KpiCardPlaceholder {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 72px 40px;
  font-size: 16px;
}
.KpiCardPlaceholder_title {
  display: flex;
  margin-bottom: 8px;
}
.KpiCardPlaceholder_body {
  display: flex;
  text-align: center;
}
.KpiCardListPlaceholder {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;
  font-size: 1.42em;
  width: 100%;
  padding: 80px 0;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
}
.KpiCardListPlaceholder_title {
  display: flex;
  margin-bottom: 8px;
}
.KpiCardListPlaceholder_body {
  display: flex;
  text-align: center;
}
.InformationCard_imgRound {
  margin-bottom: 32px;
}
.InformationCard_contentWrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.InformationCard_title {
  width: 100%;
  min-height: 44px;
  text-align: left;
  font-size: 1.28em;
  margin-bottom: 20px;
  line-height: 1.2;
}
.InformationCard_mainContent {
  width: 100%;
  text-align: left;
  min-height: 68px;
  line-height: 1.57;
}
.InformationCard_wrapper {
  position: relative;
  min-height: 400px;
  padding: 32px 24px;
  width: 100%;
  box-sizing: border-box;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .InformationCard_wrapper {
    height: 100%;
  }
}
.InformationCard_imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  width: 200px;
}
.InformationCard_indexBubble {
  position: absolute;
  left: 0;
  top: 20px;
  transform: translate(-40%);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 1.42em;
  background-color: #026df5;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SubNavbar_topBar {
  position: fixed;
  z-index: 3;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 56px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
@media (max-width: 768px), print {
  .SubNavbar_topBar {
    top: 48px;
    height: 40px;
    border-radius: 0 0 10px 10px;
    padding: 0 16px;
  }
}
.SubNavbar_topBarChildren {
  width: 1220px;
}
@media (max-width: 768px) {
  .SubNavbar_topBarChildren {
    top: 48px;
  }
}
@media (max-width: 768px) {
  .SubNavbar_spacer {
    display: none;
  }
}
.SiteSelector_container {
  width: 256px;
}
@media (min-device-width: 320px) and (max-device-width: 480px) {
  .SiteSelector_container {
    top: 48px;
    width: 100%;
  }
}
.Button {
  border-style: none;
  font-size: 14px;
  text-decoration: none;
  border-radius: 2px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Button:visited {
  color: #ffffff;
}
.Button:visited {
  color: #ffffff;
}
.Button:disabled {
  background: #d5d4d4;
  color: #30363e;
  opacity: 0.3;
}
.Button:disabled:hover {
  background: #d5d4d4;
}
.Button:disabled:active {
  background: #30363e;
}
.Button:disabled:focus {
  background: #f5f5f5;
}
.Button_blue {
  color: #ffffff;
  background-color: #026df5;
}
.Button_blue:hover {
  background: #1b85f6;
}
.Button_blue:active {
  background: #014298;
}
.Button_blue:focus {
  background: #469cf6;
}
.Button_black {
  color: #ffffff;
  background-color: #000000;
}
.Button_red {
  color: #ffffff;
  background-color: #ff0000;
}
.Button_begonia {
  background-color: #cc156f;
  color: #ffffff;
}
.Button_begonia:hover {
  background: #d52e87;
}
.Button_begonia:active {
  background: #b71263;
}
.Button_begonia:focus {
  background: #dc569e;
}
.Button_green {
  background-color: #10a265;
  color: #ffffff;
}
.Button_green:hover {
  background: #29b37d;
}
.Button_green:active {
  background: #0e915a;
}
.Button_green:focus {
  background: #51c195;
}
.Button_yellow {
  background-color: #f5b915;
  color: #ffffff;
}
.Button_yellow:hover {
  background: #f6c62e;
}
.Button_yellow:active {
  background: #dca612;
}
.Button_yellow:focus {
  background: #f6d056;
}
.Button_purple {
  background-color: #7850c1;
  color: #ffffff;
}
.Button_purple:hover {
  background: #9069cc;
}
.Button_purple:active {
  background: #573a8d;
}
.Button_purple:focus {
  background: #a585d5;
}
.Button_white {
  background-color: #ffffff;
  border: solid 1px #ffffff;
  color: #30363e;
}
.Button_white:hover {
  background: #f6f6f6;
}
.Button_ghost {
  color: #30363e;
  background-color: transparent;
  border: solid 1px #d5d4d4;
}
.Button_ghostWhite {
  color: #ffffff;
  background-color: transparent;
  border: solid 1px #ffffff;
}
.Button_standard {
  height: 48px;
}
@media only screen and (min-width: 768px) {
  .Button_standard {
    width: 400px;
  }
}
.Button_extra-small {
  height: 32px;
  width: 108px;
}
a.Button_extra-small {
  line-height: 32px;
}
.Button_small {
  height: 32px;
  width: 180px;
}
a.Button_small {
  line-height: 32px;
}
.Button_medium {
  height: 48px;
  width: 150px;
}
.Button_alert {
  height: 48px;
  width: 180px;
}
@media only screen and (max-width: 480px) {
  .Button_alert {
    width: 100%;
  }
}
a.Button_alert {
  line-height: 32px;
}
.Button_fullWidth {
  width: 100%;
}
.Button_outlined {
  border: 1px solid #30363e;
}
.Spinner_wrapper {
  width: 100%;
}
.Spinner_loadingText {
  display: flex;
  justify-content: center;
}
.Spinner_main {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #a4a4a4;
  background: -moz-linear-gradient(left, #a4a4a4 10%, rgba(164, 164, 164, 0) 42%);
  background: -webkit-linear-gradient(left, #a4a4a4 10%, rgba(164, 164, 164, 0) 42%);
  background: -o-linear-gradient(left, #a4a4a4 10%, rgba(164, 164, 164, 0) 42%);
  background: -ms-linear-gradient(left, #a4a4a4 10%, rgba(164, 164, 164, 0) 42%);
  background: linear-gradient(to right, #a4a4a4 10%, rgba(164, 164, 164, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.Spinner_main:before {
  width: 50%;
  height: 50%;
  background: #a4a4a4;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.Spinner_main:after {
  background: #f8fafa;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.Message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  min-height: 7em;
  z-index: 3;
  border-radius: 10px 10px 0em 0em;
  padding: 24px 16px 24px 16px;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.1);
}
.Message p {
  margin: 0 0 4px 0;
}
.Message p:last-child {
  margin: 0 0 16px 0;
}
.Message.error {
  color: white;
  background-color: #cc156f;
}
.Message.info {
  color: white;
  background-color: #10a265;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .Message {
    left: 0%;
  }
}
.Message_title {
  font-size: 1.28em;
}
@media only screen and (min-width: 768px) {
  .Message_title {
    margin-top: 0.5em;
  }
}
.Message_body {
  margin: 0 auto;
  word-break: BREAK-WORD;
  white-space: initial;
  text-align: left;
  font-size: 14px;
}
@media only screen and (min-width: 768px) {
  .Message_body {
    text-align: center;
    width: 500px;
  }
}
.Message_children {
  text-align: center;
  padding: 0.5em;
}
.Input_inputWrapper {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 24px;
  font-size: 14px;
  color: #30363e;
}
.Input_input {
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  min-height: 40px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  width: 100%;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% 50%;
}
@media (min-width: 768px) {
  .Input_input {
    padding: 0 14px;
  }
}
.Input_input:disabled {
  background-color: #dcdad1;
}
.Input_labels {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
}
.Input_alignLeft {
  text-align: left;
}
.Input_alignRight {
  text-align: right;
}
.Input_labels > * {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  margin-bottom: 0;
}
.Input_errorMessage {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
  color: #ff0000;
  font-size: 14px;
  letter-spacing: -0.2px;
}
.Input_validating {
  background-image: url("img/form-validating.svg");
}
.Input_validInput {
  background-image: url("img/form-valid.svg");
}
.Input_invalidInput {
  background-image: url("img/form-error.svg");
  border: 2px solid #ff0000;
}
.Input_invalidInput:focus {
  outline: none;
}
.SelectInput_inputWrapper {
  width: 100%;
  color: #a4a4a4;
  margin-bottom: 24px;
}
.SelectInput_inputWrapper input {
  box-shadow: none;
}
.SelectInput_labels {
  display: flex;
  justify-content: space-between;
}
.SelectInput_labels > * {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  margin-bottom: 0;
}
.SelectInput_alignLeft {
  text-align: left;
}
.SelectInput_errorMessage {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
  color: #ff0000;
  font-size: 16px;
  letter-spacing: -0.2px;
}
.SelectInput_selectWrapper {
  font-size: 20px;
  margin: 4px 0 0 0;
  min-height: 44px;
  width: 100%;
}
.Form_formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.DurationSelector_container {
  width: 100%;
  display: flex;
  align-items: center;
}
.DurationSelector_clock {
  width: 17.5px;
  height: 17.5px;
}
.FilterBarComponent_filterInput {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  box-sizing: inherit;
  text-indent: 0;
  height: 22px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .FilterBarComponent_filterInput {
    line-height: 0.85em;
  }
}
.FilterBarComponent_filterInput::placeholder {
  font-size: 0.85em;
  line-height: 16px;
  letter-spacing: normal;
  color: #30363e;
  padding-left: 4px;
}
@media only screen and (min-width: 768px) {
  .FilterBarComponent_filterInput::placeholder {
    font-size: 14px;
    line-height: 14px;
  }
}
.FilterControl {
  width: 100%;
}
.FilterBarComponent_filterInput:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0);
}
.AlertBox {
  display: flex;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 8px 16px 20px 16px;
  box-sizing: border-box;
  flex-wrap: nowrap;
}
@media (min-width: 768px) {
  .AlertBox {
    padding: 20px 4px 20px 0;
    flex-wrap: wrap;
  }
}
.AlertBox_begonia {
  display: flex;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 8px 16px 20px 16px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  background-color: #f9e6f0;
  border-top: 2px solid #cc156f;
}
@media (min-width: 768px) {
  .AlertBox_begonia {
    padding: 20px 4px 20px 0;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .AlertBox_begonia {
    border-top: none;
    border-left: 2px solid #cc156f;
  }
}
.AlertBox_yellow {
  display: flex;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 8px 16px 20px 16px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  background-color: #fbf4da;
  border-top: 2px solid #f5b915;
}
@media (min-width: 768px) {
  .AlertBox_yellow {
    padding: 20px 4px 20px 0;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .AlertBox_yellow {
    border-top: none;
    border-left: 2px solid #f5b915;
  }
}
.AlertBox_purple {
  display: flex;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 8px 16px 20px 16px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  background-color: #ebe5f5;
  border-top: 2px solid #7850c1;
}
@media (min-width: 768px) {
  .AlertBox_purple {
    padding: 20px 4px 20px 0;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .AlertBox_purple {
    border-top: none;
    border-left: 2px solid #7850c1;
  }
}
.AlertBox_blue {
  display: flex;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 8px 16px 20px 16px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  background-color: #d7e9fb;
  border-top: 2px solid #026df5;
}
@media (min-width: 768px) {
  .AlertBox_blue {
    padding: 20px 4px 20px 0;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .AlertBox_blue {
    border-top: none;
    border-left: 2px solid #026df5;
  }
}
.AlertBox_icon {
  display: inline-flex;
  height: 24px;
  width: 24px;
  padding-right: 4px;
  background-repeat: no-repeat;
  transform: translateY(6px);
}
@media (min-width: 768px) {
  .AlertBox_icon {
    transform: translateY(2px);
  }
}
.AlertBox_icon_yellow {
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22/%3E%3Cpath%20fill%3D%22%23FF9700%22%20fill-rule%3D%22nonzero%22%20d%3D%22M3%2019.545h18L12%204%203%2019.545zm9.818-2.454h-1.636v-1.636h1.636v1.636zm0-3.273h-1.636v-3.273h1.636v3.273z%22/%3E%3C/g%3E%3C/svg%3E');
}
.AlertBox_icon_begonia {
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22/%3E%3Cpath%20fill%3D%22%23FF2000%22%20fill-rule%3D%22nonzero%22%20d%3D%22M12%204c-4.416%200-8%203.584-8%208s3.584%208%208%208%208-3.584%208-8-3.584-8-8-8zm.8%2012h-1.6v-1.6h1.6V16zm0-3.2h-1.6V8h1.6v4.8z%22/%3E%3C/g%3E%3C/svg%3E');
}
.AlertBox_icon_purple {
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cg%3E%3Cpath%20fill%3D%22%237850c1%22%20fill-opacity%3D%22.01%22%20d%3D%22M0%200h24v24H0z%22/%3E%3Cpath%20fill%3D%22%237850c1%22%20fill-rule%3D%22nonzero%22%20d%3D%22M11.33%2015h1.33v2h-1.33v-2zm0-8h1.33v6h-1.33V7zm.66-5C6.47%202%202%206.48%202%2012s4.47%2010%209.99%2010C17.52%2022%2022%2017.52%2022%2012S17.52%202%2011.99%202zm.005%2018.66a8.663%208.663%200%200%201-8.665-8.665%208.663%208.663%200%200%201%208.665-8.665%208.663%208.663%200%200%201%208.665%208.665%208.663%208.663%200%200%201-8.665%208.665z%22/%3E%3C/g%3E%3C/svg%3E');
}
.AlertBox_icon_blue {
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cg%3E%3Cpath%20fill%3D%22%23026df5%22%20fill-opacity%3D%22.01%22%20d%3D%22M0%200h24v24H0z%22/%3E%3Cpath%20fill%3D%22%23026df5%22%20fill-rule%3D%22nonzero%22%20d%3D%22M11.33%2015h1.33v2h-1.33v-2zm0-8h1.33v6h-1.33V7zm.66-5C6.47%202%202%206.48%202%2012s4.47%2010%209.99%2010C17.52%2022%2022%2017.52%2022%2012S17.52%202%2011.99%202zm.005%2018.66a8.663%208.663%200%200%201-8.665-8.665%208.663%208.663%200%200%201%208.665-8.665%208.663%208.663%200%200%201%208.665%208.665%208.663%208.663%200%200%201-8.665%208.665z%22/%3E%3C/g%3E%3C/svg%3E');
}
.AlertIcon_wrapper {
  line-height: 1.5;
  flex-basis: 25px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
}
@media (min-width: 768px) {
  .AlertIcon_wrapper {
    padding: 0px 8px 4px 20px;
  }
}
.AlertBox_body {
  display: flex;
  flex-grow: 1;
  text-align: left;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 768px) {
  .AlertBox_body {
    padding: 0 16px 4px 0;
  }
}
.AlertBox_title {
  display: inline-block;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 4px;
}
@media (min-width: 768px) {
  .AlertBox_title {
    display: flex;
    font-size: 1.28em;
  }
}
.AlertBox_message {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 768px) {
  .AlertBox_message {
    padding-left: 56px;
  }
}
.AlertBox_button {
  width: 180px;
  display: flex;
  width: 100%;
  align-items: flex-end;
  padding: 4px;
}
@media (min-device-width: 480px) {
  .AlertBox_button {
    width: 180px;
    padding-right: 24px;
  }
}
.FixedFooter_wrapper {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 100vw;
  border-radius: 10px 10px 0 0;
  box-shadow: -2px 3px 20px 0px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  color: #000000;
  padding: 40px 16px 16px 16px;
}
@media (min-width: 768px) {
  .FixedFooter_wrapper {
    padding: 40px 30% 16px 30%;
  }
}
.BottomPanel_overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /*
     * The navbar has a z-index of 3 so this should go
     * below it but still cover the entire screen so that we
     * can handle clicks outside of this div in the future.
     */
  z-index: 2;
}
.BottomPanel_slideIn {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 75%;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px 12px 0 0;
  box-shadow: 2px 0 14px 0 rgba(0, 0, 0, 0.16);
  padding: 0 16px;
  box-sizing: border-box;
  overflow: scroll;
}
@media (min-width: 768px) {
  .BottomPanel_slideIn {
    height: 80%;
  }
}
@media (min-width: 768px) {
  .BottomPanel_separator {
    background-color: #026df5;
    transform: translateY(-4px);
    height: 4px;
    margin: 0 auto;
    width: 100%;
  }
}
.BottomPanel_content {
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .BottomPanel_content {
    width: 640px;
  }
}
.BottomPanel_transition-appear,
.BottomPanel_transition-enter {
  height: 0px;
}
.BottomPanel_transition-appear-active,
.BottomPanel_transition-enter-active {
  height: 75%;
  transition: height 250ms ease-in;
}
@media (min-width: 768px) {
  .BottomPanel_transition-appear-active,
  .BottomPanel_transition-enter-active {
    height: 80%;
  }
}
.BottomPanel_transition-exit {
  height: 75%;
}
@media (min-width: 768px) {
  .BottomPanel_transition-exit {
    height: 80%;
  }
}
.BottomPanel_transition-exit-active {
  height: 0px;
  transition: height 250ms ease-in;
}
.DateInput_wrapper {
  width: 100%;
  margin-bottom: 24px;
}
.DateInput_labels {
  display: flex;
  justify-content: space-between;
}
.DateInput_alignLeft {
  text-align: left;
}
.DateInput_labels > * {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 1.7;
  margin-bottom: 0;
}
.DateInput_nonNativePickerWrapper {
  /*
     * This is here because on MS Edge clicks
     * on the div containing the disabled input
     * would not trigger onClick handlers.
     * It also needs to be combined with a
     * pointer-events: none on the desktop input.
     */
  cursor: pointer;
  pointer-events: all;
}
.DateInput_errorMessage {
  display: inline-block;
  width: 100%;
  color: #ff0000;
  font-size: 14px;
  letter-spacing: -0.2px;
}
@media (min-width: 768px) {
  .DateInput_errorMessage {
    padding-left: 10px;
  }
}
.DateInput_invalidInput:focus {
  outline: none;
}
.DateInput_inputField {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 14px;
  margin: 4px;
  min-height: 40px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  margin: 4px 0;
  width: 100%;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% 50%;
}
@media (min-width: 768px) {
  .DateInput_inputField {
    pointer-events: none;
    display: inline-block;
    padding: 14px;
  }
}
.DateInput_inputField {
  cursor: pointer;
  background-image: url("img/calendar_icon.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% 50%;
}
/*
 * These TimeInput classes are in this file because DateInput
 * is a standalone component and TimeInput is a subcomponent
 * of it, and therefore should live within DateInput.
 * Since DateInput doesn't have its own folder due to being
 * a standalone component they ended up here
 */
.TimeInput_timeSelector {
  margin: 0 24px;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: rgba(151, 151, 151, 0.5);
}
.TimeInput_controls {
  display: flex;
  margin: 20px 24px 0 24px;
  padding-bottom: 24px;
}
.TimeInput_timeSelector {
  display: flex;
  justify-content: center;
  align-items: center;
}
.TimeInput_inputSeparator {
  font-size: 1.71em;
}
.TimeInput_timeSelector > *:not(:last-child) {
  margin-right: 8px;
}
.TimeInput_inputField {
  width: 56px;
  margin-bottom: 16px;
  margin-top: 16px;
  font-size: 14px;
}
.TimeInput_controls > *:not(:last-child) {
  margin-right: 8px;
}
.EditableText {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.EditableText_editing {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 768px) {
  .EditableText_editing {
    flex-direction: row;
  }
}
.EditableText_icon {
  width: 25px;
  height: 25px;
  background-image: url('data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0V0z%22/%3E%3Cpath%20d%3D%22M14.06%209.02l.92.92L5.92%2019H5v-.92l9.06-9.06M17.66%203c-.25%200-.51.1-.7.29l-1.83%201.83%203.75%203.75%201.83-1.83c.39-.39.39-1.02%200-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6%203.19L3%2017.25V21h3.75L17.81%209.94l-3.75-3.75z%22/%3E%3C/svg%3E');
}
.EditableText_input {
  flex: 2;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  border: thin solid white;
  border-radius: 0;
  -ms-flex-preferred-size: auto;
  width: 100%;
}
.EditableText_buttons {
  display: flex;
  padding-top: 4px;
  padding-bottom: 2px;
}
@media (min-width: 768px) {
  .EditableText_buttons {
    padding-top: 2px;
    padding-bottom: 2px;
    margin: -148px;
  }
}
.EditableText_inputButtonSave {
  width: 67px;
  height: 32px;
  padding-left: 4px;
}
.EditableText_inputButtonCancel {
  width: 67px;
  height: 32px;
  padding-left: 4px;
}
.CountBadge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 19.5px;
  background-color: rgba(34, 35, 46, 0.05);
  padding: 0 8px;
  box-sizing: border-box;
  color: #30363e;
  margin: 0 16px;
}
.StatusBadge {
  display: flex;
  align-items: center;
}
.StatusBadge_iconWrapper {
  padding: 0 4px 0 0;
}
.StatusBadge.Offline,
.StatusBadge.CriticalDiagnostic {
  color: #ff0000;
}
.StatusBadge.ModerateDiagnostic {
  color: #ff9700;
}
.StatusBadge.Online {
  color: #019f80;
}
.StatusBadge.Unplugged,
.StatusBadge.Off {
  color: #30363e;
}
.StatusBadge {
  font-size: 14px;
  line-height: 14px;
}
.SubNavSearchBox_filterControl {
  display: flex;
  align-items: center;
  padding-right: 4px;
  transition: 250ms;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .SubNavSearchBox_filterControl {
    width: 100%;
  }
}
.SubNavSearchBox_isSearching {
  width: 100%;
  border-bottom: 1px solid #000000;
}
@media only screen and (min-width: 768px) {
  .SubNavSearchBox_isSearching {
    border: none;
  }
}
.SensorCardList_loadMoreWrapper {
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;
}
.EditSensorForm_title {
  margin: 24px 0;
}
.EditSensorForm_buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
}
@media only screen and (min-width: 768px) {
  .EditSensorForm_buttons {
    flex-direction: row;
  }
}
.EditSensorForm_buttons > * {
  margin: 8px 0;
}
@media only screen and (min-width: 768px) {
  .EditSensorForm_buttons > * {
    margin: 0 24px;
  }
}
.EditSensorForm_selectLabel {
  color: #30363e;
}
.EditSensorForm_pourSelect {
  color: #30363e;
  display: flex;
  justify-content: space-between;
}
.EditSensorForm_pourSelected {
  font-size: 14px;
  color: #30363e;
  padding-left: 22px;
}
.EditSensorForm_pourSelectOption {
  color: #30363e;
  padding-left: 12px;
}
.EditSensorForm_pourSelectPourNameDifferentMix {
  opacity: 0.5;
}
.EditSensorForm_selectOptionMixNameDifferentMix {
  color: #ff0000;
  opacity: 0.5;
}
.EditSensorForm_message {
  margin-top: 8px;
  color: #30363e;
  font-size: 0.85em;
}
.EditButton_editIcon {
  height: 12px;
  width: 12px;
  margin: 0 4px;
}
.EditButton_editButtonText {
  padding: 4px;
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.ConfirmationDialogue_bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(164, 164, 164, 0.8);
  z-index: 2;
}
.ConfirmationDialogue_box {
  flex-direction: column;
  background-color: #ffffff;
  position: absolute;
  padding: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  display: flex;
  min-width: 320px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  max-width: 100%;
  box-sizing: border-box;
}
.ConfirmationDialogue_children {
  margin-bottom: 16px;
}
.ConfirmationDialogue_controls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .ConfirmationDialogue_controls {
    flex-direction: row;
  }
}
.ConfirmationDialogue_controls > *:not(:first-child) {
  margin-top: 8px;
}
.NotFoundItem_loading {
  margin: 24px;
  padding-top: 20px;
  font-size: 1.42em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NotFoundItem_message {
  margin-bottom: 48px;
}
.Tooltip {
  position: relative;
  display: flex;
  cursor: help;
}
.Tooltip_text {
  font-size: 0.85em;
  visibility: hidden;
  background-color: #ffffff;
  text-align: center;
  width: 200px;
  padding: 16px;
  border-radius: 6px;
  border: solid 1px #e1e1e1;
  box-shadow: 0 4px 17px 0 rgba(16, 124, 175, 0.23);
  top: -300%;
  right: 105%;
  position: absolute;
  text-align: left;
}
@media only screen and (min-width: 768px) {
  .Tooltip_text {
    width: 294px;
    padding: 20px;
    top: auto;
    right: auto;
    bottom: 100%;
    left: 50%;
    margin-left: -168px;
  }
}
.Tooltip:hover .Tooltip_text {
  visibility: visible;
}
.Tooltip_title {
  font-weight: bold;
  text-align: left;
  margin: 0;
  padding-bottom: 4px;
}
.NavbarAlert_wrapper {
  will-change: opacity;
  position: absolute;
  pointer-events: all;
  background-color: #f9e6f0;
  z-index: 4;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  transform: translateY(48px);
  padding: 12px 16px;
}
@media only screen and (min-width: 768px) {
  .NavbarAlert_wrapper {
    top: 100%;
    left: -50%;
    width: 600px;
    padding: 24px 16px;
    transform: translateY(-12px);
  }
}
.NavbarAlert_topBar {
  height: 4px;
  width: 100%;
  background-color: #ff0000;
  position: absolute;
  top: 0;
  left: 0;
}
.NavbarAlert_triangleUp {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ff0000;
  position: absolute;
  transform: translate(48px, -100%);
}
@media only screen and (min-width: 768px) {
  .NavbarAlert_triangleUp {
    transform: translate(120px, -100%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ff0000;
  }
}
.NavbarAlert_icon {
  padding: 0 8px 0 0;
}
@media only screen and (min-width: 768px) {
  .NavbarAlert_icon {
    padding: 0 8px;
  }
}
.NavbarAlert_contentColumn {
  width: 100%;
}
.NavbarAlert_content {
  width: 100%;
}
.NavbarAlert_header {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #30363e;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .NavbarAlert_header {
    font-size: 18px;
  }
}
.NavbarAlert_body {
  font-family: "Lato", sans-serif;
  line-height: 1.57;
  color: #30363e;
}
@media only screen and (min-width: 768px) {
  .NavbarAlert_body {
    padding: 0 0 0 40px;
  }
}
.NavbarAlert_bodyText {
  margin: 8px 0 16px 0;
}
.NavbarAlert_controls {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .NavbarAlert_controls {
    flex-direction: row;
  }
}
.NavbarAlert_controls > *:not(:last-child) {
  margin: 0 0 16px 0;
}
@media only screen and (min-width: 768px) {
  .NavbarAlert_controls > *:not(:last-child) {
    margin: 0 16px 0 0;
  }
}
.BlinkingRedDot_wrapper {
  position: relative;
  top: -5px;
}
@media (min-width: 768px) {
  .BlinkingRedDot_wrapper {
    right: 2px;
  }
}
.BlinkingRedDot_dot {
  position: absolute;
  padding: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: solid 1px rgba(243, 74, 62, 0.7);
  box-shadow: 0 0 0 #f34a3e;
  animation: redDot_pulse 2s infinite;
}
.BlinkingRedDot_dot:before {
  content: "";
  background: #ff0000;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.BlinkingRedDot_dot:after {
  content: "";
  border: solid 1px #ff0000;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
}
@-webkit-keyframes redDot_pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #f34a3e;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(243, 74, 62, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 74, 62, 0);
  }
}
@keyframes redDot_pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #f34a3e;
    box-shadow: 0 0 0 0 #f34a3e;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(243, 74, 62, 0);
    box-shadow: 0 0 0 10px rgba(243, 74, 62, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(243, 74, 62, 0);
    box-shadow: 0 0 0 0 rgba(243, 74, 62, 0);
  }
}
.print {
  display: none;
}
@media print {
  @page {
    size: auto;
    margin-left: 4mm;
    margin-right: 4mm;
    margin-top: 0mm;
  }
  a[href]:after {
    content: none !important;
  }
  .noprint {
    display: none;
  }
  .print {
    display: block;
  }
  body {
    background: transparent;
  }
  h1 {
    font-size: 1.42em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
  h2 {
    font-size: 1.28em;
    line-height: normal;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
}
