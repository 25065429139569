.PourView {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 88px;

  @media only screen and (min-width: 768px) {
    margin-top: 136px;
  }
}

.PourView_addSensorButton {
  width: 150px;
  position: fixed;
  bottom: 8px;
  right: 16px;
  z-index: 3;

  @media only screen and (min-width: 768px) {
    display: flex;
    width: 165px;
    position: absolute;
    right: 0;
    top: 32px;
    z-index: 2;
  }
}

.PourView_header {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.PourView_headerPourName {
  display: flex;
  align-items: center;
  font-size: @tx-size-x-largepx;
  padding-right: 8px;
}

.PourView_headerMixName {
  font-size: @tx-size-base;
}

.PourView_sensorLists {
  display: flex;
  width: 100%;
  z-index: 1;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    justify-content: space-between;
  }
}

.PourView_plusIcon {
  padding-right: 8px;
}

.PourView .SubNavbar_topBarChildren {
  display: flex;
}

.PourView_editButtonWrapper {
  margin: 0 32px;
}

.PourView_countWrapper {
  display: flex;
  align-items: center;
}
