.PoursIndexSubnavbar_filter {
  display: flex;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 346px;
  }
}

.PoursIndexSubnavbar_siteSelector {
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
    width: 100%;
  }
}
