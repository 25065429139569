.EditableText {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.EditableText_editing {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

// edit icon
.inline-svg-code( @code ) {
  @-svg-code: escape(~'<?xml version="1.0" ?>@{code}');
  @-inline-svg-code: ~"data:image/svg+xml,@{-svg-code}";
  @-inline-svg-url: ~"url('@{-inline-svg-code}')";
}

.EditableText_icon {
  width: 25px;
  height: 25px;
  .inline-svg-code(
    ~'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>'
  );
  background-image: @-inline-svg-url;
}

.EditableText_input {
  flex: 2;
  font-family: @font-family;
  font-size: @tx-size-x-largepx;
  border-radius: 0;
  border: thin solid white;
  border-radius: 0;
  -ms-flex-preferred-size: auto; // fuck ie11
  width: 100%;
}

.EditableText_buttons {
  display: flex;
  padding-top: 4px;
  padding-bottom: 2px;
  @media (min-width: 768px) {
    padding-top: 2px;
    padding-bottom: 2px;
    margin: -148px;
  }
}

.EditableText_inputButtonSave {
  width: 67px;
  height: 32px;
  padding-left: 4px;
}

.EditableText_inputButtonCancel {
  width: 67px;
  height: 32px;
  padding-left: 4px;
}
