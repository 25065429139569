.StatusBadge {
  display: flex;
  align-items: center;
}

.StatusBadge_iconWrapper {
  padding: 0 4px 0 0;
}

.StatusBadge.Offline,
.StatusBadge.CriticalDiagnostic {
  color: @red;
}

.StatusBadge.ModerateDiagnostic {
  color: @orange-base;
}

.StatusBadge.Online {
  color: @green-status;
}

.StatusBadge.Unplugged,
.StatusBadge.Off {
  color: @black-bold;
}

.StatusBadge {
  font-size: @tx-size-base;
  line-height: @tx-size-base;
}
