.NavLink {
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  margin: 0 1em;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: transparent;
  color: @white;
  text-decoration: none;
  height: 100%;
  position: relative;

  @media only screen and (max-width: 768px) {
    height: 48px;
    width: 100%;
    border-width: 0 0 0 9px;
    margin: 0;
    padding: 0 1em;
  }

  &:hover {
    @media only screen and (max-width: 768px) {
      border-width: 0 0 0 9px;
    }
  }

  &:hover &:focus {
    color: @white;
  }

  &:visited {
    color: @white;
  }
}

.NavLink > * {
  display: flex;
  align-items: center;
  color: @white;
  height: 100%;
}

.NavLink:hover {
  border-width: 0 0 2px 0;
  border-color: @white;
  border-style: solid;
}

.NavLink_active {
  font-weight: normal;
  border-width: 0 0 2px 0;
  border-color: @blue-5;
  border-style: solid;

  @media only screen and (max-width: 768px) {
    font-weight: bold;
    overflow: hidden;
    border-width: 0 0 0 9px;
  }
}

.NavLink_dropdownItem {
  border-width: 0 0 0 0;
  margin: 0;

  @media only screen and (max-width: 768px) {
    border-width: 0 0 0 0;
    padding-left: 24px;
  }
}

.NavLink_dropdownItem:hover {
  border-width: 0 0 0 0;
}

.NavLink_alert {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;

  @media (min-width: 768px) {
      position: initial;
      height: auto;
      width: auto;
  }
}
