.SubNavbarLayout_background {
  position: fixed;
  top: 136px;
  width: 100vw;
  height: 116px;
  left: 0;
  background-image: url("./img/white-background-light.svg");
  background-repeat: repeat-x, no-repeat;
  pointer-events: none;

  @media screen and (max-width: 768px), print {
    display: none;
  }
}
