.Measure {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.Measure_date {
  font-size: @tx-size-x-small;

  @media screen and (min-width: 768px), print {
    font-size: @tx-size-base;
  }
}

.Measure_label {
  font-size: @tx-size-small;

  @media screen and (min-width: 768px), print {
    font-size: @tx-size-base;
  }
}

.Measure_measure {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  line-height: normal;

  @media screen and (min-width: 768px), print {
    height: 50px;
    line-height: 1.57;
  }
}

.Measure_value {
  font-size: @tx-size-xxl;

  @media screen and (min-width: 768px) {
    font-size: @tx-size-xxxl;
  }

  @media only print {
    font-size: @tx-size-xxl;
  }
}

.Measure_unit {
  font-size: @tx-size-x-small;
  margin-top: 1.8em;

  @media screen and (min-width: 768px), print {
    align-self: auto;
    padding-left: 4px;
    height: 0px;
    font-size: @tx-size-medium;
    font-weight: bold;
    margin-top: 0;
  }

  @media only print {
    font-size: @tx-size-small;
  }
}
