.DownloadControls {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 2px;
  border: 1px;
  border-color: @grey-base;
  border-style: solid;
  background: @white;

  @media screen and (min-width: 768px), print {
    margin-left: 4px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 48px;
  }
}

.DownloadControls_button {
  display: flex;
  width: 100%;

  @media screen and (min-width: 768px), print {
    padding: 4px;
  }
}

.DownloadControls_buttonContent {
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (min-width: 768px), print {
    justify-content: center;
  }
}

.inline-svg-code( @code ) {
  @-svg-code: escape(~'<?xml version="1.0" ?>@{code}');
  @-inline-svg-code: ~"data:image/svg+xml,@{-svg-code}";
  @-inline-svg-url: ~"url('@{-inline-svg-code}')";
}

.DownloadControls_downloadIcon {
  width: 28px;
  height: 24px;
  .inline-svg-code(@download-icon);
  background-image: @-inline-svg-url;
  background-repeat: no-repeat;
}

.DownloadControls_chartIcon {
  width: 28px;
  height: 24px;
  .inline-svg-code(@chart-icon);
  background-image: @-inline-svg-url;
  background-repeat: no-repeat;
}
