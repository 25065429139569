.NavDropdown {
  display: flex;
  height: 48px;
  align-items: center;
  cursor: pointer;
  padding: 0 1em;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
}

.NavDropdown a {
  text-decoration: none;
}

.NavDropdown_options {
  overflow: hidden;
  display: none;
  transform-origin: top;
  transition: height 0.25s ease, opacity 0.25s ease;
  transform: translateY(-24px);

  @media only screen and (max-width: 768px) {
    transform: none;
  }
}

.NavDropdown_options a {
  color: @white;
  cursor: pointer;
}

.NavDropdown_item {
  @media only screen and (max-width: 768px) {
    height: 1em;
  }
}

.NavDropdown_mobileHidden {
  @media only screen and (max-width: 768px) {
    height: 0;
    opacity: 0;
  }
}

.NavDropdown_mobileVisible {
  @media only screen and (max-width: 768px) {
    opacity: 1;
  }
}

.NavDropdown_arrow {
  padding-left: 0.5em;
  height: 24px;
  width: 24px;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.2s ease;

  @media only screen and (max-width: 768px) {
    display: block;
    top: 50%;
    padding: 0;
    right: 0;
    transform: translate(-36px);
  }
}

.NavDropdown_arrowUp {
  transform: translate(-36px) rotateX(180deg);
}

.NavDropdown_hr {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.NavDropdown_item hr {
  border: 0;
  height: 1px;
  background: @blue-5;
  margin: 0;
}

.NavDropdown:hover .NavDropdown_options {
  @media only screen and (min-width: 768px) {
    top: 100%;
    left: 0;
    width: 240px;
    display: block;
    position: absolute;
    padding: 1em 1em 0.5em 1em;
    background-color: @blue-base;
    border-radius: 2px;
    min-width: 100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }
}
