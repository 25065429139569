.MeasureSummary {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 768px), print {
    height: 150px;
  }
}

.MeasureSummary_temperature {
  color: @purple-base;
  border-top: 4px solid @purple-base;
  @media screen and (min-width: 768px), print {
    border-top: 0px;
    border-left: 4px solid @purple-base;
  }
}

.MeasureSummary_strength {
  color: @blue-base;
  border-top: 4px solid @blue-base;
  border-left: 0px;
  @media screen and (min-width: 768px), print {
    border-top: 0px;
    border-left: 4px solid @blue-base;
  }
}

.MeasureSummary_measure {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding-top: 8px;
}

.MeasureSummary_value {
  font-size: @tx-size-xxl;
}

.MeasureSummary_unit {
  padding-left: 4px;
  height: 0px;
  font-size: @tx-size-medium-small;
  font-weight: bold;
}

.MeasureSummary_metric {
  font-size: @tx-size-medium-small;
  @media screen and (min-width: 768px), print {
    font-size: @tx-size-small;
  }
}

.MeasureSummary_minMax {
  font-size: @tx-size-small;
  color: @black-bold;
}
