.BlinkingRedDot_wrapper {
  position: relative;
  top: -5px;

  @media (min-width: 768px) {
    right: 2px;
  }
}

.BlinkingRedDot_dot {
  position: absolute;
  padding: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: solid 1px rgba(243, 74, 62, 0.7);
  box-shadow: 0 0 0 rgba(243, 74, 62, 1);
  animation: redDot_pulse 2s infinite;
}

.BlinkingRedDot_dot:before {
  content: "";
  background: @red;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.BlinkingRedDot_dot:after {
  content: "";
  border: solid 1px @red;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
}

@-webkit-keyframes redDot_pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 74, 62, 1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(243, 74, 62, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 74, 62, 0);
  }
}
@keyframes redDot_pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(243, 74, 62, 1);
    box-shadow: 0 0 0 0 rgba(243, 74, 62, 1);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(243, 74, 62, 0);
    box-shadow: 0 0 0 10px rgba(243, 74, 62, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(243, 74, 62, 0);
    box-shadow: 0 0 0 0 rgba(243, 74, 62, 0);
  }
}
