.BottomPanel_overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /*
     * The navbar has a z-index of 3 so this should go
     * below it but still cover the entire screen so that we
     * can handle clicks outside of this div in the future.
     */
  z-index: 2;
}

.BottomPanel_slideIn {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 75%;
  width: 100%;
  background-color: @white;
  border-radius: 12px 12px 0 0;
  box-shadow: 2px 0 14px 0 rgba(0, 0, 0, 0.16);
  padding: 0 16px;
  box-sizing: border-box;
  overflow: scroll;

  @media (min-width: 768px) {
    height: 80%;
  }
}

.BottomPanel_separator {
  @media (min-width: 768px) {
    background-color: @blue-base;
    transform: translateY(-4px);
    height: 4px;
    margin: 0 auto;
    width: 100%;
  }
}

.BottomPanel_content {
  margin: 0 auto;
  width: 100%;

  @media (min-width: 768px) {
    width: 640px;
  }
}

.BottomPanel_transition-appear,
.BottomPanel_transition-enter {
  height: 0px;
}

.BottomPanel_transition-appear-active,
.BottomPanel_transition-enter-active {
  height: 75%;
  transition: height 250ms ease-in;

  @media (min-width: 768px) {
    height: 80%;
  }
}

.BottomPanel_transition-exit {
  height: 75%;

  @media (min-width: 768px) {
    height: 80%;
  }
}

.BottomPanel_transition-exit-active {
  height: 0px;
  transition: height 250ms ease-in;
}
