.AlertBox {
  display: flex;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: @white;
  padding: 8px 16px 20px 16px;
  box-sizing: border-box;
  flex-wrap: nowrap;

  @media (min-width: 768px) {
    padding: 20px 4px 20px 0;
    flex-wrap: wrap;
  }
}

.AlertBox_begonia {
  .AlertBox;
  background-color: @begonia-5;
  border-top: 2px solid @begonia-base;

  @media (min-width: 768px) {
    border-top: none;
    border-left: 2px solid @begonia-base;
  }
}

.AlertBox_yellow {
  .AlertBox;
  background-color: @yellow-5;
  border-top: 2px solid @yellow-base;

  @media (min-width: 768px) {
    border-top: none;
    border-left: 2px solid @yellow-base;
  }
}

.AlertBox_purple {
  .AlertBox;
  background-color: @purple-5;
  border-top: 2px solid @purple-base;

  @media (min-width: 768px) {
    border-top: none;
    border-left: 2px solid @purple-base;
  }
}

.AlertBox_blue {
  .AlertBox;
  background-color: @blue-5;
  border-top: 2px solid @blue-base;

  @media (min-width: 768px) {
    border-top: none;
    border-left: 2px solid @blue-base;
  }
}

// Mixin to change SVG color
// See: https://github.com/lvl99/less-inline-svg-code
.inline-svg-code( @code ) {
  @-svg-code: escape(~'<?xml version="1.0" ?>@{code}');
  @-inline-svg-code: ~"data:image/svg+xml,@{-svg-code}";
  @-inline-svg-url: ~"url('@{-inline-svg-code}')";
}

// see: http://blog.lvl99.com/post/141328309411/inline-svg-code-within-css-using-less
.alert_icon(@alert-icon-svg-code) {
  .inline-svg-code(@alert-icon-svg-code);
  background-image: @-inline-svg-url;
}

.AlertBox_icon {
  display: inline-flex;
  height: 24px;
  width: 24px;
  padding-right: 4px;
  background-repeat: no-repeat;
  transform: translateY(6px);

  @media (min-width: 768px) {
    transform: translateY(2px);
  }
}

.AlertBox_icon_yellow {
  .alert_icon(
    ~'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="#FF9700" fill-rule="nonzero" d="M3 19.545h18L12 4 3 19.545zm9.818-2.454h-1.636v-1.636h1.636v1.636zm0-3.273h-1.636v-3.273h1.636v3.273z"/></g></svg>'
  );
}

.AlertBox_icon_begonia {
  .alert_icon(
    ~'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="#FF2000" fill-rule="nonzero" d="M12 4c-4.416 0-8 3.584-8 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 12h-1.6v-1.6h1.6V16zm0-3.2h-1.6V8h1.6v4.8z"/></g></svg>'
  );
}

.AlertBox_icon_purple {
  .alert_icon(
    ~'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g><path fill="@{purple-base}" fill-opacity=".01" d="M0 0h24v24H0z"/><path fill="@{purple-base}" fill-rule="nonzero" d="M11.33 15h1.33v2h-1.33v-2zm0-8h1.33v6h-1.33V7zm.66-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm.005 18.66a8.663 8.663 0 0 1-8.665-8.665 8.663 8.663 0 0 1 8.665-8.665 8.663 8.663 0 0 1 8.665 8.665 8.663 8.663 0 0 1-8.665 8.665z"/></g></svg>'
  );
}

.AlertBox_icon_blue {
  .alert_icon(
    ~'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g><path fill="@{blue-base}" fill-opacity=".01" d="M0 0h24v24H0z"/><path fill="@{blue-base}" fill-rule="nonzero" d="M11.33 15h1.33v2h-1.33v-2zm0-8h1.33v6h-1.33V7zm.66-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm.005 18.66a8.663 8.663 0 0 1-8.665-8.665 8.663 8.663 0 0 1 8.665-8.665 8.663 8.663 0 0 1 8.665 8.665 8.663 8.663 0 0 1-8.665 8.665z"/></g></svg>'
  );
}

.AlertIcon_wrapper {
  line-height: 1.5;
  flex-basis: 25px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;

  @media (min-width: 768px) {
    padding: 0px 8px 4px 20px;
  }
}

.AlertBox_body {
  display: flex;
  flex-grow: 1;
  text-align: left;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0 16px 4px 0;
  }
}

.AlertBox_title {
  display: inline-block;
  align-items: center;
  font-size: @tx-size-base;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    display: flex;
    font-size: @tx-size-medium;
  }
}

.AlertBox_message {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) {
    // This needs to be size of icon + its padding left + its padding right
    padding-left: 56px;
  }
}

.AlertBox_button {
  width: 180px;
  display: flex;
  width: 100%;
  align-items: flex-end;
  padding: 4px;
  @media (min-device-width: 480px) {
    width: 180px;
    padding-right: 24px;
  }
}
