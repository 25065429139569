.NoActiveSensors_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 8px;

  @media only screen and (min-width: 768px) {
    margin-top: 40px;
    width: 100%;
    padding: 24px;
  }
}

.NoActiveSensors_title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: @tx-size-base;
  padding-bottom: 8px;

  @media only screen and (min-width: 768px) {
    font-size: @tx-size-medium;
  }
}

.NoActiveSensors_text {
  @media only screen and (min-width: 768px) {
    padding-left: 32px;
  }
}

.NoActiveSensors_alert {
  padding-right: 8px;
}
