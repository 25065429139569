.SensorDetail {
  width: 100%;
  box-sizing: border-box;
  margin-top: 108px;
  position: relative;
  z-index: 1;

  @media print {
    padding: 0px;
    margin-top: 0px;
  }
}

.SensorDetail .SubNavbar_topBarChildren {
  display: flex;
  flex-wrap: nowrap;
}

.SensorDetail_siteSelector {
  width: 100%;
}

.SensorDetail_durationSelector {
  display: flex;
  align-items: center;
  width: 50%;
  padding-left: 4px;
  @media screen and (min-width: 768px), print {
    width: 236px;
  }
}

.SensorDetail_clock {
  width: 17.5px;
  height: 17.5px;
  @media screen and (min-width: 768px), print {
    width: 20px;
    height: 20px;
  }
}

.SensorDetail_mixHeader {
  @media print {
    margin-top: 16px;
  }
}
