.ThresholdsInlineForm_explanation {
  color: @blue-base;
  margin: 0;
  margin-bottom: 8px;
  width: 100%;
}

.ThresholdsInlineForm_inputRow {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.ThresholdsInlineForm_inputRow > *:not(:last-child) {
  margin-right: 16px;
  margin-bottom: 0px;

  @media only screen and (min-width: 768px) {
    margin-bottom: inherit;
  }
}

/*
 * This specificity is necessary because it's the only way to beat the specific class
 * TODO we should refactor stylings for input so that the parents stablish the ideal margin for them
 */
.ThresholdsInlineForm_inputRow > *,
.ThresholdsInlineForm_inputRow > .Input_inputWrapper {
  margin-bottom: 0px;
}

.ThresholdsInlineForm_buttonsRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.ThresholdsInlineForm_buttonsRow > *:not(:last-child) {
  margin-right: 8px;

  @media only screen and (min-width: 768px) {
    margin-right: 16px;
  }
}
