.MobileAlert {
  box-sizing: border-box;
  padding: 24px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.MobileAlert_body {
  color: @grey-110;
  line-height: 1.57;
  margin: 0;
  text-align: left;
}

.MobileAlert_icon {
  float: left;
  margin-top: -3px;
}

.MobileAlert_wrapper {
  display: flex;
  margin-bottom: 24px;
}
