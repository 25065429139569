.SensorPanel {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media screen and (min-width: 768px), print {
    margin-bottom: 32px;
  }

  @media only print {
    margin-bottom: 0;
    margin-top: 16px;
  }
}

.SensorPanel_top {
  display: flex;
  background: white;
  border-radius: 2px;
  border: 1px;
  border-color: @grey-base;
  border-style: solid;
  flex-direction: column;
  @media screen and (min-width: 768px), print {
    flex-direction: row;
    max-height: 452px;
  }

  @media only print {
    max-height: 300px;
  }
}

.SensorPanel_streamSummary {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 768px), print {
    width: 234px;
    flex-direction: row;
  }
}

.SensorPanel_streams {
  width: 100%;
  @media screen and (min-width: 768px), print {
    min-height: 388px;
  }

  @media only print {
    min-height: 0;
  }
}

.SensorPanel_controls {
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 768px), print {
    flex-direction: row;
  }

  @media only print {
    display: none;
  }
}

.SensorPanel_downloadButtons {
  display: flex;
  width: 100%;
}

.SensorPanel_actionButton {
  display: flex;
  width: 100%;
  @media screen and (min-width: 768px), print {
    width: 238px;
  }
}

.SensorPanel_actionButtonContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
