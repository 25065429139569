.EmptyView_wrapper {
  width: 100%;
  text-align: center;
  padding-bottom: 40px;

  @media only screen and (min-width: 768px) {
    margin-top: 40px;
  }
}

.EmptyView_registrationSteps {
  display: flex;
  margin-top: 44px;
  flex-direction: column;
  padding: 0 8px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    padding: 0;
  }
}

.EmptyView_lateralRed {
  position: absolute;
  width: 4px;
  height: 95%;
  left: -24px;
  transform: translateY(5%);
  margin-right: 20px;
  background-color: @begonia-base;
}

.EmptyView_redText {
  position: relative;
  display: flex;
  color: @begonia-base;
  font-weight: bold;
}

.EmptyView_purpleBackground {
  background-color: @purple-5;
  font-weight: 800;
  border-radius: 2px;
  padding: 3px 6px;
}

.EmptyView_registrationSteps > *:not(:last-child) {
  margin-bottom: 24px;

  @media only screen and (min-width: 768px) {
    margin-right: 24px;
    margin-bottom: 0px;
  }
}

.EmptyView_controls {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
