.KpiCardListPlaceholder {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;
  font-size: @tx-size-large;
  width: 100%;
  padding: 80px 0;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
}

.KpiCardListPlaceholder_title {
  display: flex;
  margin-bottom: 8px;
}

.KpiCardListPlaceholder_body {
  display: flex;
  text-align: center;
}
