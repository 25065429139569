.Message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  min-height: 7em;
  z-index: 3;
  border-radius: 10px 10px 0em 0em;
  padding: 24px 16px 24px 16px;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.1);

  p {
    margin: 0 0 4px 0;
  }

  p:last-child {
    margin: 0 0 16px 0;
  }

  &.error {
    color: white;
    background-color: @begonia-base;
  }

  &.info {
    color: white;
    background-color: @green-base;
  }

  // For IE11
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    left: 0%;
  }
}

.Message_title {
  font-size: @tx-size-medium;
  @media only screen and (min-width: 768px) {
    margin-top: 0.5em;
  }
}

.Message_body {
  margin: 0 auto;
  word-break: BREAK-WORD;
  white-space: initial;
  text-align: left;
  font-size: @tx-size-base;

  @media only screen and (min-width: 768px) {
    text-align: center;
    width: 500px;
  }
}

.Message_children {
  text-align: center;
  padding: 0.5em;
}
