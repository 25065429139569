.ThresholdEmptyAlert_wrapper {
  box-sizing: border-box;
  padding: 24px;
  display: flex;
}

.ThresholdEmptyAlert_icon {
  display: none;
  min-height: 24px;
  min-width: 24px;

  @media only screen and (min-width: 768px) {
    display: block;
  }
}

.ThresholdEmptyAlert_message {
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    padding-left: 8px;
  }
}

.ThresholdEmptyAlert_title {
  line-height: 1.2;
  font-size: @tx-size-large;
  font-weight: 800;
}

.ThresholdEmptyAlert_body {
  color: @grey-110;
  line-height: 1.57;
  margin: 0;
  margin-top: 8px;
}
