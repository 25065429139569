.TemperatureRow_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  height: 96px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    padding: 0 24px;
    height: auto;
    height: 64px;
  }
}

.TemperatureRow_contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: 16px 0;
  }
}

.TemperatureRow_barColumn {
  width: 100%;

  @media only screen and (min-width: 768px) {
    flex: 0 0 200px;
    padding: 0;
    width: 132px;
  }
}

.TemperatureRow_descriptionColumn {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: @tx-size-base;

  @media only screen and (min-width: 768px) {
    margin-top: 0;
    padding-left: 32px;
  }
}

.TemperatureRow_leftMetadata {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 9;
  width: 100%;
  padding: 0 16px;
  margin-top: 8px;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    padding: 0;
    margin-top: 0;
  }
}

.TemperatureRow_deleteColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(30%, -50%;);

  @media only screen and (min-width: 768px) {
    position: initial;
    transform: none;
  }
}
