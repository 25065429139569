.TimeSeriesGraph {
  display: flex;
  width: 100%;
  @media screen and (min-width: 768px), print {
    padding-left: 20px;
    max-height: 340px;
  }

  @media print {
    max-height: 250px;
  }
}

.TimeSeriesGraph_emptyDataWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// if we don't put this, when resizing the outer div width, the chart
// will not follow the width.
canvas {
  width: 100% !important;
  height: 100% !important;
}
