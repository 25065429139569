.ConfirmationScreen_confirmationWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ConfirmationScreen_confirmationMessage {
  width: 100%;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
}

.ConfirmationScreen_confirmationButtons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ConfirmationScreen_confirmationButtons > * {
  margin: 8px;
}
