.TokenGeneratorView {
  margin-top: 88px;

  @media (min-width: 768px) {
    margin-top: 136px;
  }
}

.TokenGeneratorView_content {
  padding-top: 24px;

  @media (min-width: 768px) {
    padding-top: 40px;
  }
}

.TokenGeneratorView_formWrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}

.TokenGeneratorView_form {
  padding: 16px;
}
