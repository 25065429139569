.PoliciesLayout_background {
  position: absolute;
  top: 80px;
  width: 100vw;
  height: 116px;
  left: 0;
  background-image: url("./img/white-background-light.svg");
  background-repeat: repeat-x, no-repeat;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}
