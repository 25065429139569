.InventoryIndexView_wrapper {
  overflow-x: visible;
  margin-top: 136px;

  @media (min-device-width: 320px) and (max-device-width: 480px) {
    margin-top: 88px;
  }
}

.InventoryIndexView_siteName {
  margin-top: 40px;

  @media (min-device-width: 320px) and (max-device-width: 480px) {
    margin-top: 24px;
  }
}

.InventorySection_showMoreLink {
  cursor: pointer;
}

.InventoryIndexView_metadata {
  margin-bottom: 16px;
}

.InventoryIndexView_siteName {
  display: inline-block;
}

.InventoryIndexView_siteCountBadge {
  display: inline-block;
  padding: 8px;
  border-radius: 5px;
  background-color: rgba(242, 242, 242, 0.6);
  font-family: @tx-size-small;
  color: @grey-110;
  margin: 0 8px 8px 8px;
}

.InventoryIndexView_hardwareSummary > * {
  margin-right: 48px;

  @media (min-device-width: 320px) and (max-device-width: 480px) {
    display: block;
    width: 100%;
    overflow: hidden;
  }
}

.InventoryIndexView_sensorLink {
  color: #026df5;
  text-decoration: underline;
}

.SensorsIndexView_loadMoreWrapper {
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;
}
