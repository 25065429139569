/**
 * The structure is:
 * - LoginLayout -> Set the background
 *      - LoginView_container -> Flex container that will become a fix-sized white div in laptops.
 *          - LoginForm_form -> The only content of LoginView_container, also a flex container
 */

.LoginLayout {
  background-image: url("./img/assets-bg-tile-dark.svg"),
    linear-gradient(to bottom right, @purple-90, @blue-100, @blue-80);
  background-repeat: repeat-x, no-repeat;
  background-position: top, top;
  background-color: black;
  height: 100vh;
  width: 100vw;

  // this shouldn't happen automatically? TODO verify.
  @media only screen and (orientation: landscape) {
    height: 100vw;
  }
}

.LoginView_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginForm_form {
  margin-top: 3em;
  width: 80%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  color: @white;
}

.LoginView_formItem {
  font-size: @tx-size-base;
  width: 100%;
  flex-basis: 5em;
}

.LoginView_formTitle {
  .LoginView_formItem;
  font-size: @tx-size-medium;
  @media only screen and (orientation: landscape) {
    flex-basis: 2em;
  }
}

.LoginView_formLogo {
  height: 5em;
  background: url(./img/logo-white.svg);
  background-repeat: no-repeat;
}

.LoginView_formLink {
  .LoginView_formItem;
  line-height: 30px;
  text-align: center;
}

.LoginView_formLink > a {
  color: @white;
  font-size: @tx-size-base;
  text-decoration: underline;
  cursor: pointer;
  &:hover &:focus {
    color: @white; // this is probably wrong, should be grey?
  }
  &:visited {
    color: @white; // same here
  }
}

.LoginView_formInput {
  font-size: @tx-size-base;
  width: 100%;
}

.LoginView_formButton {
  .LoginView_formItem;
  display: flex;
  align-items: center;
}

.LoginView_formErrors {
  margin-top: 0.2em;
  background-color: @begonia-5;
  color: @grey-110;
  text-indent: 10px;
  border-left: 3px solid red;
}

@media only screen and (min-width: 768px) {
  .LoginLayout {
    background-image: url("./img/assets-bg-tile-dark.svg"),
      url("./img/node.svg"), url("./img/hub.svg"),
      linear-gradient(to bottom right, @purple-90, @blue-100, @blue-80);
    background-repeat: repeat-x, no-repeat, no-repeat, no-repeat;
    background-position: top, -150px center, right 300px, top;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }

  .LoginView_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: @white;
    width: 540px;
    height: 572px;
  }

  .LoginForm_form {
    width: 80%;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    color: @grey-110;
    margin-left: 30px;
    margin-top: 70px;
  }

  .LoginView_formLogo {
    height: 5em;
    background: url(./img/logo-default.svg);
    background-repeat: no-repeat;
  }

  .LoginView_formItem {
    font-size: @tx-size-base;
    width: 400px;
    flex-basis: 6em;
  }

  .LoginView_formTitle {
    display: flex;
    align-items: center;
    flex-basis: 5em;
    font-size: @tx-size-large;
  }
}
