.StreamsSummary {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 768px), print {
    flex-direction: row;
  }
}

.StreamsSummary_measures {
  display: flex;
  flex-direction: row;
  border: 1px;
  border-color: @grey-base;
  width: 100%;
  @media screen and (min-width: 768px), print {
    border-left-style: none;
    border-right-style: solid;
    width: 238px;
    flex-direction: column;
  }
}

.StreamsSummary_temperature {
  display: flex;
  font-size: @tx-size-large;
  flex: 1;
  @media screen and (min-width: 768px), print {
    height: 90px;
    flex: 0 auto;
    height: 150px;
  }
}

.StreamsSummary_strength {
  display: flex;
  flex: 1;
  font-size: @tx-size-large;
  @media screen and (min-width: 768px), print {
    height: 90px;
    flex: 0 auto;
    height: 150px;
  }
}

.StreamsSummary_bottomContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.StreamsSummary_line {
  border: 1px;
  border-color: @grey-base;
  border-bottom-style: solid;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 8px;
  @media screen and (min-width: 768px), print {
    margin-right: 0px;
  }
}

.StreamsSummary_bottom {
  display: flex;
  flex-direction: row;
  border: 1px;
  border-color: @grey-base;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 768px), print {
    justify-content: flex-start;
    display: flex;
    border-top-style: none;
    border-left: 4px solid @blue-base;
    flex: 1;
    width: auto;
  }
}

.StreamsSummary_bottomContent {
  display: flex;
  flex: 1;
  justify-content: center;
}

.StreamsSummary_controls {
  display: flex;
  margin-top: auto;
  border-radius: 2px;
  width: 100%;
  @media screen and (min-width: 768px), print {
    border: 1px;
    border-color: @grey-base;
    border-top-style: solid;
    margin-top: auto;
    height: 48px;
  }

  @media print {
    display: none;
  }
}

.StreamsSummary_actionButtonContent {
  display: flex;
  justify-content: center;
  align-items: center;
  color: @blue-base;
  font-size: @tx-size-small;
  @media screen and (min-width: 768px), print {
    font-size: @tx-size-base;
  }
}

.StreamsSummary_reconfigureIcon {
  width: 28px;
  height: 24px;
  .inline-svg-code(@cog-icon-blue);
  background-image: @-inline-svg-url;
  background-repeat: no-repeat;
}

.StreamsSummary_space {
  width: 4px;
  @media screen and (min-width: 768px), print {
    height: 20px;
  }
}
