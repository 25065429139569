.AnimatedRow_wrapper {
  position: relative;
}

.AnimatedRow_willEnter {
  will-change: height, opacity;
}

.AnimatedRow_overlay {
  background-color: @blue-base;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
