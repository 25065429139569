.Panel {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-top: 16px;

  @media screen and (min-width: 768px), print {
    margin-bottom: 24px;
    margin-top: 24px;
  }
}

.Panel_top {
  display: flex;
  background: white;
  border-radius: 2px;
  border: 1px;
  border-color: @grey-base;
  border-style: solid;
  flex-direction: column;
  margin-bottom: 16px;

  @media screen and (min-width: 768px), print {
    flex-direction: row;
    min-height: 388px;
    margin-bottom: 9px;
  }
}

.Panel_summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 18px;
  box-sizing: border-box;

  @media screen and (min-width: 768px), print {
    width: 238px;
    flex-direction: row;
    padding-top: 24px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
    border-right: 2px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  }
}

.Panel_topBorder {
  display: flex;

  @media screen and (min-width: 768px), print {
    display: none;
  }
}

.Panel_topBorderBlue {
  display: flex;
  height: 2px;
  background-color: @blue-base;
  flex-grow: 1;
}

.Panel_topBorderPurple {
  display: flex;
  height: 2px;
  background-color: @purple-base;
  flex-grow: 2;
}

.Panel_charts {
  box-sizing: border-box;
  @media screen and (min-width: 768px), print {
    width: 982px;
    padding-top: 24px;
    padding-bottom: 12px;
  }
}

.Panel_controls {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  @media screen and (min-width: 768px), print {
    flex-direction: row;
    margin-top: 0;
  }

  @media only print {
    display: none;
  }
}

.Panel_downloadControls {
  display: flex;
  width: 100%;
}

.Panel_actionButton {
  display: flex;
  width: 100%;
  margin-bottom: 8px;

  @media screen and (min-width: 768px), print {
    margin-bottom: 0;
    width: 238px;
  }
}
