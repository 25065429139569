.Tooltip {
  position: relative;
  display: flex;
  cursor: help;
}

.Tooltip_text {
  font-size: @tx-size-small;
  visibility: hidden;
  background-color: @white;
  text-align: center;
  width: 200px;
  padding: 16px;
  border-radius: 6px;
  border: solid 1px @grey-10;
  box-shadow: 0 4px 17px 0 rgba(16, 124, 175, 0.23);
  top: -300%;
  right: 105%;
  position: absolute;
  text-align: left;

  @media only screen and (min-width: 768px) {
    width: 294px;
    padding: 20px;
    top: auto;
    right: auto;
    bottom: 100%;
    left: 50%;
    margin-left: -168px;
  }
}

.Tooltip:hover .Tooltip_text {
  visibility: visible;
}

.Tooltip_title {
  font-weight: bold;
  text-align: left;
  margin: 0;
  padding-bottom: 4px;
}
