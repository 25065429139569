.HardwareCard_cardWrapper {
  margin: 0 16px 8px 0;
  scroll-snap-align: center;
}

.HardwareCard_severeGlow {
  box-shadow: 0 2px 21px 0 rgba(255, 0, 0, 0.22);
}

.HardwareCard_bg {
  position: relative;
  width: 388px;
  height: 200px;
  padding: 24px 16px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-between;

  @media (min-device-width: 320px) and (max-device-width: 480px) {
    width: 280px;
  }
}

.HardwareCard_hardwareName {
  margin: 0 0 8px 0;
  white-space: nowrap;
  max-width: 240px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-device-width: 320px) and (max-device-width: 480px) {
    width: 120px;
  }
}

.HardwareCard_hardwareId {
  margin-bottom: 12px;
}

.HardwareCard_moderateBar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 4px;
  height: 100%;
  float: left;
  background-color: @orange-base;
}

.HardwareCard_severeBar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 4px;
  height: 100%;
  float: left;
  background-color: @red-sidebar;
}

.HardwareCard_metadataColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.HardwareCard_titleRow {
  display: flex;
  align-content: center;
}

.HardwareCard_editButton {
  margin: 0 8px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.HardwareCard_editView {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 24px;
  box-sizing: border-box;
  z-index: 1;
}

.HardwareCard_editTitle {
  margin-bottom: 16px;
  font-size: @tx-size-large;
  color: @black-bold;
  align-self: flex-start;
}

.HardwareCard_nameInput {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgba(74, 144, 226, 0.61),
    inset 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #82b6f3;
  background-color: #fbfbfb;
  padding: 12px;
  box-sizing: border-box;
  font-size: @tx-size-medium-small;
}

.HardwareCard_cancelEditButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 48px;
  font-size: @tx-size-base;
  text-align: center;
  background-color: @black-bold;
  color: @white;
  border-radius: 2px;
  margin: 0 4px;
  cursor: pointer;
}

.HardwareCard_saveEditButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 48px;
  font-size: @tx-size-base;
  text-align: center;
  background-color: #10a265;
  color: @white;
  border-radius: 2px;
  margin: 0 4px;
  cursor: pointer;
}

.HardwareCard_editControls {
  margin-top: 24px;
}
