.HardwareInformationChart_wrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  box-sizing: border-box;

  @media screen and (min-width: 768px), print {
    flex-direction: row;
  }
}

.HardwareInformationChart_item {
  white-space: pre-wrap;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.HardwareInformationChart_itemText {
  padding-left: 16px;
}

.HardwareInformationChart_item:not(:last-child) {
  margin-bottom: 24px;

  @media screen and (min-width: 768px), print {
    margin-bottom: auto;
  }
}

.HardwareInformationChart_itemName {
  display: block;
  box-sizing: border-box;
  font-weight: bold;

  @media screen and (min-width: 768px), print {
    display: inline-block;
  }
}

.HardwareInformationChart_itemNameSensor {
  display: block;
  white-space: pre-wrap;
  box-sizing: border-box;
  font-weight: bold;
  padding-left: auto;

  @media screen and (min-width: 768px), print {
    display: inline-block;
    padding-left: 0;
  }
}
