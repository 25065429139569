.NavItem {
  display: flex;
  position: relative;
  align-items: center;
  font-size: @tx-size-medium-small;
  height: 100%;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
}

.NavItem input {
  /*
     * This is necessary to cleanup tacit.css
     */
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 768px) {
  .NavItem {
    width: 100%;
  }
}
