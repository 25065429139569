.MixDetailChart_wrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  box-sizing: border-box;

  @media screen and (min-width: 768px), print {
    flex-direction: row;
  }

  @media print {
    padding: 8px;
  }
}

.MixDetailChart_item {
  width: 100%;
  text-align: left;
}

.MixDetailChart_item:not(:last-child) {
  margin-bottom: 8px;

  @media screen and (min-width: 768px), print {
    margin-bottom: auto;
  }
}

.MixDetailChart_itemName {
  font-weight: bold;
}
