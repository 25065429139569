.InformationCard_imgRound {
  margin-bottom: 32px;
}

.InformationCard_contentWrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.InformationCard_title {
  width: 100%;
  min-height: 44px;
  text-align: left;
  font-size: @tx-size-medium;
  margin-bottom: 20px;
  line-height: 1.2;
}

.InformationCard_mainContent {
  width: 100%;
  text-align: left;
  min-height: 68px;
  line-height: 1.57;
}

.InformationCard_wrapper {
  position: relative;
  min-height: 400px;
  padding: 32px 24px;
  width: 100%;
  box-sizing: border-box;
  height: auto;

  @media only screen and (min-width: 768px) {
    height: 100%;
  }
}

.InformationCard_imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  width: 200px;
}

.InformationCard_indexBubble {
  position: absolute;
  left: 0;
  top: 20px;
  transform: translate(-40%);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: @tx-size-large;
  background-color: @blue-base;
  color: @white;
  display: flex;
  justify-content: center;
  align-items: center;
}
