.NavbarAlert_wrapper {
  will-change: opacity;
  position: absolute;
  pointer-events: all;
  background-color: @begonia-5;
  z-index: 4;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  transform: translateY(48px);
  padding: 12px 16px;

  @media only screen and (min-width: 768px) {
    top: 100%;
    left: -50%;
    width: 600px;
    padding: 24px 16px;
    transform: translateY(-12px);
  }
}

.NavbarAlert_topBar {
  height: 4px;
  width: 100%;
  background-color: @red;
  position: absolute;
  top: 0;
  left: 0;
}

.NavbarAlert_triangleUp {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid @red;
  position: absolute;
  transform: translate(48px, -100%);

  @media only screen and (min-width: 768px) {
    transform: translate(120px, -100%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid @red;
  }
}

.NavbarAlert_icon {
  padding: 0 8px 0 0;

  @media only screen and (min-width: 768px) {
    padding: 0 8px;
  }
}

.NavbarAlert_contentColumn {
  width: 100%;
}

.NavbarAlert_content {
  width: 100%;
}

.NavbarAlert_header {
  display: flex;
  align-items: center;
  font-size: @tx-size-base;
  color: @black-bold;
  font-family: @font-family;
  font-weight: bold;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: @tx-size-medium-large;
  }
}

.NavbarAlert_body {
  font-family: @font-family;
  line-height: 1.57;
  color: @black-bold;

  @media only screen and (min-width: 768px) {
    padding: 0 0 0 40px;
  }
}

.NavbarAlert_bodyText {
  margin: 8px 0 16px 0;
}

.NavbarAlert_controls {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.NavbarAlert_controls > *:not(:last-child) {
  margin: 0 0 16px 0;

  @media only screen and (min-width: 768px) {
    margin: 0 16px 0 0;
  }
}
