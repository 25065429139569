.Charts {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    max-height: 448px;
  }
}

.Charts_chartDifferential {
  display: flex;
  width: 100%;
  @media screen and (min-width: 768px) {
    height: 130px;
  }
}

.Charts_chartTemperatures {
  display: flex;
  width: 100%;
  @media screen and (min-width: 768px) {
    height: 232px;
  }
}

.Charts_chartLabels {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  padding-left: 16px;
  padding-top: 6px;
  padding-bottom: 15px;

  @media screen and (min-width: 768px), print {
    flex-direction: row;
    padding-right: 6px;
    padding-left: 52px;
    padding-top: 22px;
    padding-bottom: 0px;
  }
}

.Charts_circleYellow {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  height: 10px;
  width: 10px;
  background-color: @yellow-base;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;

  @media screen and (min-width: 768px), print {
    margin-left: 20px;
  }
}

.Charts_circleBegonia {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  height: 10px;
  width: 10px;
  background-color: @begonia-base;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;

  @media screen and (min-width: 768px), print {
    margin-left: 20px;
  }
}

.Charts_circleBlue {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  height: 10px;
  width: 10px;
  background-color: @blue-base;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;

  @media screen and (min-width: 768px), print {
    margin-left: 8px;
  }
}

#print-canvas {
  display: none;
}
