.SelectInput_inputWrapper {
  width: 100%;
  color: @dark-grey;
  margin-bottom: 24px;
}

.SelectInput_inputWrapper input {
  box-shadow: none;
}

.SelectInput_labels {
  display: flex;
  justify-content: space-between;
}

.SelectInput_labels > * {
  display: inline-block;
  width: 100%;
  font-size: @tx-size-base;
  margin-bottom: 0;
}

.SelectInput_alignLeft {
  text-align: left;
}

.SelectInput_errorMessage {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
  color: #ff0000;
  font-size: @tx-size-medium-small;
  letter-spacing: -0.2px;
}

.SelectInput_selectWrapper {
  font-size: 20px;
  margin: 4px 0 0 0;
  min-height: 44px;
  width: 100%;
}
