.InlineDropdown {
  min-width: 85%;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and (max-width: 768px) {
    align-items: flex-start;
  }
}

.InlineDropdown .NavDropdown_options {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background-color: @blue-110;

  @media only screen and (min-width: 768px) {
    display: none;
    transform: translateY(-16px);
    height: auto;
    margin: auto;
    min-width: auto;
    flex-direction: row;
  }
}

.InlineDropdown .NavDropdown_label {
  display: flex;
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display: auto;
    min-width: 100%;
    transform: translate(24px);
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: initial;
  }
}

.InlineDropdown .NavLink {
  margin: 0 1em 0 0;

  @media only screen and (min-width: 768px) {
    border: none;
  }

  &:hover {
    border: none;
  }
}

.InlineDropdown .NavDropdown_item {
  padding-left: 0;
  height: 48px;
  transition: height 0.25s;

  > .NavItem {
    height: auto;
    @media only screen and (max-width: 768px) {
      height: 100%;
    }
  }
}

.InlineDropdown .NavDropdown_item {
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: left;
    align-items: center;
  }
}

.InlineDropdown .NavDropdown_item:hover {
  @media only screen and (min-width: 768px) {
    border-bottom: solid 2px @blue-5;
    -webkit-text-stroke: 0.75px @white;
  }
}
