.CountBadge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 19.5px;
  background-color: rgba(34, 35, 46, 0.05);
  padding: 0 8px;
  box-sizing: border-box;
  color: @grey-110;
  margin: 0 16px;
}
