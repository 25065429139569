.ConfirmationDialogue_bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(164, 164, 164, 0.8);
  z-index: 2;
}

.ConfirmationDialogue_box {
  display: flex;
  flex-direction: column;
  background-color: @white;
  position: absolute;
  padding: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  display: flex;
  min-width: 320px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  max-width: 100%;
  box-sizing: border-box;
}

.ConfirmationDialogue_children {
  margin-bottom: 16px;
}

.ConfirmationDialogue_controls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.ConfirmationDialogue_controls > *:not(:first-child) {
  margin-top: 8px;
}
