// print header classes. These are used only when printing.

.PrintStrength_wrapper {
  border: solid 1px @grey-base;
  padding-top: 8px;
  padding-bottom: 8px;
}

.PrintStrength_table {
  width: 100%;
  text-align: left;
  border-spacing: unset;
  padding-left: 24px;
  padding-right: 24px;
}

.PrintStrength_header {
  margin-top: 16px;
}

.PrintStrength_rowBold {
  font-weight: bold;
}

.PrintStrength_oddRow {
  padding-left: 20px;
  background-color: rgba(216, 216, 216, 0.3);
  border-bottom: solid 1px @grey-base;
  -webkit-print-color-adjust: exact;
}

.PrintStrength_evenRow {
  padding-left: 20px;
  background-color: transparent;
  border-bottom: solid 1px @grey-base;
}
