.Summary {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (min-width: 768px), print {
    flex-direction: column;
    border-bottom: none;
  }
}

.Summary_maxDiff,
.Summary_maxTemp,
.Summary_minTemp {
  display: flex;
}

.Summary_maxDiff {
  display: flex;
  color: @blue-base;
  flex-grow: 1;

  @media screen and (min-width: 768px), print {
    height: 130px;
    border-top: 0px;
    border-left: 2px solid @blue-base;
    margin-bottom: 24px;
    flex-grow: 0;
  }

  @media only print {
    border-left: none;
  }
}

.Summary_temps {
  display: flex;
  flex-grow: 2;
  justify-content: space-around;

  @media screen and (min-width: 768px), print {
    border-top: 0px;
    flex-direction: column;
    height: 206px;
    flex-grow: 0;
  }
}

.Summary_maxTemp {
  color: @purple-base;
  @media screen and (min-width: 768px), print {
    height: 103px;
    border-left: 2px solid @purple-base;
  }

  @media only print {
    border-left: none;
  }
}

.Summary_minTemp {
  .Summary_maxTemp;
}
