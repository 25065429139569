// print header classes. These are used only when printing.

.PrintHeader_printHeader {
  font-size: @tx-size-small;
  display: flex;
  width: 100%;
  margin-top: 4mm;
}

.PrintHeader_printHeaderFirstLine {
  display: flex;
}

.PrintHeader_printHeaderWrapper {
  display: flex;
  flex-direction: column;
}

.PrintHeader_printHeaderTitle {
  font-size: @tx-size-large;
}

.PrintHeader_printHeaderTitle {
  font-size: @tx-size-large;
}

.PrintHeader_printHeaderLogoWrapper {
  margin-left: auto;
}

.PrintHeader_printHeaderLogo {
  width: 110px;
}

.PrintHeader_printHeaderCreatedBy {
  margin-left: 50px;
}

.PrintHeader_siteName {
  margin-top: 16px;
  font-size: @tx-size-small;
}

.PrintHeader_printHeaderSecondLine {
  margin-top: 0px;
  display: flex;
}

.PrintHeader_sensorName {
  font-size: @tx-size-large;
}

.PrintHeader_printHeaderRegisteredBy {
  font-size: @tx-size-small;
  margin-left: auto;
  display: flex;
}

.PrintHeader_printHeaderRegisteredBy span {
  align-self: flex-end;
}
