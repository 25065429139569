.StrengthRow_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  position: relative;

  @media only screen and (min-width: 768px) {
    padding-right: 24px;
  }
}

.StrengthRow_statusIconColumn {
  padding: 0 8px 0 16px;
}

.StrengthRow_textColumn {
  display: flex;
  flex-direction: column;
  flex: 4;
  align-items: left;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.StrengthRow_titleWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 20%;
  }
}

.StrengthRow_title {
  font-weight: 800;
  display: flex;
  align-items: center;
}

.StrengthRow_silenceIcon {
  padding: 0 0 0 16px;
}

.StrengthRow_description {
  @media only screen and (min-width: 768px) {
    flex: 1 0 auto;
  }
}

.StrengthRow_predictionIcon {
  display: flex;
  padding-right: 20px;

  @media only screen and (min-width: 768px) {
    padding-right: 4px;
  }
}

.StrengthRow_deleteColumn {
  display: flex;
  flex: 1;
  width: 32px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  transform: translate(30%);

  @media only screen and (min-width: 768px) {
    position: static;
    transform: none;
    display: flex;
    justify-content: flex-end;
  }
}

.StrengthRow_emptyCircle {
  width: 24px;
  height: 24px;
  .inline-svg-code(@empty-circle);
  background-image: @-inline-svg-url;
  background-repeat: no-repeat;
}

.StrengthRow_checkCircle {
  width: 24px;
  height: 24px;
  .inline-svg-code(@check-circle);
  background-image: @-inline-svg-url;
  background-repeat: no-repeat;
}
