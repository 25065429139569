.Table {
  width: 100%;
  border-collapse: collapse;
}

.Table_wrapper {
  border: solid 1px #e1e1e1;
  width: 100%;
}

.Table_borderless {
  border: none;
}

.Table_row:nth-child(even) {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  background-color: #f4f4f4;
}

.Table_rowHasBorder:nth-child(even) {
  background-color: @white;
  border-bottom: solid 1px rgba(151, 151, 151, 0.2);
  border-top: solid 1px rgba(151, 151, 151, 0.2);
}

.Table_rowHasBorder:last-child {
  border-bottom: none;
}

.Table_sortable {
  overflow-y: auto;
  height: 642px;

  @media only print {
    overflow-y: inherit;
    height: auto;
  }
}
