.NavUser_initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  background-color: @green-base;
  border-radius: 50%;
  margin-right: 11px;
  font-size: @tx-size-small;
  letter-spacing: -0.1px;
}

.NavUser_userWrapper {
  display: flex;
  flex-direction: column;
}

.NavUser_fullName {
  line-height: 1.1;
  padding: 0;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.NavUser_siteName {
  font-size: @tx-size-small;
  opacity: 0.4;
  line-height: 1.1;
  padding: 0;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}
