.EditSensorForm_title {
  margin: 24px 0;
}

.EditSensorForm_buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.EditSensorForm_buttons > * {
  margin: 8px 0;

  @media only screen and (min-width: 768px) {
    margin: 0 24px;
  }
}

.EditSensorForm_selectLabel {
  color: @black-bold;
}

.EditSensorForm_pourSelect {
  color: @black-bold;
  display: flex;
  justify-content: space-between;
}

.EditSensorForm_pourSelected {
  font-size: @tx-size-base;
  color: @black-bold;
  padding-left: 22px;
}

.EditSensorForm_pourSelectOption {
  color: @black-bold;
  padding-left: 12px;
}

.EditSensorForm_pourSelectPourNameDifferentMix {
  opacity: 0.5;
}

.EditSensorForm_selectOptionMixNameDifferentMix {
  color: @red;
  opacity: 0.5;
}

.EditSensorForm_message {
  margin-top: 8px;
  color: @grey-110;
  font-size: @tx-size-small;
}
