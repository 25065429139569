.PoursIndexPlaceholder {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  background-color: @grey-90;
  margin-top: 12px;

  @media only screen and (min-width: 768px) {
    align-items: center;
    padding: 56px 210px;
    margin-top: 12px;
  }
}

.PoursIndexPlaceholder_header {
  color: @black-bold;
}

.PoursIndexPlaceholder_text {
  max-width: 800px;
  color: @black-bold;
}

.PoursIndexPlaceholder_addPour {
  margin-top: 32px;
  align-self: center;
  text-decoration: underline;
  color: @green-base;
  cursor: pointer;
}
