// Common print CSS

.print {
  display: none;
}

@media print {
  @page {
    size: auto;
    // small marging let and right. No margin on top to
    // avoid to put the html title + date.
    margin-left: 4mm;
    margin-right: 4mm;
    margin-top: 0mm;
  }

  // remove the URL from href
  a[href]:after {
    content: none !important;
  }

  // class for hiding elements
  .noprint {
    display: none;
  }

  .print {
    display: block;
  }

  body {
    background: transparent;
  }

  h1 {
    font-size: @tx-size-large;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  h2 {
    font-size: @tx-size-medium;
    line-height: normal;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
}
