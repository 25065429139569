.StreamInfo {
  display: flex;
  flex-direction: column;
}

.StreamInfo_title {
  font-size: @tx-size-large;
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
}

.StreamInfo_line {
  border: 1px;
  border-color: @grey-base;
  border-bottom-style: solid;
  margin-left: 24px;
  margin-right: 24px;
  @media screen and (min-width: 768px), print {
    margin-right: 0px;
  }
}

.StreamInfo_graph {
  display: flex;
  width: 100%;
}
