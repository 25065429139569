// NOTE: the order of these styles DO matter

.CalendarDay__default,
.CalendarDay__default:hover {
  font-size: @tx-size-medium-small;
  border: none;
  color: @black;
  background-color: @white;
  border-radius: 50%;
  padding: 4px;
}

.CalendarDay__default:hover {
  background-color: @grey-70;
}

.CalendarDay__selected {
  background: @blue-70;
  color: @white;
  border-radius: 50%;
  border: none;
}

.CalendarDay__selected:hover {
  background: @blue-90;
  border: none;
  color: @white;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:hover {
  color: @grey-100;
  background-color: @white;
}

.SingleDatePicker {
  width: 100%;
}

.SingleDatePicker_picker {
  transform: translate(25%, -75%);
}

.DateInput {
  width: 100%;
}

.SingleDatePickerInput__withBorder {
  display: none;
  position: absolute;
  top: -50px;
}
