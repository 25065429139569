.SensorRegistrationView_wrapper {
  overflow-x: visible;
  margin-top: 88px;

  @media (min-width: 768px) {
    margin-top: 136px;
  }
}

.SensorRegistrationView_greeting {
  padding-top: 24px;
  margin-bottom: 8px;
  font-size: @tx-size-medium;

  @media (min-width: 768px) {
    padding-top: 40px;
    font-size: @tx-size-x-large;
  }
}

.SensorRegistrationView_content {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.SensorRegistrationView_content > *:not(:last-child) {
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-right: 24px;
    margin-bottom: 0px;
  }
}

.SensorRegistrationView_informationCard {
  flex: 1 0;
}

.SensorRegistrationView_formWrapper {
  padding: 24px 16px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding: 80px 160px;
  }
}

.SensorRegistrationView_formHeader {
  width: 100%;
  margin: 0;
  margin-bottom: 8px;
  font-weight: 800;
}

.SensorRegistrationView_formSeparator {
  height: 1px;
  width: 100%;
  border-bottom: solid 1px #e9e9e9;
  margin-bottom: 24px;
}

.SensorRegistrationView_formCard {
  flex: 3;
}

.SensorRegistrationView_addPourRow {
  width: 100%;
  display: flex;
  align-items: center;
}

.SensorRegistrationView_addPourButton {
  background-color: @green-base;
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-left: 16px;
  margin-bottom: 4px;
}
