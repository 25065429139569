.ThresholdsSubscribers_subscriptionsParagraph {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.ThresholdSubscribers_otherText {
  text-decoration: underline;
}

.ThresholdSubscribers_manageLink {
  display: block;
  font-size: @tx-size-small;

  @media (min-width: 768px) {
    display: inline;
    font-size: @tx-size-base;
  }
}

.ThresholdsSubscribers_notificationsIcon {
  display: none;
  margin-right: 8px;

  @media (min-width: 768px) {
    display: block;
  }
}
