.MaturityItem_wrapper {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  height: 59px;
  background-color: @grey-30;
  border: solid 1px @grey-base;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 8px 16px;

  @media screen and (min-width: 768px), print {
    height: 108px;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  @media print {
    height: 80px;
    background-color: transparent;
  }
}

.MaturityItem_wrapper:not(:last-child) {
  margin-bottom: 8px;

  @media screen and (min-width: 768px), print {
    margin-right: 16px;
    margin-bottom: 0;
  }
}

.MaturityItem_name {
  @media screen and (min-width: 768px) {
    font-size: @tx-size-base;
    margin-bottom: 8px;
  }

  @media print {
    font-size: @tx-size-small;
    text-align: center;
    margin-bottom: 8px;
  }
}

.MaturityItem_value {
  font-weight: 800;

  @media screen and (min-width: 768px) {
    font-weight: 400;
    font-size: @tx-size-x-large;
  }

  @media print {
    font-weight: 400;
    font-size: @tx-size-large; // we need these slightly smaller when printing.
  }
}

.MaturityItem_green {
  background-color: @green-base;
  border: none;
  color: @white;

  // When printing, same as the other rectangles.
  @media print {
    border: solid 1px @grey-base;
    color: @grey-110;
    background-color: transparent;
    height: 80px;
  }
}
