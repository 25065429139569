.placeholder(@rules) {
  &::-webkit-input-placeholder {
    @rules();
  }

  &:-moz-placeholder {
    @rules();
  }

  &::-moz-placeholder {
    @rules();
  }

  &:-ms-input-placeholder {
    @rules();
  }
}

input {
  height: 40px;
  border-radius: 2px;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  background-color: #fbfbfb;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #d5d4d4;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-indent: 10px;
  .placeholder({opacity: 0.6; color: #4b4f5a;});

  &:focus {
    background-color: #fbfbfb;
  }

  &.__invalid {
    height: 40px;
    border-radius: 2px;
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #ff0000;
    background-color: @error;
    &:focus {
      background-color: @error;
    }
  }
}

.control-errors {
  margin-top: 0.2em;
  color: @red;
}

/* These rules disable spinners on number fields and the annoying clear button on IE11 */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-ms-clear {
  display: none;
}
