.Column {
  text-align: center;
  padding: 12px;

  @media only print {
    padding: 8px;
  }
}

.Column_header {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  position: sticky;
  top: 0;
  background: #fff;
  padding: 22px 0;
  border-bottom: solid 1px #e1e1e1;

  @media only print {
    padding: 16px 0;
  }
}

.Column_headerBorderless {
  border-bottom: none;
  padding-bottom: 8px;
}

.Column_imageCellWrapper {
  height: 40px;
  overflow: hidden;
}

.Column_imageCell {
  margin-top: 1px;
}

.Column_noPadding {
  padding: 0;
}
