.MaturitySummary_contentWrapper {
  padding: 16px;
  box-sizing: border-box;

  @media screen and (min-width: 768px), print {
    padding: 24px;
  }

  @media print {
    padding: 8px;
  }
}

.MaturitySummary_maturityRowItems {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px), print {
    flex-direction: row;
  }
}

.MaturitySummary_helpText {
  margin: 0;
  margin-bottom: 24px;

  @media screen and (min-width: 768px), print {
    margin-bottom: 16px;
  }

  @media print {
    font-size: @tx-size-small;
  }
}

.MaturitySummary_header {
  @media print {
    margin-top: 16px;
  }
}
